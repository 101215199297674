import { User } from "../models/User";


class APIFunctions {

    _URL = 'https://stg-api.gaflighttracker.com/restapi/';
    _URL2 = 'https://stg-api.gaflighttracker.com';

    //_URL = 'https://localhost:44305/restapi/';
    //_URL2 = 'https://localhost:44305';

    async Login(account: User): Promise<boolean> {
        var currentSession: any;
        var acc = new User();
        var jstring = JSON.stringify(account);
        //console.log(account);
        //console.log(this._URL + 'Login');
        await fetch(this._URL + 'Login', {
            method: 'POST',
            body: jstring,
            headers: {
                'Content-type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);
                currentSession = json;
                sessionStorage.setItem("cs", JSON.stringify(json));
                console.log("LOGGED IN:");
                //console.log(currentSession);
            })
            .catch(error => {
                //console.log("Error Logging in");
                console.error(error);
                return false;
            });


        if (currentSession.SSO !== null && currentSession.SSO !== "") {
            //await this.storeData("gaftLogin", JSON.stringify(account));
            var getProfile = {
                UserID: currentSession.AccountID,
                AccountID: currentSession.AccountID,
                SSO: currentSession.SSO,
            };
            jstring = JSON.stringify(getProfile);
            console.log("GETTING PROFILE:");
           // console.log(jstring);
            await fetch(this._URL + 'GetProfile', {
                method: 'POST',
                body: jstring,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => response.json())
                .then(json => {
                    console.log("Profile received..");
                    acc = json;
                    //console.log(acc);
                })
                .catch(error => {
                    console.error(error);
                });

            await sessionStorage.setItem("u", JSON.stringify(acc)); //this.storeData("gaftUser", JSON.stringify(acc));

            return true;
        } else {
            sessionStorage.removeItem("u");
            sessionStorage.removeItem("cs");
            //this.removeValue("gaftLogin");
            //this.removeValue("gaftUser");
        }

        return false;
    }

    async GetProfile(profileID: string) {
        var getProfile = {
            UserID: profileID,
            AccountID: '00000000-0000-0000-0000-000000000000',
            SSO: '00000000-0000-0000-0000-000000000000',
        };
        var jstring = JSON.stringify(getProfile);
        //console.log("GETTING PROFILE:");
        // console.log(jstring);
        var acc: any;
        await fetch(this._URL + 'GetPilotProfile/' + profileID, {
            method: 'POST',
            body: jstring,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(json => {
                //console.log("Profile received..");
                acc = json;
                //console.log(acc);
            })
            .catch(error => {
                console.error(error);
            });
        return acc;
    }

    async GetProfilePicture(profileID: string) {
        var getProfile = {
            UserID: profileID,
            AccountID: '00000000-0000-0000-0000-000000000000',
            SSO: '00000000-0000-0000-0000-000000000000',
        };
        var jstring = JSON.stringify(getProfile);
        console.log("GETTING PROFILE:");
        // console.log(jstring);
        var acc: any;
        await fetch(this._URL + 'GetPilotPicture', {
            method: 'POST',
            body: jstring,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(json => {
                console.log("Picture received..");
                acc = json;
                //console.log(acc);
            })
            .catch(error => {
                console.error(error);
            });
        return acc;
    }

    async forgotPassword(fp: any): Promise<void> {
        var jstring = JSON.stringify(fp);
        var resetcreated = false;
        await fetch(this._URL + 'forgotpassword', {
            method: 'POST',
            body: jstring,
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',

            },
        })
            .then(response => response.text())
            .then(json => {

                resetcreated = true;
            })
            .catch(error => {
                console.error(error);
            });
    }

    async validatePasswordReset(fp: any): Promise<boolean> {

        var jstring = JSON.stringify(fp);
        var isvalid = false;
        await fetch(this._URL + 'validateResetRequest', {
            method: 'POST',
            body: jstring,
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',

            },
        })
            .then(response => response.json())
            .then((json:any) => {

                isvalid = json.result;
            })
            .catch(error => {
                console.error(error);
            });

        return isvalid;

    }

    async resetPassword(fp: any): Promise<boolean> {
        var jstring = JSON.stringify(fp);
        var isvalid = false;
        await fetch(this._URL + 'resetPassword', {
            method: 'POST',
            body: jstring,
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',

            },
        })
            .then(response => response.json())
            .then((json: any) => {

                isvalid = json.result;
            })
            .catch(error => {
                console.error(error);
            });

        return isvalid;
    }

    async CreateUser(account: User): Promise<boolean> {
        
        //account.ProfilePicture = "";
        var jstring = JSON.stringify(account);
        var accountcreated = false;
        await fetch(this._URL + 'AccountCreate', {
            method: 'POST',
            body: jstring,
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',

            },
        })
            .then(response => response.text())
            .then(json => {
                if (json === "true")
                    accountcreated = true;
            })
            .catch(error => {
                console.error(error);
            });

        if (accountcreated) {
            //await this.Login(account);
            //account.ProfilePicture = pic;
            //await this.UpdateProfile(account);
        }
        return accountcreated;
    }

    async UpdateProfile(account: User): Promise<boolean> {
        var currentSession: any;
        currentSession = JSON.parse(sessionStorage.getItem("cs") || "");
        account.SSO = currentSession.SSO;
        account.AccountID = currentSession.AccountID;

        var jstring = JSON.stringify(account);
        //console.log(jstring);
        var accountUpdated = false;
        await fetch(this._URL + 'AccountUpdate', {
            method: 'POST',
            body: jstring,
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',

            },
        })
            .then(response => response.text())
            .then(json => {
                //console.log(json);
                if (json === "true")
                    accountUpdated = true;
            })
            .catch(error => {
                console.error(error);
            });

        return accountUpdated;
    }

    async GetLiveFlights(bounds:any) {
        var currentSession:any;
        //currentSession = JSON.parse(sessionStorage.getItem("cs") || "");
        var req = {
            "getAllFlights": true,
            "mapBounds": {
                "lat1": bounds.lat1, "lng1": bounds.lng1, "lat2": bounds.lat2, "lng2": bounds.lng2
            }
        };
        var jstring = JSON.stringify(req);
                                              
        await fetch(this._URL + 'v1.1/mapping/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000/GetFlights', {
            method: 'POST',
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',

            },
            body: jstring,
        })
            .then(response => response.json())
            .then(json => {
                //NativeAppEventEmitter.emit("flightsUpdated", json);
                const event = new CustomEvent("flightsUpdated", { detail: json });
                document.dispatchEvent(event);
            })
            .catch(error => {
                console.error(error);
            });

    }

    async GetLivePilots() {
        var resp = {};
        fetch(this._URL + "getLivePilots", {
            method: 'GET',
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(json => {
              
                const event = new CustomEvent("gotLivePilots", { detail: json });
                document.dispatchEvent(event);
            })
    }

    async GetNewPilots() {
        var resp = {};
        fetch(this._URL + "getNewPilots", {
            method: 'GET',
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(json => {

                const event = new CustomEvent("gotNewPilots", { detail: json });
                document.dispatchEvent(event);
            })
    }

    async getCycle() {
        let resp;
        await fetch(this._URL + "getCycle", {
            method: 'GET',
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(json => {
                //
                const event = new CustomEvent("gotCycleData", { detail: json });
                document.dispatchEvent(event);
                resp = json;
                //console.log("CYCLE:", resp);
            });
       
        //return resp;
    }

    async GetActivePilots() {
        var resp = {};
        fetch(this._URL + "getMostActivePilots", {
            method: 'GET',
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(json => {

                const event = new CustomEvent("gotActivePilots", { detail: json });
                document.dispatchEvent(event);
                resp = json;
            });

        return resp;
    }

    async getTileImage(e:number, g:number, d:number) {
        let resp;
        await fetch(this._URL + "GetTile/" + e + "/" + g + "/" + d, {
            method: 'GET',
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(json => {
                //
                console.log("TILE DATA:", json);
                resp = json.tile;
                //console.log("CYCLE:", resp);
            });
        return "data:image/png;base64," + resp;
    };

    async sendMessage(to: string, from: string, msg: string) {
        var body = { ToUser: to, FromUser: from, Msg: msg };

        fetch(this._URL + "SendMessage", {
            method: 'POST',
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });
    } 

    async followPilot(to: string, from: string) {
        var body = { ToUser: to, FromUser: from };

        fetch(this._URL + "FollowPilotNew", {
            method: 'POST',
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });
    }

    async UnfollowPilot(to: string, from: string) {
        var body = { ToUser: to, FromUser: from };

        fetch(this._URL + "UnfollowPilotNew", {
            method: 'POST',
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });
    }

    async ratePilot(to: string, from: string, rating: number) {
        var body = { ToUser: to, FromUser: from, Rating: rating };

        fetch(this._URL + "RatePilot", {
            method: 'POST',
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });
    }

    async getVideos(userID: string) {
        var body = { userID: userID };
        var resp: any;
        await fetch(this._URL + "getVideos", {
            method: 'POST',
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        }).then(response => response.json())
            .then(json => {
              
                const event = new CustomEvent("videosReceived", { detail: json });
                document.dispatchEvent(event);
                resp = json;
                //console.log(resp);
            });

        return resp;
    }

    async getNewMessageCount(userID: any) {
        var resp = 0;
        var body = { ToUser: userID };

        await fetch(this._URL + "GetMessageCount", {
            method: 'POST',
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then(response => response.json())
            .then(json => {
                if (json.newMessages)
                    resp = json.newMessages;
                //else
                //    resp = 0;
            });

        return resp;
    }

    async getMessages(userID: any) {
        var resp:any;
        var body = { ToUser: userID };

        await fetch(this._URL + "GetMessages", {
            method: 'POST',
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then(response => response.json())
            .then(json => {
               
                    resp = json;
               
            });

        return resp;
    }

    async GetADSBFlightCount() {
        var resp = {};
        fetch(this._URL2 + "/Home/GetStats", {
            method: 'GET',
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(json => {
                const event = new CustomEvent("gotFlightCount", { detail: JSON.parse(json) });
                document.dispatchEvent(event);
            })
    }

    async likeFlight(flightID: string, userID: string, userID2: string) {
        var resp = {};
        await fetch(this._URL + "LikeFlight/" + flightID + "/" + userID + "?p=" + userID2, {
            method: 'POST',
            headers: {
                'APIKey': '86D1675E-2A93-4C58-8132-2737C593BD68',
                'Content-Type': 'application/json',
            },
        });
        //    .then(response => response.json())
        //    .then(json => {
        //        const event = new CustomEvent("gotFlightCount", { detail: JSON.parse(json) });
        //        document.dispatchEvent(event);
        //    })
    }

}

export { APIFunctions }