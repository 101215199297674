import React, { Component, } from 'react';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faRectangleXmark } from '@fortawesome/free-solid-svg-icons'
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import {
    LoginSocialGoogle,
    LoginSocialFacebook,
    IResolveParams,
} from 'reactjs-social-login'

// CUSTOMIZE ANY UI BUTTON
import {
    FacebookLoginButton,
    GoogleLoginButton,
} from 'react-social-login-buttons'
import { AccountManagement } from './AccountManagement';
import { User } from '../models/User';
import { Link } from 'react-router-dom';
import { ErrorPopup } from './ErrorPopup';

library.add(faRectangleXmark);

declare global {
    interface Window {
        FB?: any;
    }
}


interface IProps {
}

interface IState {
    ShowLoginWindow: Boolean;
    ShowErrorWindow: Boolean;
    isCaptchaVerified: Boolean;
}

const InputUsername = React.createRef<Input>();
const InputPassword = React.createRef<Input>();

class LoginPopup extends Component<IProps, any>{

    password = "";
    username = "";

    constructor(props: IProps) {
        super(props);
        this.state = {
            ShowLoginWindow: false,
            ShowErrorWindow: false,
            isCaptchaVerified: false,
            val_username: "",
            val_password: "",
        }
        
    }

    componentDidMount() {
        document.addEventListener("showLoginPopup", this.eventLoginPopupShow);
        document.addEventListener("LoggedIn", this.eventLoggedIn);
        document.addEventListener("hideLoginPopup", this.eventHideLoginPopup);
        document.addEventListener("showLoginErrorPopup", this.eventShowLoginErrorPopup);
        this.setState({ refreshReCaptcha: (r: any) => !r });
    }

    componentWillUnmount() {
        document.removeEventListener("showLoginPopup", this.eventLoginPopupShow);
        document.removeEventListener("LoggedIn", this.eventLoggedIn);
        document.removeEventListener("hideLoginPopup", this.eventHideLoginPopup);
        document.removeEventListener("showLoginErrorPopup", this.eventShowLoginErrorPopup);
    }

    eventLoggedIn = () => {
        this.setState({ ShowLoginWindow: false });
    }

    eventLoginPopupShow = () => {
        //AccountManagement.Login();
        this.setState({ ShowLoginWindow: true })
    }

    eventHideLoginPopup = () => {
        this.setState({ ShowLoginWindow: false })
    }

    eventShowLoginErrorPopup = () => {
        this.closePopup();
        this.setState({ ShowErrorWindow: true });
        console.log("Initiate error popup");
        const event = new CustomEvent("showErrorPopup");
        document.dispatchEvent(event);
    }

    eventLoginPopupHide = () => {
        this.setState({ ShowLoginWindow: false })
    }

    closePopup = () => {
        AccountManagement.Logout();
        this.setState({ ShowErrorWindow: false });
        this.setState({ ShowLoginWindow: false });
    }

    reCaptchaTest = (token: any) => {
        this.setState({ isCaptchaVerified: true });
        console.log("Recaptcha verified");
        if (this.state.submittingForm) {
            this.doLogin();
        }
    }

    handleGAFTLogin = async () => {
        console.log(this.state.val_username, this.state.val_password);
        this.setState({ ShowErrorWindow: false });
        console.log("Attempting login");
        if (!this.state.isCaptchaVerified) {
            this.setState({ submittingForm: true });
            this.setState({ refreshReCaptcha: (r: any) => !r });
            
            console.log("Executed recaptcha");
        } else {
            this.doLogin();
        }
    }

    doLogin = async () => {
        if (this.state.val_username !== "" && this.state.val_password !== "") {
            var user: User = new User();
            user.Username = this.state.val_username;
            user.Password = this.state.val_password;
            user.Platform = 'Website - 3.0 - GAFT Account Login - ';
            user.LastOnline = new Date().toLocaleString();

            console.log(user);
            await AccountManagement.Login(user);
        }
    }

    getAccountPictureFBandGoogle = async (url:String): Promise<String> => {
        var b = "";
        await fetch('' + url, {
            method: 'GET',
           
        })
            .then(response => response.blob())
            .then(blob =>
                new Promise(callback => {
                    let reader = new FileReader();
                    reader.onload = function () { callback(this.result) };
                    reader.readAsDataURL(blob);

                    //console.log("Profile Picture Download");
                    //console.log(blobBase64);

                }).then(result => {
                    //console.log(result);
                    var r:any = result;
                    var res = r.toString().replace("data:image/jpeg;base64,", "");
                    res = res.replace("data:image/png;base64,", "");
                    //console.log(res);
                    b = res;
                })
            )
            .catch(error => {
                console.error(error);
            });

        return b;
    }

    render() {
        this.username = "";
        this.password = "";
        return (
            this.state.ShowLoginWindow ?
                <>
                    <div className="blackout"></div>
                    <div className="loginPopupDiv">
                        <div className="loginPopup">
                            <div className="closeButton" onClick={this.closePopup}>
                                <FontAwesomeIcon icon="rectangle-xmark"  />
                            </div>
                            <div className="container">
                                <div className="row" style={{marginBottom: '20px'} }>
                                    <div className="col-12" style={{ textAlign: 'center' }} >
                                        <h2>Sign In With</h2>
                                    </div>
                                </div>
                                <div className="row" style={{ marginBottom: '20px' }}>
                                   
                                    <div className="col-md-6 col-sm-12">
                                        <LoginSocialFacebook
                                            
                                            appId="156721515093388"
                                            onLoginStart={() => {
                                                window.FB.getLoginStatus(function (response: any) {
                                                    console.log(response);
                                                    window.FB.logout(function (response: any) {
                                                        console.log("FB Logged out");
                                                    });

                                                });
                                                
                                                }
                                            }
                                            fieldsProfile="id,first_name,last_name,middle_name,name,name_format,picture.height(1024).width(1024),short_name,email,gender"
                                            onResolve={async ({ provider, data }: IResolveParams) => {
                                                this.setState({ ShowErrorWindow: false });
                                                //console.log(data);
                                                sessionStorage.setItem("provider", JSON.stringify(data));
                                                var user: User = new User();
                                                user.Username = data?.id + '-gaft-fb';
                                                user.Password = data?.id + '-gaft-fb';
                                                user.Platform = 'Website - 3.0 - GAFT Facebook Login - ';
                                                user.FirstName = data?.first_name;
                                                user.LastName = data?.last_name;
                                                user.EmailAddress = data?.email || "";
                                                user.ProfilePicture = await this.getAccountPictureFBandGoogle(data?.picture.data.url);
                                                user.MobileNumber = "";
                                                user.LastOnline = new Date().toLocaleString();
                                                user.isPilot = true;
                                                user.isVFR = false;
                                                user.isIFR = false;
                                                user.isDualEngine = false;
                                                user.isPublic = true;
                                                user.isNightRated = false;
                                                //console.log(user);
                                                AccountManagement.Login(user);
                                                
                                            }}
                                            onReject={(err) => {
                                                console.log(err)
                                                this.closePopup();
                                                this.setState({ ShowErrorWindow: true });
                                                const event = new CustomEvent("showErrorPopup");
                                                document.dispatchEvent(event);
                                            }}>  
                                            <FacebookLoginButton style={{ fontSize: '9pt', alignText: 'center' }} text="Facebook" />
                                        </LoginSocialFacebook>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <LoginSocialGoogle
                                           
                                            client_id="891818624629-9g3q1gf4ep0a3vape5e9cgtr3ur57471.apps.googleusercontent.com"
                                            onLoginStart={() => { }}
                                            onResolve={async ({ provider, data }: IResolveParams) => {
                                                //console.log(data);
                                                this.setState({ ShowErrorWindow: false });
                                                if (data && data.picture.includes('s96-c')) {
                                                    data.picture = data.picture.replace('s96-c', 's512-c');
                                                } else if (data && data.picture.includes('?sz=')) {
                                                    data.picture = data.picture.substring(0, data.picture.indexOf('?')) + '?sz=1024';
                                                }
                                                //console.log(data);
                                                sessionStorage.setItem("provider", JSON.stringify(data));
                                                var user: User = new User();
                                                user.Username = data?.sub + '-gaft-fb';
                                                user.Password = data?.sub + '-gaft-fb';
                                                user.Platform = 'Website - 3.0 - GAFT Google Login - ';
                                                user.FirstName = data?.given_name;
                                                user.LastName = data?.family_name;
                                                user.EmailAddress = data?.email || "";
                                                user.ProfilePicture = await this.getAccountPictureFBandGoogle(data?.picture);
                                                user.MobileNumber = "";
                                                user.LastOnline = new Date().toLocaleString();
                                                user.isPilot = true;
                                                user.isVFR = false;
                                                user.isIFR = false;
                                                user.isDualEngine = false;
                                                user.isPublic = true;
                                                user.isNightRated = false;
                                                //console.log("Profile with picture", data?.picture);
                                                //console.log(user);
                                                AccountManagement.Login(user);
                                            }}
                                            onReject={(err) => {
                                                console.log(err)
                                                this.closePopup();
                                                this.setState({ ShowErrorWindow: true });
                                                const event = new CustomEvent("showErrorPopup");
                                                document.dispatchEvent(event);
                                            }}
                                        >
                                            <GoogleLoginButton style={{ fontSize: '9pt' }} text="Google" />
                                        </LoginSocialGoogle>

                                    </div>
                                </div>
                                <div className="row" style={{ marginBottom: '20px' }}>
                                    <div className="col-12">
                                        <FormGroup className="mb-3">
                                            <Label>Username</Label>
                                            <Input onChange={(e) => { this.setState({ val_username: e.target.value }); }} type="text" placeholder="" ref={InputUsername}  />
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <Label>Password</Label>
                                            <Input onChange={(e) => { this.setState({ val_password: e.target.value }); }}  type="password" placeholder="" ref={InputPassword}  />
                                        </FormGroup>
                                        <Button className="col-12" onClick={this.handleGAFTLogin }>Login</Button>
                                        <GoogleReCaptchaProvider reCaptchaKey="6LfTl9wlAAAAACJy4nN2eCBV84inU41hkT3TiYUc">
                                            <GoogleReCaptcha onVerify={this.reCaptchaTest}
                                                refreshReCaptcha={this.state.refreshReCaptcha} />
                                        </GoogleReCaptchaProvider>
                                    </div>
                                   
                                </div>
                                <div className="row" style={{ marginBottom: '20px' }}>
                                    <div className="col-12" style={{ textAlign: 'center' }}>
                                        Not a member? <Link to="/register" onClick={() => { this.setState({ ShowLoginWindow: false }); } }>Register now!</Link><br />
                                        <Link to="/forgotpassword" onClick={() => { this.setState({ ShowLoginWindow: false }); }}>Forgot your password?</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : 
                    <>
                    <ErrorPopup ErrorMessage="We had trouble logging you in.  Please try again." ErrorTitle="Login Error" onShow={() => { }} onClose={() => { }} isShown={this.state.ShowErrorWindow} />
                    </> 
        );
    }


}

export { LoginPopup };