import { isNumber } from "util";
import { User } from "../models/User";
import { APIFunctions } from "../modules/APIFunctions";

class AccountManagement {
    static isLoggedIn = (): boolean => {
        var retval = sessionStorage.getItem("u");
        if (retval != null) {
            //console.log("AccountManagement isLoggedIn called")
            const event = new CustomEvent("LoggedIn");
            document.dispatchEvent(event);
            
            return true;
        }
        const event = new CustomEvent("LoggedOut");
        document.dispatchEvent(event);
        return false;
    }

    static checkMessages = async () => {
        var profile = (JSON.parse(sessionStorage.getItem("u") || '{"error": "true"}'));
        if (profile && profile.UserID) {
            //console.log(profile.UserID);
            var api = new APIFunctions();
           await api.getNewMessageCount(profile.UserID).then(resp => {
               
               //this.setState({ NewMessages: resp });
               if (isNumber(resp)) {
                   //console.log("NEW MESSAGES:", resp);
                   sessionStorage.setItem("messages", resp.toString());
                   const event = new CustomEvent("MessageUpdate", { detail: resp });
                   document.dispatchEvent(event);
               }
            });
        }
    }

    static Login = async (account: User) => {
        var a = new APIFunctions();
        //console.log("Starting log in process");
        var result = await a.Login(account);
        if (result) {
            //console.log("Logged in successfully");
            const event = new CustomEvent("LoggedIn");
            document.dispatchEvent(event);
            this.checkMessages();
        } else {
            //console.log("Could not log in");
            const event = new CustomEvent("showLoginErrorPopup");
            document.dispatchEvent(event);
        }
        //sessionStorage.setItem("u", "{SignedIn: true}");
        //const event = new CustomEvent("LoggedIn");
        //document.dispatchEvent(event);
    }

    static CreateLogin = async (account: User) => {
        var a = new APIFunctions();
        //console.log("Creating user");
        var result = await a.CreateUser(account);
        console.log(result);
        if (result)
            var result = await a.Login(account);

        console.log(result);
    }

    static UpdateProfile = async (account: User) => {
        var a = new APIFunctions();
       // console.log("Updating profile");
        var result = await a.UpdateProfile(account);
        console.log(result);
        
    }

    static Logout = () => {
        sessionStorage.removeItem("u");
        sessionStorage.removeItem("cs");
        //window.FB.getLoginStatus(function (response: any) {
        //    console.log(response);
        //    window.FB.logout(function (response: any) {
        //        console.log("FB Logged out");
        //    });

        //});
        const event = new CustomEvent("LoggedOut");
        document.dispatchEvent(event);
    }

    static getUserInfo = (): User => {
        var retval = sessionStorage.getItem("u");
        var u: User = JSON.parse(retval || '');
        return u;
    }
}

export { AccountManagement }