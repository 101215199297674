import React, { Component } from 'react';
import '../css/layout.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { withRouter } from '../modules/withRouter';

library.add(fab,faCheckSquare, faCoffee)


interface IProps {
    buttonName: String,
    buttonNavigation: String,
    icon?: String,
    navigate: any,
    color?: string,
    iOS?: string,
    Android?: string,
    target?: string
}

class HeaderButton extends Component<IProps> {
    static displayName = HeaderButton.name;
    color: string = "#d8d8d8";
    weight: string = "normal";

    constructor(props:any) {
        super(props);
        this.nav = this.nav.bind(this);
        if (this.props.color != null) {
            this.color = this.props.color;
            this.weight = 'bold';
        }
    }

    nav() {
        var OS = window.navigator.platform;
  
        if ((OS == "iPhone" || OS == "iPad" || OS.match(/^Mac/)) && this.props.iOS != null && this.props.iOS != "")
            window.location.href = this.props.iOS;

        else if (OS == "Android" && this.props.Android != null && this.props.Android != "")
            window.location.href = this.props.Android;

        else
            this.props.navigate(this.props.buttonNavigation);
        
    }

    render() {
    
        return (
            <div className="col-md-2 headerMenuItem" style={{ color: this.color, fontWeight: this.weight }} onClick={this.nav}>
                 {this.props.buttonName}
                
            </div>
        );
    }
}

export default withRouter(HeaderButton)