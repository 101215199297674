import React, { Component } from 'react';

class FAQ extends Component<any, any> {

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="col-12 registerDiv container">
                    <div className="row divCenter col-12">
                        <div className="col-md-6 col-sm-12 divCenter" style={{ marginBottom: '20px' }}><h2>Frequently Asked Questions</h2></div>
                    </div>
                    <div className="row" style={{ marginBottom: '20px' }}>
                        <div className="col-12">
                            <div className="col-12">
                                <br />
                                <span className="fontBanner1" style={{color: '#000'} }>What is General Aviation Flight Tracker?</span>
                                <br />
                                GA Flight Tracker is a based off the need to be able to see other civil aircraft around you while flying your airplane.  This is done by downloading and utilizing our app on both Android and iOS devices.  The content is
                                driven by community participation, so the more pilots that use the app, the more useful it becomes.  To encourage usage of the app, we have added plenty of features and additional tools that can also make the use of GA
                                Flight Tracker fun for everyone.
                            </div>

                            <div className="col-12">
                                <br />
                                <span className="fontBanner1" style={{ color: '#000' }}>Is this free?</span>
                                <br />
                                Using GA Flight Tracker is 100% free to use and will always remain free.  We will never charge to use the app or the website, and will never bombard you with unwanted ads.
                            </div>

                            <div className="col-12">
                                <br />
                                <span className="fontBanner1" style={{ color: '#000' }}>What is the subscription for, if it's free?</span>
                                <br />
                                GA Flight Tracker allows you to capture all of your flights, and it's flight path, altitude, ground speed and heading.  By opting into a paid subscription, you can keep unlimited flights and review your flight
                                details anytime.  We also give you the ability to share any of your flight tracks and details with others through your profile.  All of this data needs space and storage which unfortunately is not free.  The amount
                                of detail and data collected can be a significant amount for each flight, so we require a subscription to offset this cost.  We do, however, grant you 1 month subscription for free so you can see if this is something
                                you would like to continue.  You can cancel at anytime without penalty. Thanks for understanding.
                            </div>

                            <div className="col-12">
                                <br />
                                <span className="fontBanner1" style={{ color: '#000' }}>I want to contribute, but want to maintain my privacy...</span>
                                <br />
                                By default, all GA Flight Tracker accounts are set to <b>Public</b> mode upon registration. Making your profile public is voluntary - we respect your privacy.  It is important to note that no one will be able see your details on the site,
                                or during a flight if you set your profile to Private mode.  You will also not be able to share any of your Flight Tracks with anyone else while your account is set to Private.  You an change this setting by editing your profile inside the app, or by signing into the site and
                                updating you account details.
                            </div>

                            <div className="col-12">
                                <br />
                                <span className="fontBanner1" style={{ color: '#000' }}>Can my friends and family track my flight?</span>
                                <br />
                                Yes.  If your profile is public, they can search for you in our Pilot Database and click "VIEW LIVE".  This will take them directly to the Live Tracking map, and centre your location and plane for easy tracking.  They can click on the plane
                                marker to get more details about your flight.  This tracking is ONLY avialable once you select to "Go Online" within the app.
                            </div>

                            <div className="col-12">
                                <br />
                                <span className="fontBanner1" style={{ color: '#000' }}>My tracking stops in certain area's, why?</span>
                                <br />
                                GA Flight Tracker uses cell reception to transmit position data to our servers.  In most area's, you can still obtain cell reception upto 10,000 from the closest cell tower.  However, in more sparse area's, it is possible that the cell towers are placed
                                too far apart, and the degrigated signal may not reach your cruising altitude.  In most cases, when you are back within cell reception, your tracking will automatically resume.
                            </div>

                            <div className="col-12">
                                <br />
                                <span className="fontBanner1" style={{ color: '#000' }}>Cell data? That sounds like a lot for my plan!</span>
                                <br />
                                Not to worry.  We've streamlined the data to be as minimal as possible.  You can generally view the data usage of our app on your device.  The amount of data will vary per use and per pilot and their device settings.  Most Cell phone providers now provide service on their network coast to coast in North America - consult your provider if you are unsure of your coverage area.  Please note that you may be charged
                                additional international roaming charges if you leave your home service area and enter another country during your flight.  Consult your cell provider for additional details about international roaming fees.
                            </div>

                            <div className="col-12">
                                <br />
                                <span className="fontBanner1" style={{ color: '#000' }}>Have more questions?</span>
                                <br />
                                We thought your might.. you can submit your question via our Contact page and we will email you back as soon as possible (and perhaps add it to our FAQ to help others with the same question), or contact us directly at 1-877-311-3630.
                                <br /><br /><br />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export { FAQ }