import { Disclaimer } from "../pages/home/Disclaimer";
import { FAQ } from "../pages/home/FAQ";
import { ForgotPassword } from "../pages/home/forgotpassword";
import { Home } from "../pages/home/home";
import { PrivacyPolicy } from "../pages/home/PrivacyPolicy";
import { Register } from "../pages/home/register";
import { ResetPassword } from "../pages/home/resetPassword";
import { LiveTracking } from "../pages/LiveTracking/livetracking";
import ViewPilot from "../pages/pilot/ViewPilot";
import MyAccount from "../pages/Account/account";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/register',
        index: false,
        element: <Register />
    },
    {
        path: '/forgotpassword',
        index: false,
        element: <ForgotPassword />,
    },
    {
        path: '/resetPassword',
        index: false,
        element: <ResetPassword />,
    },
    {
        path: '/Home/Privacy',
        index: false,
        element: <PrivacyPolicy />,
    },
    {
        path: '/Home/Disclaimer',
        index: false,
        element: <Disclaimer />,
    },
    {
        path: '/FAQ',
        index: false,
        element: <FAQ />,
    },
    {
        path: '/LiveTracking',
        index: false,
        element: <LiveTracking />
    },
    {
        path: '/LiveMap',
        index: false,
        element: <LiveTracking />
    },
    {
        path: '/ViewPilot/:pilotID',
        index: false,
        element: <ViewPilot />
    },
    {
        path: '/Account',
        index: false,
        element: <MyAccount />
    },

    
    //{
    //    path: '/counter',
    //    element: <Counter />
    //},
    //{
    //    path: '/fetch-data',
    //    element: <FetchData />
    //}
];

export default AppRoutes;