import React, { PropsWithChildren } from 'react';
import '../css/layout.css';

import logoImage from '../images/logo.png';


export class GALogoPC extends React.Component<PropsWithChildren> {
    static displayName = GALogoPC.name;

    render() {
        return (
            <div className="row" style={{ height: '100%', minWidth: '250px', maxWidth: '250px' }}>
                <div className="col-md-4" style={{ height: '100%', display: 'flex', flexWrap: 'inherit', alignContent: 'center' }}>
                    <img src={logoImage} style={{ height: '100%', padding: 5 }} alt="GA Flight Tracker Logo" />
                </div>
                <div style={{ height: '100%', display: 'flex', flexWrap: 'inherit', alignContent: 'center' }} className="col-md-8">
                    <div className="col-12 fontLogo1">
                        General Aviation
                    </div>
                    <div className="col-12 fontLogo2">
                        Flight Tracker
                    </div>
                </div>
            </div>
        );
    }
}