import React from 'react';
import { Container } from 'reactstrap';
import logoImage from '../images/logo.png';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faCoffee, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faYoutubeSquare, faFacebook, faAppStoreIos, faGooglePlay } from '@fortawesome/free-brands-svg-icons'
import { withRouter } from '../modules/withRouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

library.add(faCheckSquare, faCoffee, faFacebookSquare, faYoutubeSquare, faFacebook, faAppStoreIos, faGooglePlay)

class Footer extends React.Component<any, any>{

    constructor(props: any) {
        super(props);
    }

    render() {

        return (
            <> <div className="Footer"><Container style={{ height: '100%', marginTop: '50px' }} className="">
                <div className="container" style={{ height: '100%' }}>
                    <div className="row col-12" style={{ height: '100%', }}>
                        <div style={{ height: '100%', display: 'flex', flexWrap: 'inherit', alignContent: 'center', padding: 10 }} className="col-sm-12 col-md-4">
                            <img src={logoImage} style={{ maxWidth: '33%' }} />
                            General Aviation Flight Tracker<br/>
                            Copyright &copy; 2023, All rights reserved.<br /><br />
                            675 Huntington Ridge Drive<br />
                            Mississauga, Ontario<br />
                            L5R 4H8, CANADA
                        </div>
                        <div style={{ height: '100%', flexWrap: 'inherit', alignContent: 'center', padding: 10 }} className="col-sm-12 col-md-2">
                            <a href="https://play.google.com/store/apps/details?id=ca.oxigen.apps.gaflighttracker" target="_blank">Android App</a><br />
                            <a href="https://itunes.apple.com/us/app/ga-flight-tracker/id1319102682?ls=1&mt=8" target="_blank">iOS App</a><br />
                            <a href="/Contact">Contact Us</a><br />
                            <a href="/News/PressReleases">Press Releases</a><br />
                            <a href="/FAQ">FAQ</a><br/>
                            <a href="/Home/Privacy">Privacy Policy</a><br />
                            <a href="/Home/Disclaimer">Disclaimer</a><br />
                            
                        </div>
                        <div style={{ height: '100%', flexWrap: 'inherit', alignContent: 'center', padding: 10 }} className="col-sm-12 col-md-2">
                            <a href="/LiveMap">Live Tracking</a><br />
                            <a href="/Videos">User Videos</a><br />
                            <a href="/Pilots">Pilot Search</a><br />
                            <a href="/Aircraft">Aircraft Search</a><br />
                            
                        </div>
                        <div style={{ height: '100%', display: 'flex', flexWrap: 'inherit', alignContent: 'center', padding: 10, fontSize: '16pt' }} className="col-sm-12 col-md-2">
                            Where to find us
                            <div className="col-12"><FontAwesomeIcon icon={faFacebookSquare} />&nbsp;&nbsp;<FontAwesomeIcon icon={faYoutubeSquare} />&nbsp;&nbsp;<FontAwesomeIcon icon={faAppStoreIos} />&nbsp;&nbsp;<FontAwesomeIcon icon={faGooglePlay} /></div>
                            <div className="col-12" style={{ display: 'flex', alignContent: 'center', flexWrap: 'inherit', fontSize: '10pt' }}>
                                <FontAwesomeIcon icon={faEnvelope} />&nbsp;support@gaflighttracker.com
                            </div>
                        </div>
                    </div>
                    <div className="col-4" style={{ position: 'absolute', top: 15, right: 5 }}>
                      
                    </div>
                </div>
            </Container></div></>
        );
    }


}

export default withRouter(Footer)