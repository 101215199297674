import React, { Component } from 'react';
import {
    LoginSocialGoogle,
    LoginSocialFacebook,
    IResolveParams,
} from 'reactjs-social-login'
import {
    FacebookLoginButton,
    GoogleLoginButton,
} from 'react-social-login-buttons'
import { User } from '../../models/User';
import { AccountManagement } from '../../components/AccountManagement';
import { Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';


class Register extends Component<any, any> { 

    constructor(props:any) {
        super(props);
        this.state = {
            valid_fname: null,
            valid_lname: null,
            valid_email: null,
            valid_password: null,
            valid_confirmpassword: null,
            valid_profilepicture: null,
            valid_agree: null,
            val_fname: "",
            val_lname: "",
            val_email: "",
            val_password: "",
            val_confirmpassword: "",
            val_profilepicture: "",
            captchaPassed: false,
            isRegistered: false,

        }
    }

    componentDidMount() {
        this.setState({ refreshReCaptcha: (r: any) => !r });
    }
 
    getAccountPictureFBandGoogle = async (url: String): Promise<String> => {
        var b = "";
        await fetch('' + url, {
            method: 'GET',

        })
            .then(response => response.blob())
            .then(blob =>
                new Promise(callback => {
                    let reader = new FileReader();
                    reader.onload = function () { callback(this.result) };
                    reader.readAsDataURL(blob);

                    //console.log("Profile Picture Download");
                    //console.log(blobBase64);

                }).then(result => {
                    //console.log(result);
                    var r: any = result;
                    var res = r.toString().replace("data:image/jpeg;base64,", "");
                    res = res.replace("data:image/png;base64,", "");
                    //console.log(res);
                    b = res;
                })
            )
            .catch(error => {
                console.error(error);
            });

        return b;
    }

    validateForm = () => {
        console.log("Validating Form");
        var pattern = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; 
        var hasErrors = false;
        if (this.state.val_fname === "") {
            this.setState({ valid_fname: false });
            hasErrors = true;
        } else {
            this.setState({ valid_fname: null });
        }
        if (this.state.val_lname === "") {
            this.setState({ valid_lname: false });
            hasErrors = true;
        } else {
            this.setState({ valid_lname: null });
        }
        if (this.state.val_email === "" || !pattern.test(this.state.val_email)) {
            this.setState({ valid_email: false });
            hasErrors = true;
        } else {
            this.setState({ valid_email: null });
        }
        if (this.state.val_password.length < 8) {
            this.setState({ valid_password: false });
            hasErrors = true;
        } else {
            this.setState({ valid_password: null });
        }
        if (this.state.val_password !== this.state.val_confirmpassword) {
            this.setState({ valid_confirmpassword: false });
            hasErrors = true;
        } else {
            this.setState({ valid_confirmpassword: null });
        }
        if (this.state.val_agree !== true) {
            this.setState({ valid_agree: false });
            hasErrors = true;
        } else {
            this.setState({ valid_agree: null });
        }
        if (hasErrors === false) {
            console.log("No errors found.. ");
            if (this.state.captchaPassed) {
                console.log("captcha passed.. creating user");
                this.createUser();
            } else {
                console.log("doing captcha..");
                this.setState({ refreshReCaptcha: (r: any) => !r });
                //recaptchaRef.current?.execute();
            }
        }
    }

    onImageChange = async (event:any) => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            console.log("IMG:", img);
            this.setState({
                profilepicture: URL.createObjectURL(img)
            });
           
            console.log(this.state.profilepicture);
            if (this.state.profilepicture) {
                this.setState({ valid_profilepicture: true });
                var img2 = await this.getAccountPictureFBandGoogle(this.state.profilepicture);
                console.log(img2);
                this.setState({ val_profilepicture: img2 });
            } else {
                this.setState({ valid_profilepicture: false });
            }
        }
    };

    reCaptchaTest = (token: any) => {
        this.setState({ captchaPassed: true });
        //this.createUser();

    }

    createUser = async () => {
        
        var user: User = new User();
        user.Username = this.state.val_email;
        user.EmailAddress = this.state.val_email;
        user.Password = this.state.val_password;
        user.FirstName = this.state.val_fname;
        user.LastName = this.state.val_lname;
        user.LastOnline = new Date().toLocaleString();
        user.Platform = 'Website - 3.0 - GAFT Account Login - ';
        user.ProfilePicture = this.state.val_profilepicture;
        user.MobileNumber = "";
        user.isPilot = true;
        user.isVFR = false;
        user.isIFR = false;
        user.isDualEngine = false;
        user.isPublic = true;
        user.isNightRated = false;
        console.log(user);
        await AccountManagement.CreateLogin(user);
        await AccountManagement.UpdateProfile(user);
        AccountManagement.Logout();
        this.setState({ isRegistered: true })
    }

    render() {
        
        return (
            !this.state.isRegistered ?
            <div className="col-12 registerDiv container">
                <div className="row divCenter col-12">
                    <div className="col-md-6 col-sm-12 divCenter" style={{marginBottom: '20px'} }><h2>Create account</h2></div>
                </div>
                <div className="row divCenter">
                    <div className="col-md-6 col-sm-12 text-divider">Sign up with</div>
                </div>
                <div className="row divCenter" style={{ marginBottom: '20px' }}>
                    <div className="col-md-3 col-sm-12">
                        <LoginSocialFacebook

                            appId="156721515093388"
                            onLoginStart={() => {
                                window.FB.getLoginStatus(function (response: any) {
                                    console.log(response);
                                    window.FB.logout(function (response: any) {
                                        console.log("FB Logged out");
                                    });

                                });

                            }
                            }
                            fieldsProfile="id,first_name,last_name,middle_name,name,name_format,picture.height(1024).width(1024),short_name,email,gender"
                            onResolve={async ({ provider, data }: IResolveParams) => {
                                this.setState({ ShowErrorWindow: false });
                                //console.log(data);
                                sessionStorage.setItem("provider", JSON.stringify(data));
                                var user: User = new User();
                                user.Username = data?.id + '-gaft-fb';
                                user.Password = data?.id + '-gaft-fb';
                                user.Platform = 'Website - 3.0 - GAFT Facebook Login - ';
                                user.FirstName = data?.first_name;
                                user.LastName = data?.last_name;
                                user.EmailAddress = data?.email || "";
                                user.ProfilePicture = await this.getAccountPictureFBandGoogle(data?.picture.data.url);
                                user.MobileNumber = "";
                                user.LastOnline = new Date().toLocaleString();
                                user.isPilot = true;
                                user.isVFR = false;
                                user.isIFR = false;
                                user.isDualEngine = false;
                                user.isPublic = true;
                                user.isNightRated = false;
                                //console.log(user);
                                AccountManagement.Login(user);

                            }}
                            onReject={(err) => {
                                console.log(err)
                               
                            }}>
                            <FacebookLoginButton style={{ fontSize: '9pt', alignText: 'center' }} text="Facebook" />
                        </LoginSocialFacebook>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <LoginSocialGoogle

                            client_id="891818624629-9g3q1gf4ep0a3vape5e9cgtr3ur57471.apps.googleusercontent.com"
                            onLoginStart={() => { }}
                            onResolve={async ({ provider, data }: IResolveParams) => {
                                //console.log(data);
                                this.setState({ ShowErrorWindow: false });
                                if (data && data.picture.includes('s96-c')) {
                                    data.picture = data.picture.replace('s96-c', 's512-c');
                                } else if (data && data.picture.includes('?sz=')) {
                                    data.picture = data.picture.substring(0, data.picture.indexOf('?')) + '?sz=1024';
                                }
                                //console.log(data);
                                sessionStorage.setItem("provider", JSON.stringify(data));
                                var user: User = new User();
                                user.Username = data?.sub + '-gaft-fb';
                                user.Password = data?.sub + '-gaft-fb';
                                user.Platform = 'Website - 3.0 - GAFT Google Login - ';
                                user.FirstName = data?.given_name;
                                user.LastName = data?.family_name;
                                user.EmailAddress = data?.email || "";
                                user.ProfilePicture = await this.getAccountPictureFBandGoogle(data?.picture);
                                user.MobileNumber = "";
                                user.LastOnline = new Date().toLocaleString();
                                user.isPilot = true;
                                user.isVFR = false;
                                user.isIFR = false;
                                user.isDualEngine = false;
                                user.isPublic = true;
                                user.isNightRated = false;
                             
                                AccountManagement.Login(user);
                            }}
                            onReject={(err) => {
                                console.log(err)
                                
                            }}
                        >
                            <GoogleLoginButton style={{ fontSize: '9pt' }} text="Google" />
                        </LoginSocialGoogle>

                    </div>
                    
                </div>
                <div className="row divCenter" style={{marginBottom: '20px'} }>
                    <div className="col-md-6 col-sm-12 text-divider">Or a standalone account</div>
                </div>
                <div className="row divCenter" style={{marginBottom: '20px'} }>
                    <div className="col-md-6 col-sm-12">
                        <FormGroup floating>
                            <Input onChange={(e) => { this.setState({ val_fname: e.target.value }); }} id="fname" placeholder="First Name" type="text" valid={this.state.valid_fname === true} invalid={this.state.valid_fname === false} />
                            <Label for="fname">First Name <span style={{ color: '#FF0000' }}>*</span></Label>
                            <FormFeedback invalid>
                                First name is required.
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup floating> 
                            <Input onChange={(e) => { this.setState({ val_lname: e.target.value }); }} id="lname" placeholder="Last Name" type="text" valid={this.state.valid_lname === true} invalid={this.state.valid_lname === false} />
                            <Label for="lname">Last Name <span style={{ color: '#FF0000' }}>*</span></Label>
                            <FormFeedback invalid>
                                Last name is required.
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup floating>
                            <Input onChange={(e) => { this.setState({ val_email: e.target.value }); }} id="email" placeholder="Email Address" type="email" valid={this.state.valid_email === true} invalid={this.state.valid_email === false} />
                            <Label for="email">Email Address <span style={{ color: '#FF0000' }}>*</span></Label>
                            <FormFeedback invalid>
                                Email address is required and needs to be a valid email.
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup floating>
                            <Input onChange={(e) => { this.setState({ val_password: e.target.value }); }} id="password" placeholder="Password" type="password" valid={this.state.valid_password === true} invalid={this.state.valid_password === false} />
                            <Label for="password">Password <span style={{ color: '#FF0000' }}>*</span></Label>
                            <FormFeedback invalid>
                                Password length should be minimum 8 characters.
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup floating>
                            <Input onChange={(e) => { this.setState({ val_confirmpassword: e.target.value }); }} id="confirmpassword" placeholder="Confirm Password" type="password" valid={this.state.valid_confirmpassword === true} invalid={this.state.valid_confirmpassword === false} />
                            <Label for="confirmpassword">Confirm Password <span style={{ color: '#FF0000' }}>*</span></Label>
                            <FormFeedback invalid>
                                Passwords do not match.
                            </FormFeedback>
                        </FormGroup>
                            <FormGroup >
                                <Label for="profilepicture">Profile Picture</Label>
                            <Input id="profilepicture" placeholder="Profile Picture" type="file" onClick={(event) => { (event.target as any).value = null; }} onChange={this.onImageChange} valid={this.state.valid_profilepicture === true } invalid={this.state.valid_profilepicture === false} />
                            <FormFeedback invalid>
                                Error during upload.  Please try again.
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup floating>
                            <Input onChange={(e) => { this.setState({ val_agree: e.target.checked }); }} id="iagree" type="checkbox" valid={this.state.valid_agree === true} invalid={this.state.valid_agree === false} /> I have read and agree to the <Link to="/privacypolicy">privacy policy</Link> and <Link to="/disclaimer">disclaimer</Link> for using the GA Flight Tracker app and the web site. <span style={{ color: '#FF0000' }}>*</span>
                            <FormFeedback invalid>
                                You must review and agree to the privacy policy and disclaimer.
                            </FormFeedback>
                        </FormGroup>
                        
                    </div>
                </div>
                <div className="row divCenter">
                    <div className="col-md-6 col-sm-12">
                        <Button color="primary" className="col-12" onClick={this.validateForm}>Create account</Button>
                        <br /><br />
                        <GoogleReCaptchaProvider reCaptchaKey="6LfTl9wlAAAAACJy4nN2eCBV84inU41hkT3TiYUc">
                            <GoogleReCaptcha onVerify={this.reCaptchaTest}
                                refreshReCaptcha={this.state.refreshReCaptcha} />
                        </GoogleReCaptchaProvider>
                    </div>
                   
                </div>
                </div>
                : <>
                    <div className="col-12 registerDiv container" style={{padding: 10} }>
                        <div className="row divCenter col-12">
                            <div className="col-md-6 col-sm-12 divCenter" style={{ marginBottom: '20px' }}><h2>Congratulations!</h2></div>
                        </div>
                        <div className="row col-12">
                            <div className="col-md-12 col-sm-12 divCenter" style={{ marginBottom: '20px' }}>You have successfully registered your account. Please proceed to log in using the following username:</div>
                        </div>
                        <div className="row divCenter col-12">
                            <div className="col-md-12 col-sm-12 divCenter" style={{ marginBottom: '20px' }}><h2>{this.state.val_email }</h2></div>
                        </div>
                    </div>
                </>
            
        );
    }
}

export { Register }