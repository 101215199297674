import React, { Component, ReactElement } from 'react';
import { Button, Input } from 'reactstrap';
import { APIFunctions } from '../../modules/APIFunctions';
import { withRouter } from '../../modules/withRouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye, faThumbsUp, faFileAudio, faVideo, faMicrophone } from '@fortawesome/free-solid-svg-icons'
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

library.add(faEye, faThumbsUp, faFileAudio, faVideo, faMicrophone);



class ViewPilot extends Component<any, any>{

    pilotID: string = "";
    profile: any;
    message: string = "";
    rating: number = 0;

    constructor(props: any) {
        super(props);
        var u = sessionStorage.getItem("u");
        var user: any = null;
        if (u != null) {
            user = JSON.parse(u);
            this.setState({ user: user });
        }
        this.state = {  captchaPassed: false, isSelf: false, profile: null, videos: null, sendEmail: false, ratePilot: false, showFlights: true, showVideos: true, user: user, followPilot: false, s1: false, s2: false, s3: false, s4: false, s5: false, ratingselected: false };
        
    }

    async componentDidMount() {
        this.setState({ refreshReCaptcha: (r: any) => !r });
        this.pilotID = this.props.router.params.pilotID
        this.getPilotProfile();
        document.addEventListener("LoggedIn", this.loggedIn);
        document.addEventListener("LoggedOut", this.loggedOut);
        
    }

    loggedIn = () => {
        var u = sessionStorage.getItem("u");
        var user: any = null;
        if (u != null) {
            user = JSON.parse(u);
        }
        this.setState({ user: user });
       
    }

    loggedOut = () => {
        this.setState({ user: null });
       
    }

    getPilotProfile = async () => {
        var api = new APIFunctions();
        api.GetProfile(this.pilotID).then((p) => this.setState({ profile: p }));
        api.GetProfilePicture(this.pilotID).then((p) => this.setState({ profilePicture: p }));
        api.getVideos(this.pilotID).then((p) => this.setState({ videos: p }));
    }

    reCaptchaTest = (token: any) => {
        this.setState({ captchaPassed: true });
        //this.createUser();

    }

    sendMessage = async () => {
        if (this.message != "") {
            var api = new APIFunctions();
            api.sendMessage(this.state.profile.profile.UserID, this.state.user.UserID, this.message);
            this.message = "";
        }
    }

    ratePilot = async () => {
        var api = new APIFunctions();
         await api.ratePilot(this.state.profile.profile.UserID, this.state.user.UserID, this.rating);
         this.getPilotProfile();
    }

    likeFlight = async (flightID: string, pilotID: string) => {
        if (this.state.user )
        {
            var api = new APIFunctions();
            api.likeFlight(flightID, pilotID, this.state.user.UserID);
            console.log("Liked flight");
        }

        
    }

    render() {

        var totalLoggedTime = 0;
        if (this.state.profile && this.state.profile.profile.Flights) {
            this.state.profile.profile.Flights.map((f: any) => {
                totalLoggedTime += f.flightDuration;
            })
        }
        var isFollowing = false;
        if (this.state.profile && this.state.profile.followers && this.state.user) {
            var idx = this.state.profile.followers.findIndex((x:any) => x.FollowerID.toUpperCase() == this.state.user.UserID.toUpperCase());
            if (idx >= 0)
                isFollowing = true;

        }
        var starOn = []
        var starOff = [];
        if (this.state.profile != null && this.state.profile.profile != null) {
            for (var i = 0; i < this.state.profile.profile.Rating.toFixed(0); i++) {
                starOn.push(<img key={"ON" + i} src="/images/star_on.png" style={{ width: '25px' }} />)
            }
            for (var i = 0; i < 5 - this.state.profile.profile.Rating.toFixed(0); i++) {
                starOff.push(<img key={"ON" + i} src="/images/star_off.png" style={{ width: '25px' }} />)
            }
        }

        return (<> {this.state.profile != null ? <div className="col-12 container">
            <div className="row">
                <div className="col-lg-4 col-12" style={{ textAlign: 'center' }}>
                    <img src={this.state.profilePicture && this.state.profilePicture.ProfilePicture && this.state.profilePicture.ProfilePicture.length > 200 ? "data:image/png;base64," + this.state.profilePicture.ProfilePicture : "/images/unknownuser.png"} style={{ maxWidth: '350px', maxHeight: '350px', width: '350px', height: '350px', border: '7px solid #000000', borderRadius: '50%' }} />
                </div>
                <div className="col-lg-8 col-12" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="row">
                        <div className="col-12 fontBanner2" style={{ color: '#333' }}>
                            <span style={{ color: '#999', fontSize: '9pt', position: 'relative', top: 10 }} className="fontNormal">PILOT NAME</span><br />
                            {this.state.profile.profile.LastName}&nbsp; / &nbsp;{this.state.profile.profile.FirstName}<br />
                            <span style={{ fontSize: '15px' }}>{starOn}{starOff} ({this.state.profile.profile.Rating} star rating)</span>
                        </div>

                        <div className="col-6 fontBanner1" style={{ color: '#333' }}>
                            <span style={{ color: '#999', fontSize: '9pt', position: 'relative', top: 10 }} className="fontNormal">FLIGHT TIME LOGGED</span><br />
                            {(totalLoggedTime / 3600).toFixed(0)}H {((totalLoggedTime % 3600) / 60).toFixed(0)}M
                        </div>

                        <div className="col-6 fontBanner1" style={{ color: '#333' }}>
                            <span style={{ color: '#999', fontSize: '9pt', position: 'relative', top: 10 }} className="fontNormal">LOGGED FLIGHTS</span><br />
                            {this.state.profile.profile.Flights.length}
                        </div>


                        <div className="col-6 fontBanner1" style={{ color: '#333' }}>
                            <span style={{ color: '#999', fontSize: '9pt', position: 'relative', top: 10 }} className="fontNormal">FOLLOWERS</span><br />
                            {this.state.profile.followers.length}
                        </div>

                        <div className="col-6 fontBanner1" style={{ color: '#333' }}>
                            <span style={{ color: '#999', fontSize: '9pt', position: 'relative', top: 10 }} className="fontNormal">FOLLOWING</span><br />
                            {this.state.profile.following.length}
                        </div>
                        {this.state.user == null || 1==1 || (this.state.user.UserID.toUpperCase() != this.state.profile.profile.UserID.toUpperCase()) ? 
                        <><hr style={{ marginTop: 20 }} />
                        <div className="col-12">
                                    <Button className="btn btn-success" onClick={() => { this.setState({ sendEmail: true }) }}>Contact Pilot</Button> &nbsp;&nbsp;<Button className="btn btn-warning" onClick={() => { this.setState({ followPilot: true }) }}>{isFollowing ? <>Unfollow</> : <>Follow</>} Pilot</Button> &nbsp;&nbsp;<Button className="btn" onClick={() => { this.setState({ratePilot: true}) } }>Rate Pilot</Button>
                        </div>
                        <hr style={{ marginTop: 15 }} />
                          </>  : <></>}
                    </div>


                </div>
            </div>
            <br />
            <div className="d-md-none d-sm-inline"><Button onClick={() => { this.setState({ showFlights: true, showVideos: false }) }}>View Flights</Button> &nbsp;&nbsp;<Button onClick={() => { this.setState({ showFlights: false, showVideos: true }) } }>View Videos</Button><br/><br/></div>
            
            <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                {this.state.showFlights ? 
                <div className="col-md-6 col-sm-12 registerDiv">
                    <span className="fontBanner2" style={{ color: '#333' }}>Flight Logs</span>
                    
                        {this.state.profile.profile.Flights.length > 0 ? <>
                            {this.state.profile.profile.Flights.map((f: any) => {
                                
                                return (
                                    <div key={f.FlightDate} className="row registerDiv" style={{ margin: 5, padding: '0px' }}>
                                        <div className="col-12" style={{ padding: 0, height: '150px', background: 'url(https://api.gaflighttracker.com/Functions/PlaneImage?img=' + f.CallSign + ') center center no-repeat', backgroundSize: 'cover', borderTopLeftRadius: 10, borderTopRightRadius: 10 }} >
                                            <div style={{ textShadow: '1px 1px 2px rgba(0,0,0,.9)', padding: '10px', background: 'rgba(62, 22, 178, 0.81)', color: '#fff', fontSize: '16pt', position: 'relative', top: 0, left: 0, borderTopLeftRadius: 10, borderTopRightRadius: 10 }} className="medium">
                                                {new Date(parseInt(f.FlightDate.match(/\d+/)[0])).toLocaleString("en", { day: "2-digit" })}&nbsp;
                                                {new Date(parseInt(f.FlightDate.match(/\d+/)[0])).toLocaleString("en", { month: "short" }).toUpperCase()}&nbsp;
                                                {new Date(parseInt(f.FlightDate.match(/\d+/)[0])).toLocaleString("en", { year: "numeric" })}
                                            </div>
                                            <div style={{ bottom: '0px', position: 'relative', padding: '10px', fontSize: '24pt', color: 'white', textShadow: '1px 1px 3px rgba(0,0,0,.9)' }} className="bold shadowText">{f.CallSign}</div>

                                        </div>
                                        <div className="row">
                                            <div className="col-4 fontLabel">
                                                DEPART
                                            </div>
                                            <div className="col-4 fontLabel">
                                                ARRIVE
                                            </div>
                                            <div className="col-4 fontLabel">
                                                DURATION
                                            </div>
                                            <div className="col-4 fontLabelData">
                                                {f.Departure}
                                            </div>
                                            <div className="col-4 fontLabelData">
                                                {f.Arrival}
                                            </div>
                                            <div className="col-4 fontLabelData">
                                                {(f.flightDuration / 3600).toFixed(0)}H {((f.flightDuration % 3600) / 60).toFixed(0)}M
                                            </div>

                                            <div className="col-12 fontLabel">
                                                FLIGHT NOTES
                                            </div>
                                            <div className="col-12 fontLabelData">
                                                {f.flightNotes}
                                            </div>

                                            <div className="col-12 fontLabelData">
                                                <hr />
                                            </div>

                                            <div className="col-12" style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                <div><FontAwesomeIcon icon="eye"></FontAwesomeIcon>&nbsp;{f.Views}&nbsp;&nbsp;&nbsp;</div>
                                                <div><FontAwesomeIcon icon="thumbs-up"></FontAwesomeIcon>&nbsp;{f.Likes}&nbsp;&nbsp;&nbsp;</div>
                                                <div>{f.hasAudio ? <><FontAwesomeIcon icon="microphone" color="#ff6600"></FontAwesomeIcon> &nbsp;&nbsp;&nbsp;</> : <></>}
                                                    {f.hasVideo ? <><FontAwesomeIcon icon="video" color="#FF6600"></FontAwesomeIcon> &nbsp;&nbsp;&nbsp;</> : <></>}</div>
                                                <div>
                                                    {f.isShared ? <><Button className="btn btn-warning" style={{ margin: 5 }} onClick={(e) => { window.location.href = "/reviewFlight/" + f.FlightID; } }><FontAwesomeIcon icon="eye"></FontAwesomeIcon>&nbsp; Review Flight</Button></> : <></>}
                                                    <Button className="btn btn-success" style={{ margin: 5 }} onClick={(e) => { this.likeFlight(f.FlightID, this.pilotID); } }><FontAwesomeIcon icon="thumbs-up"></FontAwesomeIcon>&nbsp; Like Flight</Button>
                                                </div>

                                            </div>

                                            <div className="col-12 fontLabelData">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </> : <p>This pilot has not logged any flights in GA Flight Tracker.</p>}

                    </div> : <></>}
                {this.state.showVideos ?
                    <div className="col-md-5 col-sm-12 registerDiv" >
                        <span className="fontBanner2" style={{ color: '#333' }}>Videos</span>

                        {(this.state.videos && this.state.videos.length > 0) ? <>
                            {this.state.videos.map((v:any) => {
                                return (
                                    <div className="col-12" key={v.videoID}>
                                        <iframe  width="100%" height="315" src={"https://www.youtube.com/embed/" + v.videoURL} title={v.videoName} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe><br />
                                        {v.videoName}<br />
                                        {v.videoDescription}
                                        <hr/>
                                    </div>
                                )
                            }) }
                           
                        </> : <p>This pilot has not posted any flight videos in their profile.</p>}

                    </div>
                    : <></>}
            </div>
            <br />
        </div>
            : <></> }
            {this.state.sendEmail ? <>
                <div className="blackout"></div>
                <div className="loginPopupDiv">
                    <div className="loginPopup">
                        <div className="closeButton" onClick={() => { this.setState({ sendEmail: false }) } }>
                            <FontAwesomeIcon icon="rectangle-xmark" />
                        </div>
                        <div className="container">
                            {this.state.user != null ? <>
                                <div className="col-12" style={{ textAlign: 'center' }} >
                                    <h2>Contact {this.state.profile.profile.FirstName}</h2>
                                </div>
                                <div className="col-12 divCenter" style={{ margin: 0 }}>
                                    <div className="col-12 text-divider">Send a direct message</div>
                                </div>
                                <div className="col-12">
                                    <Input type="textarea" className="fontNormal col-12" style={{ height: '300px' }} onBlur={(e:any) => { this.message = e.target.value; } }></Input>
                                </div>
                                <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                                    <Button className="btn btn-warning" onClick={() => { this.setState({ sendEmail: false }); }}>CANCEL</Button>&nbsp;&nbsp;&nbsp;&nbsp;<Button className="btn btn-success" onClick={() => { this.sendMessage(); this.setState({ sendEmail: false }); } }>SEND MESSAGE</Button>
                                </div>
                                <GoogleReCaptchaProvider reCaptchaKey="6LfTl9wlAAAAACJy4nN2eCBV84inU41hkT3TiYUc">
                                    <GoogleReCaptcha onVerify={this.reCaptchaTest}
                                        refreshReCaptcha={this.state.refreshReCaptcha} />
                                </GoogleReCaptchaProvider>
                            </> : <> <div className="row" style={{ marginBottom: '20px' }}>
                                <div className="col-12" style={{ textAlign: 'center' }} >
                                    <h2>Contact Pilot</h2><br />
                                </div>
                                <div className="col-12" style={{ textAlign: 'center' }}>
                                    You must be signed in to send a direct message to this pilot.
                                </div>
                            </div></> }
                        </div>
                    </div>
                </div>
            </> : <></>}
            {this.state.ratePilot ? <>
                <div className="blackout"></div>
                <div className="loginPopupDiv">
                    <div className="loginPopup">
                        <div className="closeButton" onClick={() => { this.setState({ ratePilot: false }) }}>
                            <FontAwesomeIcon icon="rectangle-xmark" />
                        </div>
                        <div className="container">
                            {this.state.user != null ? <>
                                <div className="row" style={{ marginBottom: '20px' }}>
                                    <div className="col-12" style={{ textAlign: 'center' }} >
                                        <h2>Rating {this.state.profile.profile.FirstName}</h2>
                                    </div>
                                    <div className="col-12" style={{ textAlign: 'justify' }}>
                                        You are rating {this.state.profile.profile.FirstName }.  We ask that you give a fair and justified rating for this pilot, as this is publically visible to others.<br/><br/>After rating this pilot, why not send a quick message to them to let them know why you gave them the rating you did?<br/><br/>
                                    </div>
                                    <div className="col-12 divCenter" style={{ margin: 0 }}>
                                        <div className="col-12 text-divider">Select a star rating below</div>
                                    </div>
                                    <div className="col-12" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        {this.state.s1 ? <><img src="/images/star_on.png" style={{ width: '35px' }} onClick={() => { this.rating = 1; this.setState({ ratingselected: true, s1: true, s2: false, s3: false, s4: false, s5: false }); }} onMouseOut={() => { if (!this.state.ratingselected) { this.setState({ s1: false, s2: false, s3: false, s4: false, s5: false }) } else { this.setState({ ratingselected: false }) } }} /></> : <><img src="/images/star_off.png" style={{ width: '35px' }} onMouseOver={() => {  this.setState({ s1: true, s2: false, s3: false, s4: false, s5: false })  }} /></>}  
                                        {this.state.s2 ? <><img src="/images/star_on.png" style={{ width: '35px' }} onClick={() => { this.rating = 2; this.setState({ ratingselected: true, s1: true, s2: true, s3: false, s4: false, s5: false }); }} onMouseOut={() => { if (!this.state.ratingselected) { this.setState({ s2: false, s3: false, s4: false, s5: false }) } else { this.setState({ ratingselected: false }) } }} /></> : <><img src="/images/star_off.png" style={{ width: '35px' }} onMouseOver={() => { this.setState({ s1: true, s2: true, s3: false, s4: false, s5: false }) }} /></>}
                                        {this.state.s3 ? <><img src="/images/star_on.png" style={{ width: '35px' }} onClick={() => { this.rating = 3; this.setState({ ratingselected: true, s1: true, s2: true, s3: true, s4: false, s5: false }); }} onMouseOut={() => { if (!this.state.ratingselected) { this.setState({ s3: false, s4: false, s5: false }) } else { this.setState({ ratingselected: false }) } }} /></> : <><img src="/images/star_off.png" style={{ width: '35px' }} onMouseOver={() => { this.setState({ s1: true, s2: true, s3: true, s4: false, s5: false }) }} /></>}
                                        {this.state.s4 ? <><img src="/images/star_on.png" style={{ width: '35px' }} onClick={() => { this.rating = 4; this.setState({ ratingselected: true, s1: true, s2: true, s3: true, s4: true, s5: false }); }} onMouseOut={() => { if (!this.state.ratingselected) { this.setState({ s4: false, s5: false }) } else { this.setState({ ratingselected: false }) } }} /></> : <><img src="/images/star_off.png" style={{ width: '35px' }} onMouseOver={() => { this.setState({ s1: true, s2: true, s3: true, s4: true, s5: false }) }} /></>}
                                        {this.state.s5 ? <><img src="/images/star_on.png" style={{ width: '35px' }} onClick={() => { this.rating = 5; this.setState({ ratingselected: true, s1: true, s2: true, s3: true, s4: true, s5: true }); }} onMouseOut={() => { if (!this.state.ratingselected) { this.setState({ s5: false  }) } else { this.setState({ ratingselected: false }) } }} /></> : <><img src="/images/star_off.png" style={{ width: '35px' }} onMouseOver={() => { this.setState({ s1: true, s2: true, s3: true, s4: true, s5: true }) }} /></>}
                                    </div>
                                    <div className="col-12 divCenter" style={{ margin: 0 }}>
                                        <div className="col-12 text-divider"></div>
                                    </div>
                                    <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                                        <Button className="btn btn-warning" onClick={() => { this.setState({ ratePilot: false }); }}>CANCEL</Button>&nbsp;&nbsp;&nbsp;&nbsp;<Button className="btn btn-success" onClick={() => { this.ratePilot(); this.setState({ ratePilot: false }); }}>SUBMIT RATING</Button>
                                    </div>
                                </div>
                            </> : <>
                                <div className="row" style={{ marginBottom: '20px' }}>
                                    <div className="col-12" style={{ textAlign: 'center' }} >
                                        <h2>Rate Pilot</h2><br />
                                    </div>
                                    <div className="col-12" style={{ textAlign: 'center' }}>
                                        You must be signed in to rate this pilot.
                                    </div>
                                </div>
                                </>}
                        </div>
                    </div>
                </div>
            </> : <></>}
            {this.state.followPilot ? <>
                <div className="blackout"></div>
                <div className="loginPopupDiv">
                    <div className="loginPopup">
                        <div className="closeButton" onClick={() => { this.setState({ followPilot: false }) }}>
                            <FontAwesomeIcon icon="rectangle-xmark" />
                        </div>
                        <div className="container">
                            {this.state.user != null ? <>
                                <div className="row" style={{ marginBottom: '20px' }}>
                                    <div className="col-12" style={{ textAlign: 'center' }} >
                                        <h2> {isFollowing ? <>Unfollow</> : <>Follow</>} {this.state.profile.profile.FirstName}</h2>
                                    </div>
                                    <div className="col-12" style={{ textAlign: 'center' }}>
                                        {!isFollowing ? <>You have opted to follow this pilot.  If you choose to continue, this pilot will be notified that you are now following them.  When you follow a pilot, you will receive email notifications when they are in flight.<br /><br /></> :
                                            <>You are requesting to stop following this pilot.  You will no longer be notified that this pilot is in flight.<br /><br /></>}
                                    </div>
                                    {/*<hr/>*/}
                                    {/*<div className="col-12" style={{ textAlign: 'center' }}>*/}
                                    {/*    Following: <b>{this.state.profile.profile.FirstName}&nbsp;{this.state.profile.profile.LastName}</b><br /><br/>*/}

                                    {/*</div>*/}
                                    <hr />
                                    <div className="col-12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Button className="btn btn-error" onClick={() => { this.setState({followPilot: false}) } }>CANCEL</Button>
                                        <Button className="btn btn-success" onClick={() => {
                                            var api = new APIFunctions();
                                            if (isFollowing) {
                                                api.UnfollowPilot(this.state.profile.profile.UserID, this.state.user.UserID);
                                                
                                            } else {
                                                api.followPilot(this.state.profile.profile.UserID, this.state.user.UserID);
                                            }
                                            this.setState({ followPilot: false });
                                            this.getPilotProfile();
                                        }}>CONTINUE</Button>

                                    </div>
                                </div>
                               
                            </> : <>
                                    <div className="row" style={{ marginBottom: '20px' }}>
                                        <div className="col-12" style={{ textAlign: 'center' }} >
                                            <h2>Follow Pilot</h2><br />
                                        </div>
                                        <div className="col-12" style={{ textAlign: 'center' }}>
                                            You must be signed in to follow this pilot.
                                        </div>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </> : <></>}
            
            </>);
    }

}

export default withRouter(ViewPilot)