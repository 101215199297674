import React, { PropsWithChildren } from 'react';
import '../css/layout.css';
import HeaderButton from './HeaderButton';


class HeaderMenuPC extends React.Component<PropsWithChildren> {
    static displayName = HeaderMenuPC.name;


    render() {
        return (
            <div className="row col-12" style={{ height: '100%', display: 'flex', flexWrap: 'inherit', alignContent: 'center', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <HeaderButton buttonName="HOME" icon="home" buttonNavigation="/" />
                <HeaderButton buttonName="APP" buttonNavigation="/App" iOS="https://itunes.apple.com/us/app/ga-flight-tracker/id1319102682?ls=1&mt=8" Android="https://play.google.com/store/apps/details?id=ca.oxigen.apps.gaflighttracker" target="_blank"/>
                <HeaderButton buttonName="LIVE TRACKING" buttonNavigation="/LiveMap" color="#cfde00" />
                <HeaderButton buttonName="VIDEOS" buttonNavigation="/Videos" color="#ff0000"/>
                <HeaderButton buttonName="PILOTS" buttonNavigation="/Pilots" />
                <HeaderButton buttonName="AIRCRAFT" buttonNavigation="/Aircraft" />
            </div>
        );
    }

}

export { HeaderMenuPC };