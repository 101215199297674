import React from "react"
import GoogleMapReact from 'google-map-react';
import { text } from "stream/consumers";
import { APIFunctions } from "../../modules/APIFunctions";
import { gaftAPI } from '../../modules/gaftapi';
import { Button } from "reactstrap";
//const gaftAPI = require('../../modules/gaft');

const StatsBox = (obj: any) => <div className="statsBox d-none d-md-inline">
    Globally Tracking: {obj.onlinePlanes}<br />
    Tracking in View: {obj.planesInView}<br />
    <hr />
    <Button onPress={() => {
        const event = new CustomEvent("showVFRMap");
        document.dispatchEvent(event);
        console.log("VFR MAP");
    }}>VFR Map</Button>&nbsp;&nbsp;
    <Button onPress={(e:any) => {
        const event = new CustomEvent("showTerrainMap");
        document.dispatchEvent(event)
    }}>Terrain Map</Button>

</div>;

class LiveTracking extends React.Component<any, any> {

    lat = 0;
    lng = 0;
    timer: any;
    timer2: any;
    timer3: any;
    timer4: any;
    timer5: any;
    maps: any;
    map: any;
    ms: string = "";
    mapSettings;
    a: any;
    b: any;
    wx: any;
    t: any;
    cycle: string = "";
    showLabels = true;
    showPrecip = false;
    clickedMarker: string = "---";
    markerDetails: any;
    lastPos: any;
    trail: any = [];
    focused: string = "";
    navLine: any;
    destinationLatLng: any;

    constructor(props: any) {
        super(props);
       
        this.state = {
            showDetails: false, lat: 0, lng: 0, onlinePlanes: 0, planesInView: 0, planes: [], bounds: { lat1: 0, lat2: 0, lng1: 0, lng2: 0, markers: [] }
        };
        this.ms = localStorage.getItem("mapSettings") || "";
        if (this.ms == "") {
            localStorage.setItem("mapSettings", JSON.stringify({ lat: 43.5879936, lng: -79.6655616, zoom: 10, mapTypeId: 'terrain' }));
            this.ms = localStorage.getItem("mapSettings") || "";
        }
        this.mapSettings = JSON.parse(this.ms);


    }

   

    componentDidMount() {
        
        navigator.geolocation.getCurrentPosition(this.gotPos, this.posError);
        document.addEventListener("gotFlightCount", this.updatePlaneCount);
        document.addEventListener("flightsUpdated", this.flightsUpdated);
        document.addEventListener("gotCycleData", this.gotCycleData);
        this.setState({ markers: [] });
        this.getPlanes();
        
        this.timer = setInterval(this.getPlanes, 5000);
        this.timer5 = setInterval(this.updatePrecip, 60000 * 10);
    }

    gotCycleData = (aa:any) => {
        console.log("CYCLE DATA:",aa.detail)
        // VFR Sectional Map
        this.cycle = aa.detail;
        
    }

    showVFRMap = () => {
        this.map.setMapTypeId("sectional");
        console.log("Showing VFR Sectional");
        this.updateMapSettings();
    }

    showTerrainMap = () => {
        this.map.setMapTypeId("terrain");
        console.log("Showing Terrain Map");
        this.updateMapSettings();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        clearInterval(this.timer2);
        clearInterval(this.timer3);
        clearInterval(this.timer4);
        clearInterval(this.timer5);
        document.removeEventListener("gotFlightCount", this.updatePlaneCount);
        document.removeEventListener("flightsUpdated", this.flightsUpdated);
        document.removeEventListener("gotCycleData", this.gotCycleData);
    }

    updatePlaneCount = (e: any) => {
        
        //console.log(e.detail[0])
        this.setState({onlinePlanes: e.detail[0]})
    }

    getPlanes = () => {
        var a = new APIFunctions();
        a.GetADSBFlightCount();
        
    }

    flightsUpdated = (e: any) => {
        var pp = e.detail;
        if (this.focused != "") {
            var filtered = e.detail.filter((x: any) => x.callSign == this.focused);
            pp = filtered;
            var currentFlights = this.state.markers;
            currentFlights.map((f: any) => {
                if (f.flight.callSign != this.focused) {
                    f.marker.setMap(null);
                }
            })
        }
        this.setState({ planesInView: e.detail.length, planes: pp });
        
    }

    async apiIsLoaded(map:any, maps:any) {
        
        this.maps = maps;
        this.map = map;
        this.setState({ trail: [] });
            //await fetch("https://api.rainviewer.com/public/weather-maps.json", {
            //    method: 'GET',
            //}).then(response => response.json())
            //    .then(json => {
            //        this.t = json;
            //    });
           
        console.log(this.t);
        
        var api = new APIFunctions();
        await api.getCycle();
        let aa: string = this.cycle;
        console.log("USING CYCLE:",aa);

        this.a = {
            minZoom: 7,
            maxZoom: 11,
            name: 'sectional',
            tileSize: new this.maps.Size(256, 256),
            getTileUrl: (function (f: any, e: any) {
                var d = f.x % (1 << e);
                if (d < 0) {
                    d = d + (1 << e)
                }
                var g = (1 << e) - f.y - 1;
                //var a = new APIFunctions();
                //var resp = await a.getTileImage(e, g, d);
                //console.log(resp);
                return "https://api.gaflighttracker.com/Functions/GetTileAPI/" + e + "/" + g + "/" + d + ""
            })
        }
        this.map.mapTypes.set('sectional', new this.maps.ImageMapType(this.a));

        
        this.b = {
            minZoom: 0,
            maxZoom: 22,
            opacity: .6,
            name: 'PRECIP',
            tileSize: new this.maps.Size(256, 256),
            getTileUrl: ((f: any, e: any) => {
                var d = f.x % (1 << e);
                if (d < 0) {
                    d = d + (1 << e)
                }
                var g = (1 << e) - f.y - 1;
                //console.log(this.t, this.t.host + this.t.radar.nowcast[this.t.radar.nowcast.length - 1] + "/256/" + e + "/" + f.x + "/" + f.y + "/4/1_1.png");
                return this.t.host + this.t.radar.nowcast[this.t.radar.nowcast.length - 1].path + "/256/" + e + "/" + f.x + "/" + f.y + "/4/1_1.png";
            }),
        };
        this.wx = new this.maps.ImageMapType(this.b);

        this.updateFlights();
        this.timer2 = setInterval(this.updateFlights, 3000)
        this.timer3 = setInterval(this.removeOldFlights, 1000);
        this.timer4 = setInterval(this.moveMarkers, 100);
    }

    

    moveMarkers = () => {
        var markers = this.state.markers;
        if (markers.length < 30) {
            markers.map((m: any) => {
                var mps = (m.flight.spd * 0.51444444444) / 10; //* .05;
                //mps = mps / 10; // half a second.
                var lat = m.marker.getPosition().lat();
                var lng = m.marker.getPosition().lng();
                var latlng = this.calculateNewPostionFromBearingDistance(lat, lng, m.flight.track, mps);
                //if (m.flight.spd > 10)
                m.marker.setPosition({ lat: latlng[0], lng: latlng[1] });
                var g = new gaftAPI();

                var z = this.map.getZoom();

                if (z >= 10)
                    g.gaftAPI.sizing = 80;
                else
                    g.gaftAPI.sizing = 35;

                //g.gaftAPI.sizing = 35;
                var _icon: any;
                if (this.clickedMarker == m.flight.callSign) {
                    _icon = g.gaftAPI.getIcon(m.flight.planeType.toUpperCase(), m.flight.track, "red");
                    if (this.navLine) {
                        this.navLine.setPath([new this.maps.LatLng(latlng[0], latlng[1]), this.destinationLatLng]);
                    } else {
                        this.navLine = new this.maps.Polyline({
                            path: [new this.maps.LatLng(latlng[0], latlng[1]), this.destinationLatLng],
                            strokeColor: "#FE18D1",
                            strokeOpacity: 0.5,
                            strokeWeight: 4,
                            map: this.map,
                            geodesic: true,
                        });
                    }
                    //console.log("CHANGING " + m.flight.callSign + " TO BLUE");

                } else {
                    _icon = g.gaftAPI.getIcon(m.flight.planeType.toUpperCase(), m.flight.track, "yellow");
                }
                m.marker.setIcon({
                    url: _icon.url,
                    anchor: new this.maps.Point(_icon.scaled.w / 2, _icon.scaled.h / 2),
                    labelOrigin: new this.maps.Point(_icon.labelOrigin.x, _icon.labelOrigin.y),
                    origin: new this.maps.Point(_icon.origin.x, _icon.origin.y),
                    scaledSize: new this.maps.Size(_icon.scaledSize.w, _icon.scaledSize.h),
                    size: new this.maps.Size(_icon.size.w, _icon.size.h),

                });
                //m.flight.lat = latlng[0];
                //m.flight.lng = latlng[1];
                //console.log(mps,"** OLD **", lat, lng, "**NEW**", latlng[0], latlng[1]);
            });
            if (this.state.planeShown) {
                var ps: any = this.state.planeShown;
                var newalt = (ps.vsi / 60000) * 100;
                ps.alt = ps.alt + newalt;
                this.setState({ planeShown: ps });
            }
            this.setState({ markers: markers });
        }
    }

    calculateNewPostionFromBearingDistance = (lat:number, lng:number, bearing:number, distance:number) => {
        var R = 6371 * 1000; // Earth Radius in Km

        var lat2 = Math.asin(Math.sin(Math.PI / 180 * lat) * Math.cos(distance / R) + Math.cos(Math.PI / 180 * lat) * Math.sin(distance / R) * Math.cos(Math.PI / 180 * bearing));
        var lon2 = Math.PI / 180 * lng + Math.atan2(Math.sin(Math.PI / 180 * bearing) * Math.sin(distance / R) * Math.cos(Math.PI / 180 * lat), Math.cos(distance / R) - Math.sin(Math.PI / 180 * lat) * Math.sin(lat2));

        return [180 / Math.PI * lat2, 180 / Math.PI * lon2];
    };

    removeOldFlights = () => {
        var oldFlights = this.state.markers.filter((x: any) => this.timeSince(x.lastUpdated).seconds > 20);
        var currentFlights = this.state.markers.filter((x: any) => this.timeSince(x.lastUpdated).seconds <= 20);
        //console.log("*** OLD FLIGHTS ***", oldFlights);
        //console.log("*** CURRENT FLIGHTS ***", currentFlights);
        oldFlights.map((m:any) => {
            m.marker.setMap(null);
        });
        this.setState({ markers: currentFlights });
    }

    timeSince = (when:Date) => { // this ignores months
        var obj:any = {};
        obj._milliseconds = (new Date()).valueOf() - when.valueOf();
        obj.milliseconds = obj._milliseconds % 1000;
        obj._seconds = (obj._milliseconds - obj.milliseconds) / 1000;
        obj.seconds = obj._seconds % 60;
        obj._minutes = (obj._seconds - obj.seconds) / 60;
        obj.minutes = obj._minutes % 60;
        obj._hours = (obj._minutes - obj.minutes) / 60;
        obj.hours = obj._hours % 24;
        obj._days = (obj._hours - obj.hours) / 24;
        obj.days = obj._days % 365;
        // finally
        obj.years = (obj._days - obj.days) / 365;
        return obj;
    }

    updateFlights = () => {
        var a = new APIFunctions();
        var bounds = this.map.getBounds();
        a.GetLiveFlights({
            lat1: bounds.getNorthEast().lat(), lng1: bounds.getNorthEast().lng(),
            lat2: bounds.getSouthWest().lat(), lng2: bounds.getSouthWest().lng()
        });
    }

    gotPos = (pos:any) => {
        this.setState({ lat: pos.coords.latitude, lng: pos.coords.longitude });
        this.lat = pos.coords.latitude;
        this.lng = pos.coords.longitude;
        console.log(this.lat, this.lng);
    }

    posError = (e:any) => {
        console.log("*** POS ERROR **", e);
    }

    markerClicked = (m: any) => {
        console.log("MARKER CLICKED: ", m);
    }

    processAltColor(Altitude:number) {
        if (Altitude < 500) {
            return "#00ff00";
        } else if (Altitude < 700) {
            return "#22FF00";

        } else if (Altitude < 800) {
            return "#33FF00";

        } else if (Altitude < 900) {
            return "#44FF00";
        } else if (Altitude < 1000) {
            return "#55ff00";
        } else if (Altitude < 1100) {
            return "#66ff00";
        } else if (Altitude < 1200) {
            return "#77ff00";
        } else if (Altitude < 1300) {
            return "#88ff00";
        } else if (Altitude < 1400) {
            return "#99ff00";
        } else if (Altitude < 1500) {
            return "#AAff00";
        } else if (Altitude < 1600) {
            return "#BBff00";
        } else if (Altitude < 1700) {
            return "#CCff00";
        } else if (Altitude < 1800) {
            return "#DDff00";
        } else if (Altitude < 1900) {
            return "#EEff00";
        } else if (Altitude < 2000) {
            return "#ffFF00";

        } else if (Altitude < 2500) {
            return "#FFBB33";
        } else if (Altitude < 3000) {
            return "#FF9966";
        } else if (Altitude < 3500) {
            return "#FF4499";
        } else if (Altitude < 4000) {
            return "#FF00FF";
        } else if (Altitude < 5000) {
            return "#BB00FF";
        } else if (Altitude < 6000) {
            return "#9900FF";
        } else if (Altitude < 7000) {
            return "#7700FF";
        } else if (Altitude < 8000) {
            return "#5500FF";
        } else if (Altitude < 10000) {
            return "#3300FF";
        } else if (Altitude < 13500) {
            return "#1100ff";
        } else {
            return "#0000ff";
        }

    }

    PlaneIcon = (item: any, icon: any) => {

        var marker = (this.state.markers) ? this.state.markers.find((x:any) => x.markerID == item.callSign) : null;
        if (!marker) {
            var m = new this.maps.Marker();
            //console.log(icon);
            m.setTitle(item.callSign);
            m.setPosition(new this.maps.LatLng(item.lat, item.lng ));
            //m.setOptions({ optimized: true });
            //console.log(item);
            var action = "Cruise";
            if (item.vsi > 0)
                action = "Climbing";
            else if (item.vsi < 0)
                action = "Descending";
            if (this.showLabels)
                m.setLabel({ className: 'planeLabel' + action, color: action == "Cruise" ? '#fff' : action == "Climbing" ? "#FFFF00" : "#00FFFF",  text: item.callSign + " - " + item.alt.toFixed(0) + "' " + action });

            m.setIcon({
                url: icon.url,
                anchor: new this.maps.Point(icon.scaled.w / 2, icon.scaled.h / 2),
                labelOrigin: new this.maps.Point(icon.labelOrigin.x, icon.labelOrigin.y),
                origin: new this.maps.Point(icon.origin.x, icon.origin.y),
                scaledSize: new this.maps.Size(icon.scaledSize.w, icon.scaledSize.h),
                size: new this.maps.Size(icon.size.w, icon.size.h),

            });
            m.addListener('click', async () => {
                let planeDetails0: any;
                //var trail0 = this.trail;
                this.trail.map((t: any) => {
                    t.setMap(null);
                });
                this.trail = [];
                //this.setState({ trail: trail0 });
                //this.setState({ trail: [] });
                await fetch("https://www.gaflighttracker.com/LiveTracking/getFlightInfo?callsign=" + item.callSign.toLowerCase(),
                    {
                        method: 'GET',
                    }).then(response => response.json())
                    .then(json => {
                        planeDetails0 = JSON.parse(json);
                    });

                let p0: any;
                let trail: any = [];
                let p: any;
                if (planeDetails0.airport && planeDetails0.airport.destination && planeDetails0.airport.destination.position) {
                    this.destinationLatLng = new this.maps.LatLng(planeDetails0.airport.destination.position.latitude, planeDetails0.airport.destination.position.longitude);
                }
                if (planeDetails0 && planeDetails0.trail) {
                    planeDetails0.trail.reverse().map((z: any, idx: any) => {

                        var pp = [];
                        if (idx == 0) {
                            p0 = new this.maps.LatLng(z.lat, z.lng);
                        }
                        p = new this.maps.LatLng(z.lat, z.lng)
                        pp.push(p0);
                        pp.push(p);
                        p0 = p;
                        var g = new gaftAPI();
                        this.trail.push(new this.maps.Polyline({
                            path: pp,
                            strokeColor: this.processAltColor(z.alt),
                            strokeOpacity: 0.8,
                            strokeWeight: 4,
                            map: this.map,
                        }));

                    });
                }
                this.lastPos = p;
                //this.setState({  });
                console.log("PLANE DETAILS:", planeDetails0);
                this.clickedMarker = item.callSign;
                this.markerDetails = planeDetails0;
                console.log(item);
                console.log("CLICKED MARKER:", this.clickedMarker);
                //this.setState({  });
                this.setState({ showDetails: true,  planeShown: item.flight });
            });
            m.setMap(this.map);
            var markers = this.state.markers;
            markers.push({ markerID: item.callSign, marker: m, lastUpdated: new Date(), flight: item, oldLat: item.lat, oldLng: item.lng });
            
        } else {
            var midx = this.state.markers.findIndex((x: any) => x.markerID == item.callSign);
            //console.log(marker,midx,this.state.markers);
            if (item.callSign == this.clickedMarker) {
                
                //console.log("PLANE SHOWN:", this.state.planeShown);
                //var trail = this.trail;
                var p = new this.maps.LatLng(item.lat, item.lng);
                var pp = [];
                //console.log(this.lastPos.lat(),this.lastPos.lng());
                pp.push(this.lastPos);
                pp.push(p);
                this.trail.push(new this.maps.Polyline({
                    path: pp,
                    strokeColor: this.processAltColor(item.alt),
                    strokeOpacity: 0.8,
                    strokeWeight: 4,
                    map: this.map,
                }));
                this.lastPos = p;
                this.setState({ planeShown: item });//, trail: trail });
            }
            
            if (item.lat == marker.oldLat && item.lng == marker.oldLng) {

            } else {
                marker.marker.setPosition(new this.maps.LatLng(item.lat, item.lng));
                //console.log("***", marker.oldLat, item.lat, marker.oldLng, item.lng);
                marker.oldLat = item.lat;
                marker.oldLng = item.lng;
            }
            
            marker.marker.setIcon({
                url: icon.url,
                anchor: new this.maps.Point(icon.scaled.w / 2, icon.scaled.h /2),
                labelOrigin: new this.maps.Point(icon.labelOrigin.x, icon.labelOrigin.y),
                origin: new this.maps.Point(icon.origin.x, icon.origin.y),
                scaledSize: new this.maps.Size(icon.scaledSize.w, icon.scaledSize.h),
                size: new this.maps.Size(icon.size.w, icon.size.h),

            });
            var action = "Cruise";
            if (item.vsi > 0)
                action = "Climbing";
            else if (item.vsi < 0)
                action = "Descending";
            if (this.showLabels)
                marker.marker.setLabel({ className: 'planeLabel' + action, color: action == "Cruise" ? '#fff' : action == "Climbing" ? "#FFFF00" : "#00FFFF", text: item.callSign + " - " + item.alt.toFixed(0) + "' " + action });

            marker.lastUpdated = new Date();
            marker.flight = item;
            var markers = this.state.markers;
            markers[midx] = marker;
            
        
        }
        
    }

    updateMapSettings = () => {
        //console.log(this.map.getZoom(),this.map.getMapTypeId(),this.map.getCenter().lat(), this.map.getCenter().lng());
        localStorage.setItem("mapSettings", JSON.stringify({
            lat: this.map.getCenter().lat(),
            lng: this.map.getCenter().lng(),
            zoom: this.map.getZoom(),
            mapTypeId: this.map.getMapTypeId()
        }));
    }

    showHideLabels = () => {
        this.showLabels = this.showLabels == false; 
        this.state.markers.map((m: any, idx: number) => {
            var action = "Cruise";
            if (m.flight.vsi > 0)
                action = "Climbing";
            else if (m.flight.vsi < 0)
                action = "Descending";
            if (this.showLabels)
                m.marker.setLabel({ className: 'planeLabel' + action, color: action == "Cruise" ? '#fff' : action == "Climbing" ? "#FFFF00" : "#00FFFF", text: m.flight.callSign + " - " + m.flight.alt.toFixed(0) + "' " + action });
            else {
                m.marker.setLabel(null);
            }
        });
    }

    showHidePrecip = async () => {
        await fetch("https://api.rainviewer.com/public/weather-maps.json", {
            method: 'GET',
        }).then(response => response.json())
            .then(json => {
                this.t = json;
            });

        this.showPrecip = this.showPrecip == false;
        if (this.showPrecip) {
            //console.log("Showing Precip");
            this.map.overlayMapTypes.insertAt(0, new this.maps.ImageMapType(this.b));
        } else {
            //console.log("Hiding Precip");
            this.map.overlayMapTypes.clear();
        }
    }

    updatePrecip = async () => {
        await fetch("https://api.rainviewer.com/public/weather-maps.json", {
            method: 'GET',
        }).then(response => response.json())
            .then(json => {
                this.t = json;
            });
    }

    render() {
        
       
        return (<div style={{ height: '100vh', width: '100%', position: 'fixed', top: 0, left: 0 }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyBfFWOcuPVivBfGzs-cijL3DVEjZm2GtZw" }}
                defaultCenter={{ lat: this.mapSettings.lat, lng: this.mapSettings.lng }}
                center={{ lat: this.mapSettings.lat, lng: this.mapSettings.lng }}
                defaultZoom={this.mapSettings.zoom}
                yesIWantToUseGoogleMapApiInternals
                options={{ gestureHandling: "greedy", disableDefaultUI: true, mapTypeId: this.mapSettings.mapTypeId }}
                onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps)}
                onDrag={this.updateMapSettings}
                onMapTypeIdChange={this.updateMapSettings}
                onZoomAnimationEnd={this.updateMapSettings}
                
            >
                
            </GoogleMapReact>
            {this.state.planes.map((plane: any, idx: any) => {

                var g = new gaftAPI();

                var z = this.map.getZoom();

                if (z >= 10)
                    g.gaftAPI.sizing = 80;

                else
                    g.gaftAPI.sizing = 35;

                //g.gaftAPI.sizing = 35;
                var _icon: any
                if (plane.callSign == this.clickedMarker) { 
                  _icon  = g.gaftAPI.getIcon(plane.planeType.toUpperCase(), plane.track, "red");
            } else {
                  _icon  = g.gaftAPI.getIcon(plane.planeType.toUpperCase(), plane.track, "yellow");

            }
                    this.PlaneIcon(plane, _icon);
               
            })}
            {this.state.showDetails ? 
                <div id="DetailWindow2" style={{position: 'absolute', top: 110, left: 5, minWidth: '350px', maxWidth: '350px', zIndex: 10000, padding: '5px',  alignItems: 'center', justifyContent: 'center'}} className="col-md-2 col-sm-12">
                    <div>
                        <div className="container" style={{ backgroundColor: 'rgba(0,0,0,.8)', borderRadius: '10px', height: '100%', paddingBottom: '25px', }} >
                            <div className="row" style={{ padding: '10px' }}>
                                <div className="col-12" style={{ color: '#fff', textAlign: 'right', cursor: 'pointer' }} onClick={() => {
                                    this.setState({ showDetails: false }); this.clickedMarker = "---"; this.trail.map((t: any) => {
                                        t.setMap(null);
                                    });
                                    this.trail = [];
                                    this.focused = "";
                                    this.destinationLatLng = null;
                                    if (this.navLine) {
                                        this.navLine.setMap(null);
                                        this.navLine = null;
                                    }
                                }}>[ CLOSE ]</div>
                            </div>
                            <div className="row" style={{ padding: '5px' }} >
                                <div className="col-12 black" style={{ overflow: 'hidden', color: '#fff' }} >
                                    <div>
                                        <span className="detailCallSign" style={{ fontSize: '24px' }}>{this.clickedMarker}</span> / <span className="detailFlightNumber" style={{ fontSize: '24px' }}>{this.markerDetails.identification ? this.markerDetails.identification.callsign : ""}</span> <Button className="btn btn-primary normal" style={{ fontSize: '10pt', padding: 5 }} onClick={() => { this.focused = this.markerDetails.aircraft.registration }}>Focus</Button><br />
                                    </div>
                                    <div style={{ fontSize: '9pt', overflow: 'hidden', width: '100%', textOverflow: 'ellipsis', display: 'inline-block', whiteSpace: 'nowrap' }} >
                                        <span className="detailModel">{this.markerDetails.aircraft ? this.markerDetails.aircraft.model.text : "Unknown Model"}</span>
                                    </div>
                                    <div style={{ fontSize: '9pt', overflow: 'hidden', width: '100%', textOverflow: 'ellipsis', display: 'inline-block', whiteSpace: 'nowrap' }} >
                                        <span className="detailAirline">{this.markerDetails.airline ? this.markerDetails.airline.name : "Unknown Operator"}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12" style={{ padding: 0, overflow: 'hidden' }} >
                                    <img id="detailPilotPic" className="ppic" src={this.markerDetails.aircraft && this.markerDetails.aircraft.images && this.markerDetails.aircraft.images.large ? this.markerDetails.aircraft.images.large[0].src : 'https://www.gaflighttracker.com/Functions/PlaneImage?img=' + this.clickedMarker} style={{ width: '350px', backgroundColor: '#fff' }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-6" style={{ textAlign: 'center', backgroundColor: 'rgba(255,255,255,.5)', lineHeight: '15px', padding: 10 }} >
                                            <div style={{ width: '100%', textAlign: 'center', textOverflow: 'ellipsis', display: 'inline-block', whiteSpace: 'nowrap' }} >

                                                <span className="bold" style={{ fontSize: '10px' }} >DEPART</span>
                                            </div>
                                            <div style={{ width: '100%', textAlign: 'center', textOverflow: 'ellipsis', display: 'inline-block', whiteSpace: 'nowrap' }} >

                                                <span className="detailDeparture black" style={{ fontSize: '24px' }}>{this.markerDetails.airport && this.markerDetails.airport.origin ? this.markerDetails.airport.origin.code.icao : "UNKNOWN"}</span>
                                            </div>
                                            <div style={{width: '100%', textAlign: 'center', textOverflow: 'ellipsis' }}>

                                                <span className="detailAirportNameDepart normal" style={{ fontSize: '9px' }}>{this.markerDetails.airport && this.markerDetails.airport.origin ? this.markerDetails.airport.origin.name : "UNKNOWN AIRPORT"}</span>
                                            </div>
                                        </div>
                                        <div className="col-6" style={{ borderLeft: '1px #666666 solid', textAlign: 'center', backgroundColor: 'rgba(255,255,255,.5)', lineHeight: '15px', padding: 10 }}>
                                            <div style={{ width: '100%', textAlign: 'center', textOverflow: 'ellipsis', display: 'inline-block', whiteSpace: 'nowrap' }} >
                                                <span className=" bold" style={{ fontSize: '10px' }} >ARRIVE</span>
                                            </div>
                                            <div style={{ width: '100%', textAlign: 'center', textOverflow: 'ellipsis' }} >
                                                <span className="detailArrival black" style={{ fontSize: '24px' }}>{this.markerDetails.airport && this.markerDetails.airport.destination ? this.markerDetails.airport.destination.code.icao : "UNKNOWN"}</span>
                                            </div>
                                            <div style={{ textAlign: 'center', textOverflow: 'ellipsis' }} >
                                                <span className="detailAirportNameArrive normal" style={{ fontSize: '9px' }} >{this.markerDetails.airport && this.markerDetails.airport.destination ? this.markerDetails.airport.destination.name : "UNKNOWN AIRPORT"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="detailStatusMessge black" style={{ width: '100%', padding: 10, backgroundColor: 'rgba(255,255,255,.8)', borderTop: '1px #666666 solid', borderBottom: '1px #666666 solid', borderLeft: '15px ' + (this.markerDetails.status ? this.markerDetails.status.icon : 'grey') + ' solid' }} >{this.markerDetails.status ? this.markerDetails.status.text : "UNKNOW STATUS"}</div>
                            </div>
                            <div className="row" style={{ backgroundColor: 'rgba(255,255,255,.8)' }} >
                                <div className="col-6" style={{ textAlign: 'center' }} >
                                    <span className="bold" style={{ fontSize: '9px' }} >ALTITUDE</span>
                                </div>
                                <div className="col-6" style={{ textAlign: 'center', borderLeft: '1px #666666 solid' }} >
                                    <span className="bold" style={{ fontSize: '9px' }} >VERTICLE SPEED</span>
                                </div>
                            </div>
                            <div className="row" style={{ backgroundColor: 'rgba(255,255,255,.8)' }} >
                                <div className="col-6 normal" style={{ textAlign: 'center', fontSize: '9px' }} >
                                    <span className="detailAltitude black" style={{ fontSize: '24px' }} >{this.state.planeShown ? this.state.planeShown.alt.toFixed(0) : '---'}</span><br />Calibrated
                                </div>
                                <div className="col-6 normal" style={{ fontSize: '9px', textAlign: 'center', borderLeft: '1px #666666 solid' }} >
                                    <span className="detailVSI black" style={{ fontSize: '24px' }}>{this.state.planeShown ? this.state.planeShown.vsi : '---'}</span><br />Feet Per Minute
                                </div>
                            </div>
                            <div className="row" style={{ backgroundColor: 'rgba(255,255,255,.8)', borderTop: '1px #666666 solid' }} >
                                <div className="col-6" style={{ textAlign: 'center' }}>
                                    <span className="bold" style={{fontSize: '9px'}}>AIRSPEED</span>
                                </div>
                                <div className="col-6" style={{ textAlign: 'center', borderLeft: '1px #666666 solid' }}>
                                    <span className="bold" style={{ fontSize: '9px' }}>CURRENT TRACK</span>
                                </div>
                            </div>
                            <div className="row" style={{backgroundColor: 'rgba(255,255,255,.8)'}}>
                                <div className="col-6 normal" style={{textAlign: 'center', fontSize: '9px'}}>
                                    <span className="detailSpeed black" style={{ fontSize: '24px' }}>{this.state.planeShown ? this.state.planeShown.spd : '---'}</span><br />Ground Speed (Knots)
                                </div>
                                <div className="col-6 normal" style={{ fontSize: '9px', textAlign: 'center', borderLeft: '1px #666666 solid' }} >
                                    <span className="detailTrack black" style={{ fontSize: '24px' }} >{this.state.planeShown ? this.state.planeShown.track : '---'}</span><br />
                                </div>
                            </div>
                            <div className="row" style={{ backgroundColor: 'rgba(255,255,255,.8)', borderTop: '1px #666666 solid' }} >
                                <div className="col-12" style={{ textAlign: 'left' }}>
                                    <span className="bold" style={{ fontSize: '9px' }}>FLIGHT NOTES</span>
                                </div>

                            </div>
                            <div className="row" style={{backgroundColor: 'rgba(255,255,255,.8)', height: '100%'}}>
                                <div className="col-12 normal" style={{ textAlign: 'left', fontSize: '9px' }}>
                                    <span className="detailFlightNotes bold" style={{ fontSize: '12px' }}></span><br />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                :
                <></>}

            <div className="statsBox d-none d-md-inline">
                Globally Tracking: {this.state.onlinePlanes}<br />
                Tracking in View: {this.state.planes.length}<br />
                <hr />
                <Button onClick={this.showVFRMap}>VFR Map</Button>&nbsp;&nbsp;
                <Button onClick={this.showTerrainMap}>Terrain Map</Button>
                <hr />
                <Button onClick={this.showHideLabels}>Labels</Button>&nbsp;&nbsp;
                <Button onClick={this.showHidePrecip}>Precip</Button>
            </div>;
        </div>);
    }

}

export { LiveTracking }