import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGear, faRightFromBracket, faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { AccountManagement } from './AccountManagement';
import { User } from '../models/User';
import { Link } from 'react-router-dom';
import { APIFunctions } from '../modules/APIFunctions';
import { isNumber } from 'util';

library.add(faUser, faRightFromBracket, faGear, faEnvelope);

interface IProps {
}

interface IState {
    ShowLoginWindow: Boolean
    isLoggedIn: Boolean
    NewMessages: number
    videos: any
}


class AccountSignIn extends React.Component<IProps, IState> {

    _timer:any;

    constructor(props: IProps) {
        super(props);
        
        this.state = {
            isLoggedIn: AccountManagement.isLoggedIn(),
            ShowLoginWindow: false,
            NewMessages: 0,
            videos: null,

        }
        
    }

    checkLogin = async () => {
        //this.setState({ isLoggedIn:  });
        
    }

    componentDidMount() {
        document.addEventListener("LoggedIn", this.loggedIn);
        document.addEventListener("LoggedOut", this.loggedOut);
        document.addEventListener("MessageUpdate", this.messageCountUpdate);
        this.messageCountUpdate();

        
    }

    componentWillUnmount() {
        document.removeEventListener("LoggedIn", this.loggedIn);
        document.removeEventListener("LoggedOut", this.loggedOut);
        document.removeEventListener("MessageUpdate", this.messageCountUpdate);
        
    }

    messageCountUpdate = () => {
        var msgs:string = sessionStorage.getItem("messages") || "0";
        //if (msgs && isNumber(msgs)) {
            
            this.setState({ NewMessages: parseInt(msgs) });
            //console.log("MESSAGES:", msgs);
        //} else { 
        //console.log("UNKOWN:", msgs);
        //}
    }

    loggedIn = async () => {
        this.setState({ isLoggedIn: true });
        console.log("Logged in");
        
        //var api = new APIFunctions();
        //var profile = (JSON.parse(sessionStorage.getItem("u") || ""));
        //if (profile) {
        //    await api.getNewMessageCount(profile.UserID).then(resp => {
        //        const event = new CustomEvent("MessageUpdate", { detail: resp });
        //        document.dispatchEvent(event);
        //        //this.setState({ NewMessages: resp });
        //        console.log("NEW MESSAGES:", resp)
        //    });
        //}
    }

    loggedOut = () => {
        this.setState({ isLoggedIn: false });
        console.log("Logged Out");
    }

    render() {
        //AccountManagement.isLoggedIn();
        var profile = new User();
        if (this.state.isLoggedIn) {
            profile = (JSON.parse(sessionStorage.getItem("u") || ""));
            //var api = new APIFunctions();
            //api.getNewMessageCount(profile.UserID).then(resp => { this.setState({ NewMessages: resp }); /*console.log("NEW MESSAGES:", resp)*/ });

        }
        return (
            !this.state.isLoggedIn ?
                <>
                    <div className="col-12" >
                        <div className="row">
                            <div className="col-12 ">
                                <Button onClick={() => {
                                    const event = new CustomEvent("showLoginPopup");
                                    document.dispatchEvent(event);
                                }} >
                                    <span className="fontLogin">
                                        <FontAwesomeIcon icon="user" />&nbsp;&nbsp;Sign in
                                    </span>
                                </Button><br />
                                <Link to="/register" style={{ textDecoration: 'none' }}><span className="fontRegister">Not registered?</span></Link>
                            </div>
                        </div>
                    </div>

                </>
                : <>

                    <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        
                        <div className="col-4" style={{ padding: 0, cursor: 'pointer', position: 'relative' }} onClick={() => { this.gotoAccount(); }}>
                            
                            <img src={'data:image/png;base64,' + profile.ProfilePicture} className="profilePictureHeader" alt="Profile" />
                            {this.state.NewMessages > 0 ?
                                <>
                                    <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#ff0000', color: 'white', fontSize: '12px', fontWeight: 'bold', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 7, boxShadow: '0px 0px 3px rgba(0,0,0,.8)' }}><FontAwesomeIcon icon="envelope"></FontAwesomeIcon>&nbsp;&nbsp;{this.state.NewMessages}</div>
                                </>
                                : <></>}
                        </div>
                        <div className="col-8">
                            <div className="col-12 profileHeaderText" style={{ textAlign: 'left', cursor: 'pointer' }} onClick={() => {
                            this.gotoAccount() }}>
                                {profile.FirstName}
                        </div>
                            <div className="col-12 profileHeaderSignout" style={{ textAlign: 'left', cursor: 'pointer' }} onClick={() => {
                                this.signOut()
                            }}>
                                <FontAwesomeIcon icon="right-from-bracket" />&nbsp;&nbsp;Sign Out
                                </div>
                            </div>
                        </div>
                    </>);
                
        
    }

    gotoAccount = () => {
         
        window.location.href = "/Account";
        return true;
    }

    signOut = () => {
        AccountManagement.Logout();
    }

    ShowLoginWindow = () => {
       
    }
}

export { AccountSignIn }