import React, { Component } from 'react';

class Disclaimer extends Component<any, any> {

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="col-12 registerDiv container">
                    <div className="row divCenter col-12">
                        <div className="col-md-6 col-sm-12 divCenter" style={{ marginBottom: '20px' }}><h2>Disclaimer & Usage Policy</h2></div>
                    </div>
                    <div className="row" style={{ marginBottom: '20px' }}>
                        <div className="col-12">
                            <p>
                                This document governs your access to the GA Flight Tracker web site and use of the iOS and Android App. The GA Flight Tracker web site and Apps are only available to you on the condition that you understand and agree to all terms and disclosures presented on this page. This document is subject to regular change; please view regularly.
                                <br /><br />
                                <b>Your Obligations To General Aviation Flight Tracker</b>
                                <li>
                                    You will not disguise the origin of your connection to the GA Flight Tracker web site or device application.
                                </li><li>
                                    You will not provide false or inaccurate information to the GA Flight Tracker web site or device application.
                                </li><li>
                                    You will not use the GA Flight Tracker web site or device application for the purpose of conducting illegal activity.
                                </li><li>
                                    You will not abuse the GA Flight Tracker web site or device application with spam, including self-promotion, in any user contributed content.
                                </li><li>
                                    You will not access the GA Flight Tracker web site or device application in any way that intentionally affects or significantly negatively impacts the performance or reliability of the GA Flight Tracker web site and its device applications.
                                </li><li>
                                    With the exception of our API, you will only access the GA Flight Tracker web site with an interactive web browser and not with any program, collection agent, or "robot" for the purpose of automated retrieval of content.
                                </li><li>
                                    You will not use the GA Flight Tracker web site for any aviation, commercial, operational, law enforcement, or judicial activity that relies on the availability, validity, or accuracy of GA Flight Tracker data.
                                </li><li>
                                    You acknowledge that any ideas, concepts, techniques, ("suggestions") for new or existing services submitted to GA Flight Tracker or discussed on the GA Flight Tracker Discussions forums are not confidential or proprietary. GA Flight Tracker will have an unrestricted, irrevocable, world-wide, royalty free right to use, disseminate, display, distribute, and exploit any suggestions.
                                </li><li>
                                    You grant GA Flight Tracker a worldwide, royalty-free, unlimited, irrevocable license to use, transmit, distribute, and sell any submitted photography, media, and data content You acknowledge that the owner of any submitted content continues to retain ownership to the submitted content.
                                </li><li>
                                    You acknowledge that any photos or media submitted must not infringe on the trademark, copyright, or patent rights of any third property.
                                </li><li>
                                    Any intellectual property or content associated with GA Flight Tracker is the sole property of Oxigen Apps. With the exception of data retrieved through the API service, you may not copy, reproduce, distribute, modify, lease, loan, sell, or create creative derivative works of any GA Flight Tracker content.
                                    <br /><br />
                                    <b>General Aviation Flight Tracker's Obligations To You</b>
                                </li><li>
                                    GA Flight Tracker has the right to moderate any and all conversations, discussions, topics, posts, and images, in the discussion forums, photo system, or squawks section without prejudice.
                                </li><li>
                                    GA Flight Tracker may choose to refuse service, account privileges, or access to any individual at any time without explanation or justification.
                                </li><li>
                                    GA Flight Tracker makes no representations about the results to be obtained from using GA Flight Tracker. Any use is at your own risk. The GA Flight Tracker web site and device applications are provided exclusively on an "as is" basis.
                                </li><li>
                                    GA Flight Tracker disclaims any and all warranties, either express or implied, statutory or otherwise, including but not limited to the implied warranties of merchantability, non-infringement of third parties' rights, and fitness for any purpose.
                                </li><li>
                                    GA Flight Tracker makes no representations or warranties about the accuracy, completeness, security, or timeliness of the content, information, or services provided on or through the use of GA Flight Tracker. No information obtained by you from GA Flight Tracker shall create any warranty not expressly stated by GA Flight Tracker in this document.
                                </li></p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export { Disclaimer }