import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppRoutes from './components/app-router';
import { Layout } from './components/layout';
import './App.css';
import { AccountManagement } from './components/AccountManagement';

var _timer: any;

function App() {


    _timer = setInterval(AccountManagement.checkMessages, 60000);
    AccountManagement.checkMessages();

    return (
        
        <BrowserRouter>
            <Layout>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}
                </Routes>
            </Layout>
        </BrowserRouter>
        
    );
}

export default App;
