import React from 'react';
import { Container } from 'reactstrap';

import logoImage from '../images/logo.png';
import { GALogoMobile } from './GALogoMobile';
import { GALogoPC } from './GALogoPC';
import { HeaderMenuPC } from './HeaderMenuPC';
import { AccountSignIn } from './AccountSignin';
import { useNavigate } from "react-router-dom"



interface IProps {
}

interface IState {
    HeaderState?: string;
    showExpandedHeader?: boolean;
}

class Header extends React.Component<IProps, IState> {

    
    
    constructor(props: IProps) {
        super(props);
        this.state = {
            HeaderState: "Header",
            showExpandedHeader: true,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    GoHome = () => {

        window.location.href = '/';
        
    }

    render() {
      
        return (
            <div className={this.state.HeaderState}>
                {this.state.showExpandedHeader ?
                    <><Container style={{ height: '100%' }} className="d-none d-md-inline col-md-12">
                        <div className="container" style={{ height: '100%', marginTop: '-25px' }}>
                            <div className="row col-12" style={{ height: '100%' }} >
                                <div onClick={this.GoHome} style={{ cursor: 'pointer', height: '100%', display: 'flex', flexWrap: 'inherit', alignContent: 'center' }} className="col-md-4">           
                                    <GALogoPC />
                                                             
                                </div>
                                <div className="col-md-6" style={{ height: '100%', display: 'flex', flexWrap: 'inherit', alignContent: 'center' }}>
                                    <HeaderMenuPC />
                                </div>
                                <div className="col-2" style={{ height: '100%', display: 'flex', flexWrap: 'inherit', alignContent: 'center' }}>
                                    <AccountSignIn />
                                </div>
                            </div>
                        </div>
                    </Container>
                        <Container style={{ height: '100%' }} className="d-md-none d-inline col-sm-12">
                            <div className="container" style={{ height: '100%', marginTop: '-25px', position: 'relative' }}>
                                <div className="row col-12" style={{ height: '100%', }}>
                                    <div onClick={this.GoHome} style={{ cursor: 'pointer',height: '100%', display: 'flex', flexWrap: 'inherit', alignContent: 'center' }} className="col-12">
                                       
                                        <GALogoMobile />
                                        
                                    </div>
                                   
                                </div>
                                <div className="col-4" style={{ position: 'absolute', top: 15, right: 5 }}>
                                    <AccountSignIn />
                                </div>
                            </div>
                        </Container></>
                    : <><Container style={{ height: '100%' }} className="d-none d-md-inline col-md-12">
                        <div className="container" style={{ height: '100%', marginTop: '-25px' }}>
                            <div className="row" style={{ height: '100%' }}>
                                <div style={{ height: '100%', display: 'flex', flexWrap: 'inherit', alignContent: 'center' }}>
                                    <img src={logoImage} style={{ height: '100%', padding: 5 }} alt="GA Flight Tracker Logo" /> 
                                </div>
                            </div>
                        </div>
                    </Container>
                        <Container style={{ height: '100%' }} className="d-md-none d-inline col-sm-12">
                            <div className="row" style={{ height: '100%', marginTop: '-25px' }}>
                                <div style={{ height: '100%', display: 'flex', flexWrap: 'inherit', alignContent: 'center' }}>
                                    <img src={logoImage} style={{ height: '100%', padding: 5 }} alt="GA Flight Tracker Logo" />
                                </div>
                            </div>
                        </Container>
                    </>
                }
            </div>
        );
    }

    handleScroll = () => {
        var offset = window.pageYOffset;
        if (offset > 100) {
            this.setState({ HeaderState: "HeaderShrink", showExpandedHeader: false });
            
        } else {
            this.setState({ HeaderState: "Header", showExpandedHeader: true });
        }
    }
    
}

export {Header}