import React, {PropsWithChildren } from 'react';
import { Container } from 'reactstrap';
import { Header } from './header';

import '../css/layout.css';
import { LoginPopup } from './LoginPopup';
import Footer from './footer';
//import { NavMenu } from './NavMenu';


export class Layout extends React.Component<PropsWithChildren> {
    static displayName = Layout.name;

    render() {
        return (
            <div style={{height: '100%'} }> 
                <LoginPopup />
                <Header />
                <div className="contentTop" style={{minHeight: '100%'} }>
                    {this.props.children}
                </div>
                <Footer />
            </div>
        );
    }
}