import React, { PropsWithChildren } from 'react';
import '../css/layout.css';

import logoImage from '../images/logo.png';


export class GALogoMobile extends React.Component<PropsWithChildren> {
    static displayName = GALogoMobile.name;


    render() {
        return (
            <div className="row col-12" style={{ height: '100%' }}>
                <div className="col-3" style={{ height: '100%', display: 'flex', flexWrap: 'inherit', alignContent: 'center' }}>
                    <img src={logoImage} style={{ height: '100%', padding: 5 }} alt="GA Flight Tracker Logo"/>
                </div>
                <div style={{ height: '100%', display: 'flex', flexWrap: 'inherit', alignContent: 'center' }} className="col-6">
                    <div className="col-12 fontLogo1">
                        General Aviation
                    </div>
                    <div className="col-12 fontLogo2">
                        Flight Tracker
                    </div>
                </div>
            </div>
        );
    }
}