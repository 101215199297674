import React, { Component, ReactElement } from 'react';
import {
    Carousel, CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';
import ReactDOMServer from 'react-dom/server';
import bannerImg from '../../images/banner1.jpg';
import appStoreImg from '../../images/app-store-badge.svg';
import googleStoreImg from '../../images/google-play-badge.svg';
import carousel_items from '../../homebanners';
import ContentLoader, { BulletList } from 'react-content-loader'
import { APIFunctions } from '../../modules/APIFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCloudDownload ,faWifi, faUsers, faPlane, faSatelliteDish, faExclamationTriangle, faCloudSunRain, faDrawPolygon, faDraftingCompass, faCompass, faBook } from '@fortawesome/free-solid-svg-icons'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
library.add(faCloudDownload, faWifi, faUsers, faPlane, faSatelliteDish, faExclamationTriangle, faCloudSunRain, faDrawPolygon, faDraftingCompass, faCompass, faBook);


const MyBulletListLoader = () => (
    <ContentLoader
        speed={.5}
        width={400}
        height={150}
        viewBox="0 0 400 150"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        
    >
        <circle cx="10" cy="20" r="8" />
        <rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="50" r="8" />
        <rect x="25" y="45" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="80" r="8" />
        <rect x="25" y="75" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="110" r="8" />
        <rect x="25" y="105" rx="5" ry="5" width="220" height="10" />
    </ContentLoader>
)
//const carousel_items = React.lazy(() => import("../../homebanners"));



class Home extends Component<any,any> {

    animating: boolean = false;
    timer:any;

    constructor(props: any) {
        super(props);
      
        this.state = { globalFlights: 0, GAFTFlights: [], activeIndex: 0, newpilots: [], activepilots: [] }
    }

    
    componentDidMount() {
        document.addEventListener('gotLivePilots', this.handleLivePilots);
        document.addEventListener('gotNewPilots', this.handleNewPilots);
        document.addEventListener('gotActivePilots', this.handleActivePilots);
        document.addEventListener('gotFlightCount', this.handleFlightCount);
        var a: APIFunctions = new APIFunctions();
        a.GetLivePilots();
        a.GetNewPilots();
        a.GetActivePilots();
        a.GetADSBFlightCount();
        this.timer = setInterval(this.UpdateCounts,600000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        document.removeEventListener('gotLivePilots', this.handleLivePilots);
        document.removeEventListener('gotNewPilots', this.handleNewPilots);
        document.removeEventListener('gotActivePilots', this.handleActivePilots);
        document.removeEventListener('gotFlightCount', this.handleFlightCount);
    }

    handleFlightCount = (e: any) => {
        //console.log("*** Flight Stats: ***", e.detail);
        if (e.detail != null && e.detail.Error == null)
        this.setState({globalFlights: e.detail[0]})
    }

    handleActivePilots = (e: any) => {
        //console.log(e.detail)
        //console.log("*** ACTIVE PILOTS ***", e.detail)
        if (e.detail != null && e.detail.Error == null)
        this.setState({ activepilots: e.detail });
    }

    handleNewPilots = (e: any) => {
        //console.log("*** NEW PILOTS ***",e.detail)
        if (e.detail != null && e.detail.Error == null)
        this.setState({ newpilots: e.detail });
    }

    handleLivePilots = (e:any) => {
        if (e.detail != null && e.detail.Error == null)
        this.setState({GAFTFlights: e.detail})
    }

    UpdateCounts = async () => {
       
            var a: APIFunctions = new APIFunctions();
            a.GetLivePilots();
            a.GetNewPilots();
            a.GetActivePilots();
            a.GetADSBFlightCount();
    }


    render() {
       
        

        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            accessibility: true,
            autoplay: true,
            pauseOnHover: true,
            pauseOnFocus: true,
            arrows: false,
            autoplaySpeed: 7000,
        };

        var settings2 = {
            autoplaySpeed: 5000,
            fade: true,
            arrows: false,
            dots: false,
            infinite: true,
            speed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            accessibility: true,
            autoplay: true,
            pauseOnHover: false,
            pauseOnFocus: false,
        };

        var settings3 = {
            autoplaySpeed: 3000,
            fade: true,
            arrows: false,
            dots: false,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            accessibility: true,
            autoplay: true,
            pauseOnHover: false,
            pauseOnFocus: false,
        };

        var options = {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "numeric"
        };
    return( <>
        <section className="main">
            <Slider {...settings2 }>
            <div className="col-sm-12 col-md-12 ">
                <div className="row" style={{margin: 0} }>
                    <div className="col-sm-8 col-md-6">
                        <img className="img-fluid" src="/images/big-hand.png" alt="Iphone" height="100%" />
                        
                    </div>
                    <div className="d-none d-md-flex col-md-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                        <div className="container">
                            <div className="fontBanner2">
                                GA Flight Tracker 4
                            </div>
                            <br />
                            <div className="fontBanner1">
                                Feature rich and re-invisioned from the ground up.
                                <br/><br/>
                            </div>
                            <div className="fontNormal">
                                <ul>
                                    <li>User intiutive design</li>
                                    <li>Flight planning on the fly</li>
                                    <li>Real-time flight route changes and updates</li>
                                </ul>
                                <br /><br/>
                            </div>
                            <div className="fontBanner1">
                                <a href="https://itunes.apple.com/us/app/ga-flight-tracker/id1319102682?ls=1&mt=8" target="_blank"><img className="img-fluid appBadge" src={appStoreImg} /></a>&nbsp;&nbsp;<a href="https://play.google.com/store/apps/details?id=ca.oxigen.apps.gaflighttracker" target="_blank"><img className="img-fluid appBadge" src={googleStoreImg}/></a>
                            </div>
                        </div>
                       
                    </div>
                    <div className="d-sm-flex d-md-none col-sm-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className="fontBanner1"><br/><br/>
                            <a href="https://itunes.apple.com/us/app/ga-flight-tracker/id1319102682?ls=1&mt=8" target="_blank"><img className="img-fluid appBadge" src={appStoreImg} /></a>&nbsp;&nbsp;<a href="https://play.google.com/store/apps/details?id=ca.oxigen.apps.gaflighttracker" target="_blank"><img className="img-fluid appBadge" src={googleStoreImg} /></a>
                            <br/><br/>
                        </div>
                    </div>
                </div>
                </div>
                <div className="col-sm-12 col-md-12 ">
                    <div className="row" style={{ margin: 0 }}>
                        <div className="col-sm-12 col-md-12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div>
                                <div><img className="img-fluid" src="/images/videos.png" alt="Iphone" /></div>
                                <div style={{ textAlign: 'center' }}>
                                    <span className="fontBanner2">Member Videos</span><br /><br/>
                                    <span className="fontNormal">Members can now add videos of<br />
                                        their flying adventures.<br /><br/>

                                        To showcase your video's,<br/>become a member today...<br />
                                        It's <b>QUICK</b> and <b>FREE!</b>
                                    </span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </Slider>
        </section>
        <section className="product">
            <div className="container">       
                <Slider {...settings}>
                    <div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <img className="img-fluid" src="/images/livemap.jpg" /><br /><br/>
                        </div>
                        <div className="col-md-6 col-sm-12 fontNormal" >
                            <span className="fontBanner1" style={{ color: '#000' }}><FontAwesomeIcon icon="wifi" /> Live Flight Tracking</span>
                            <br /><br />
                            <p>
                                There are currently <b>{this.state.globalFlights}</b> flights being tracked worldwide using ADS-B and <b>{this.state.GAFTFlights.length}</b> current GA Flight Tracker flights.
                                </p>
                                <button className="btn btn-secondary" onClick={() => { window.location.href = '/LiveMap'; } }>TRACK FLIGHTS</button>
                        </div>
                        </div>
                    </div>
                    <div>
                    <div className="row">
                       
                        <div className="col-md-12 col-sm-12 fontNormal" >
                            <span className="fontBanner1" style={{ color: '#000' }}><FontAwesomeIcon icon="users" /> New Registered Pilots</span>
                            <br /><br />

                            </div>
                            {this.state.newpilots.map((pilot:any, idx:number) => {
                                return (<div key={idx}>
                                    <div className="container row rowitem" style={{fontSize: '14pt'} }>
                                        <div className="col-6 elips">
                                            {pilot.isPublic ? <><FontAwesomeIcon icon="user" />&nbsp;<a href={"/viewPilot/" + pilot.UserID} >{pilot.LastName} / {pilot.FirstName}</a></>
                                                
                                                : <><FontAwesomeIcon icon="user" />&nbsp;{pilot.LastName} / {pilot.FirstName}</>}
                                        </div>
                                        <div className="col-6 elips" style={{textAlign: 'right'} }>
                                            {new Date(parseInt(pilot.LastOnline.match(/\d+/)[0])).toLocaleString("en", { day: "2-digit" })}&nbsp;
                                            {new Date(parseInt(pilot.LastOnline.match(/\d+/)[0])).toLocaleString("en", { month: "short" })}&nbsp;
                                            {new Date(parseInt(pilot.LastOnline.match(/\d+/)[0])).toLocaleString("en", { year: "numeric" })}
                                        </div>
                                    </div>
                                </div>);
                            })}
                        </div>
                    </div>
                    <div>
                    <div className="row">

                        <div className="col-md-12 col-sm-12 fontNormal" >
                            <span className="fontBanner1" style={{ color: '#000' }}><FontAwesomeIcon icon="plane" /> Active Pilots</span>
                            <br /><br />

                            </div>
                            {this.state.activepilots.map((pilot: any, idx: number) => {
                                return (<div key={idx}>
                                    <div className="col-12 row rowitem" style={{ fontSize: '14pt' }}>
                                        <div className="col-6 elips">
                                            {pilot.isPublic ? <><FontAwesomeIcon icon="user" />&nbsp;<a href={"/viewPilot/" + pilot.UserID} >{pilot.LastName} / {pilot.FirstName}</a></>

                                                : <><FontAwesomeIcon icon="user" />&nbsp;{pilot.LastName} / {pilot.FirstName}</>}
                                        </div>
                                        <div className="col-6 elips" style={{ textAlign: 'right' }}>
                                            {pilot.Flights.length}&nbsp;Flights
                                        </div>
                                    </div>
                                </div>);
                            })}
                        </div>
                    </div>
                </Slider>
            </div>
        </section>
        <section className="product1">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-md-4" style={{padding: '10px'} }>
                        <Slider {...settings2}>
                            <div>
                                <img src="/images/screenshots/g4-3.png" className="img-fluid" style={{maxHeight: '900px'}} />
                            </div>
                            <div>
                                <img src="/images/screenshots/g4-1.jpg" className="img-fluid" style={{ maxHeight: '900px' }} />
                            </div>
                            <div>
                                <img src="/images/screenshots/g4-2.png" className="img-fluid" style={{ maxHeight: '900px' }} />
                            </div>
                        </Slider>
                    </div>
                    
                    <div className="col-sm-6 col-md-8" style={{ padding: '10px' } }>
                        <div className="fontBanner2" style={{fontSize: '5em'} }>Features</div>
                    
                    <br/><br/>
                    <div className="container">
                            <div className="row">
                               
                                <div className="col-sm-12 col-md-4" style={{ textAlign: 'center', color: '#fff' }}>
                                    <FontAwesomeIcon icon="exclamation-triangle" style={{ fontSize: '24pt', color: '#fff' }} /><br />
                                    <span className="fontBanner1">Obstacle Avoidance</span>
                                    <hr />
                                    <p style={{ textAlign: 'justify' }}>
                                        Navigated with a piece of mind, knowing GA Flight Tracker will keep an eye out for any obstacles that may be around you within a 10nm radius, at or above your current altitude. Warnings displayed on the screen so you can see their position and be notifed of the closest danger.
                                        <br/><br/>Obstacle data is updated daily to ensure you have the latest data available for your flight.
                                    </p>
                                </div>

                                <div className="col-sm-12 col-md-4" style={{ textAlign: 'center', color: '#fff' }}>
                                    <FontAwesomeIcon icon="compass" style={{ fontSize: '24pt', color: '#fff' }} /><br />
                                    <span className="fontBanner1">Real-time Route Mapping</span>
                                    <hr />
                                    <p style={{ textAlign: 'justify' }}>
                                        Easily navigate with GPS level flight plan route mapping within the app to assist you and keep you on track during your flight.<br/><br/>
                                        With easy to see distance to go for leg, leg ETA, total distance to go and ETA to destination, you can quickly update your ETA's entroute based on your current ground speed.
                                    </p>
                                </div>

                                <div className="col-sm-12 col-md-4" style={{ textAlign: 'center', color: '#fff' }}>
                                    <FontAwesomeIcon icon="cloud-sun-rain" style={{ fontSize: '24pt', color: '#fff' }} /><br />
                                    <span className="fontBanner1">Precipitation Radar</span>
                                    <hr />
                                    <p style={{ textAlign: 'justify' }}>
                                        Overlay percipitation radar data to avoid areas of heavy rain and thunderstorm activity. The radar data is updated in 10 minute intervals to reduce data usage, and provide you with useful radar imagery.
                                    </p>
                                </div>

                                <div className="col-sm-12 col-md-4" style={{ textAlign: 'center', color: '#fff' }}>
                                    <FontAwesomeIcon icon="compass-drafting" style={{ fontSize: '24pt', color: '#fff' }} /><br />
                                    <span className="fontBanner1">Flight Planning</span>
                                    <hr />
                                    <p style={{ textAlign: 'justify' }}>
                                        Plan your flight interactively online and print out professional VFR/IFR navigation logs, weight and balance and fuel endurance calculations.
                                    </p>
                                </div>

                                <div className="col-sm-12 col-md-4" style={{ textAlign: 'center', color: '#fff' }}>
                                    <FontAwesomeIcon icon="book" style={{ fontSize: '24pt', color: '#fff' }} /><br />
                                    <span className="fontBanner1">Flight Logs</span>
                                    <hr />
                                    <p style={{ textAlign: 'justify' }}>
                                        Keep history of your flight logs automatically with duration of flight, departure and arrival airports, and any custom notes.
                                    </p>
                                </div>

                                <div className="col-sm-12 col-md-4" style={{ textAlign: 'center', color: '#fff' }}>
                                    <FontAwesomeIcon icon="satellite-dish" style={{ fontSize: '24pt', color: '#fff' }} /><br />
                                    <span className="fontBanner1">Track & Transmit</span>
                                    <hr />
                                    <p style={{ textAlign: 'justify' }}>
                                        Track airborn flights, and transmit your position in real time.
                                    </p>
                                </div>

                                <div className="col-sm-12 col-md-4" style={{ textAlign: 'center', color: '#fff' }}>
                                    <FontAwesomeIcon icon="draw-polygon" style={{ fontSize: '24pt', color: '#fff' }} /><br />
                                    <span className="fontBanner1">Flight Review & Sharing</span>
                                    <hr />
                                    <p style={{ textAlign: 'justify' }}>
                                        Store, review and share unlimited past flights, with on-board audio.
                                    </p>
                                </div>

                                <div className="col-sm-12 col-md-8" style={{ textAlign: 'center', color: '#fff' }}>
                                    <FontAwesomeIcon icon="cloud-download" style={{ fontSize: '24pt', color: '#fff' }} /><br />
                                    <span className='fontBanner1'>Download</span>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-6'>
                                            Download for iOS devices
                                            <a href="https://itunes.apple.com/us/app/ga-flight-tracker/id1319102682?ls=1&mt=8" target="_blank"><img className="img-fluid appBadge" src={appStoreImg} /></a>&nbsp;&nbsp;
                                        </div>
                                        <div className='col-6'>
                                            Download for Android devices
                                            <a href="https://play.google.com/store/apps/details?id=ca.oxigen.apps.gaflighttracker" target="_blank"><img className="img-fluid appBadge" src={googleStoreImg} /></a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="testimolials">
            <div className="container">
                <Slider {...settings3}>
                    <div className="container">
                        <div className="row" >
                            <div className="col-sm-12 col-md-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img className="img-fluid testimonialsImg" width="100%" src="/images/testimonials/t1.webp" style={{ margin: '10px' }} />
                            </div>
                            <div className="col-sm-12 col-md-8 row" >
                                <div className="col-md-12 quote" style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                    "App works perfectly fine for me. Nice back up if I need it."<br />
                                </div>
                                <div className="col-md-12" style={{ textAlign: 'right' }} >
                                    {/*<div style={{display: 'relative', marginRight: '0px'} }>*/}
                                    {/*    <img src="/images/testimonials/5stars.png" className="img-fluid d-none d-md-block" width="100" /><br />*/}
                                    {/*</div>*/}
                                    <div>
                                        - Daniel S.  | Feb. 4, 2023
                                    </div>
                                </div>
                            </div>
                            
                        </div> 
                    </div>

                    <div className="container">
                        <div className="row" >
                            <div className="col-sm-12 col-md-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img className="img-fluid testimonialsImg" width="100%" src="/images/testimonials/t2.webp" style={{ margin: '10px' }} />
                            </div>
                            <div className="col-sm-12 col-md-8 row" >
                                <div className="col-md-12 quote" style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                    "I like it holly"<br />
                                </div>
                                <div className="col-md-12" style={{ textAlign: 'right' }}>
                                    {/*<img src="/images/testimonials/5stars.png" className="img-fluid d-none d-md-block" width="100" /><br/>*/}
                                    - Holly H.  | Jun. 5, 2023
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="container">
                        <div className="row" >
                            <div className="col-sm-12 col-md-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img className="img-fluid testimonialsImg" width="100%" src="/images/testimonials/t3.jpg" style={{margin: '10px'}} />
                            </div>
                            <div className="col-sm-12 col-md-8 row" >
                                <div className="col-md-12 quote" style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                    "One of the best flight tracking apps! I recommend it to new pilots. Also very helpful to know where is the place of your plane, altitude, and speed, could also see the details of any plane that's near or far from you!"<br />
                                </div>
                                <div className="col-md-12" style={{textAlign: 'right'}}>
                                    {/*<img src="/images/testimonials/5stars.png" className="img-fluid d-none d-md-block" width="100" /><br/>*/}
                                    - Mohammad A.  | Feb. 15, 2023
                                </div>
                            </div>

                        </div>
                    </div>
                </Slider>
            </div>
        </section>
            </>
        );
    }
}

export { Home }