import React, { Component } from 'react';
import { Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { APIFunctions } from '../../modules/APIFunctions';


class ForgotPassword extends Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            isReset: false,
            refreshReCaptcha: null,
        }
    }

    reCaptchaTest = (token:any) => {
        console.log("reCaptcha Verified.");
    }

    componentDidMount() {
        this.setState({ refreshReCaptcha: (r: any) => !r });
    }

    validateForm = async () => {
        var pattern = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; 
        if (this.state.val_email === "" || !pattern.test(this.state.val_email)) {
            this.setState({ valid_email: false });
            
        } else {
            this.setState({ valid_email: null });
            var a = new APIFunctions();
            await a.forgotPassword({ EmailAddress: this.state.val_email });
            this.setState({ isReset: true });
        }
       
    }

    render() {
        return (
            !this.state.isReset ?
                <div className="col-12 registerDiv container">
                    <div className="row divCenter col-12">
                        <div className="col-md-6 col-sm-12 divCenter" style={{ marginBottom: '20px' }}><h2>Recover your password</h2></div>
                    </div>
                    <div className="row divCenter" style={{ marginBottom: '20px' }}>
                        <div className="col-md-6 col-sm-12">
                            Enter your email below and we will send you a reset link.  Unfortunately, we cannot recover your existing password and a new one will need to be created.  <br/><br/>If you used your Google or Facebook account to log in, we cannot reset your password.
                        </div>
                    </div>
                    <div className="row divCenter" style={{ marginBottom: '20px' }}>
                        <div className="col-md-6 col-sm-12">
                            <FormGroup floating>
                                <Input onChange={(e) => { this.setState({ val_email: e.target.value }); }} id="email" placeholder="Email Address" type="email" valid={this.state.valid_email === true} invalid={this.state.valid_email === false} />
                                <Label for="email">Email Address <span style={{ color: '#FF0000' }}>*</span></Label>
                                <FormFeedback invalid>
                                    Email address is required and needs to be a valid email.
                                </FormFeedback>
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row divCenter" style={{ marginBottom: '20px' }}>
                        <div className="col-md-6 col-sm-12">
                            <Button color="primary" className="col-12" onClick={this.validateForm}>Reset password</Button>
                        </div>
                    </div>
                    <GoogleReCaptchaProvider reCaptchaKey="6LfTl9wlAAAAACJy4nN2eCBV84inU41hkT3TiYUc">
                        <GoogleReCaptcha onVerify={this.reCaptchaTest}
                            refreshReCaptcha={this.state.refreshReCaptcha} />
                    </GoogleReCaptchaProvider>
                </div>
                : 
                <div className="col-12 registerDiv container">
                    <div className="row divCenter col-12">
                        <div className="col-md-6 col-sm-12 divCenter" style={{ marginBottom: '20px' }}><h2>Recover your password</h2></div>
                    </div>
                    <div className="row divCenter" style={{ marginBottom: '20px' }}>
                        <div className="col-md-6 col-sm-12">
                            We have sent you an email from <span style={{ fontWeight: 700 }}>noreply@gaflighttracker.com</span>.  If you haven't received an email from us within 5 minutes, please check your junk or spam folders. 
                        </div>
                    </div>
                   
                </div>

        );
    }


}

export { ForgotPassword }