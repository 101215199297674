import React, { Component, } from 'react';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faRectangleXmark } from '@fortawesome/free-solid-svg-icons'

interface IProps {
    ErrorMessage: String;
    ErrorTitle: String;
    onShow: VoidFunction;
    onClose: VoidFunction;
    isShown: Boolean
}

interface IState {
    showErrorPopup: Boolean; 
}

class ErrorPopup extends Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
        this.state = { showErrorPopup: this.props.isShown }
        if (props.onShow !== null) {
            props.onShow();
        }
    }

    componentDidMount() {
        document.addEventListener("showErrorPopup", this.showPopup);
    }

    componentWillUnmount() {
        document.removeEventListener("showErrorPopup", this.showPopup);
    }

    closePopup = () => {
       
        this.setState({ showErrorPopup: false });
        this.props.onClose();
    }

    showPopup = () => {
        console.log("Opening error popup");
        this.setState({ showErrorPopup: true });
    }

    render() {

        return (
            this.state.showErrorPopup ? 
                <>
                    <div className="blackout"></div>
                    <div className="loginPopupDiv">
                        <div className="errorPopup">
                            <div className="closeButton" onClick={this.closePopup}>
                                <FontAwesomeIcon icon="rectangle-xmark" />
                            </div>
                            <div className="container">
                                <div className="row" style={{ marginBottom: '20px' }}>
                                    <div className="col-12" style={{ textAlign: 'center' }} >
                                        <h2>{this.props.ErrorTitle}</h2>
                                    </div>
                                    <div className="col-12" style={{ marginBottom: '20px', marginTop: '20px', textAlign: 'center' }}>
                                        {this.props.ErrorMessage }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <></>
        );
    }

}

export { ErrorPopup }