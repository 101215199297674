import React, { Component, ReactElement } from 'react';
import { Badge, Button, FormFeedback, FormGroup, Input, Label, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { APIFunctions } from '../../modules/APIFunctions';
import { withRouter } from '../../modules/withRouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye, faThumbsUp, faFileAudio, faVideo, faMicrophone, faEnvelope, faEnvelopeOpen, faMailReply, faTrash } from '@fortawesome/free-solid-svg-icons'
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


library.add(faEye, faThumbsUp, faFileAudio, faVideo, faMicrophone, faEnvelope, faEnvelopeOpen, faMailReply, faTrash);

class MyAccount extends React.Component<any, any>{

    val_fname:string = "";
    val_lname:string = "";
    val_email: string = "";

    val_addVideoURL: string = "";
    val_addVideoTitle: string = "";
    val_addVideoDescription: string = "";

    constructor(props:any) {
        super(props);
        var u = sessionStorage.getItem("u");
        var user: any = null;
        if (u != null) {
            user = JSON.parse(u);

        } else {
            window.location.href = "/";
        }
        this.state = { user: user, activeTab: '1', videos: null, };
        document.addEventListener("LoggedOut", this.loggedOut);
    }

    componentDidMount = () => {

        this.messageCountUpdate();
        this.getPilotProfile();

    }

    loggedOut = () => {
        window.location.href = "/";
    }

    groupArray = (array: [], prop: string) => {
        let zzz: any = [];
        //console.log(array);
        array.map((f: any) => {
            var x1 = zzz.findIndex((xx: any) => xx.CallSign == f.CallSign);
            if (x1 > -1) {
                zzz[x1].Count++;
                zzz[x1].FlightTime += f.flightDuration;
            } else {
                zzz.push({ CallSign: f.CallSign, Count: 1, FlightTime: f.flightDuration });
            }
        });
        zzz.sort((a:any, b:any) => {
            if (a.CallSign < b.CallSign) {
                return -1;
            }
            if (a.CallSign > b.CallSign) {
                return 1;
            }

            // names must be equal
            return 0;
        })
        return zzz;
    }

    getPilotProfile = async () => {
        var api = new APIFunctions();
        api.GetProfile(this.state.user.UserID).then((p) => {
            //console.log(p);
            this.setState({ profile: p, aircraftLog: this.groupArray(p.profile.Flights, "CallSign") });
            this.val_fname = this.state.user.FirstName;
            this.val_lname = this.state.user.LastName;
            this.val_email = this.state.user.EmailAddress;
            
        });
        api.getMessages(this.state.user.UserID).then((p) => { /*console.log("**** MESSAGES:",p);*/  this.setState({ messages: p }) });
        api.getVideos(this.state.user.UserID).then((p) => this.setState({ videos: p }));
    }

    messageCountUpdate = () => {
        var msgs: string = sessionStorage.getItem("messages") || "0";

        this.setState({ NewMessages: parseInt(msgs) });
        //console.log("MESSAGES:", msgs);

    }

    render = () => {
        //console.log(this.state.user, this.state.profile, this.state.videos);
        var totalLoggedTime = 0;
        if (this.state.profile && this.state.profile.profile.Flights) {
            this.state.profile.profile.Flights.map((f: any) => {
                totalLoggedTime += f.flightDuration;
            })
        }
        return (<>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <img src={this.state.user && this.state.user.ProfilePicture && this.state.user.ProfilePicture.length > 200 ? "data:image/png;base64," + this.state.user.ProfilePicture : "/images/unknownuser.png"} style={{ maxWidth: '350px', maxHeight: '350px', width: '350px', height: '350px', border: '7px solid #000000', borderRadius: '50%' }} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="row">
                            <div className="col-12" style={{marginTop: 10} }>
                                <span className="fontLogo2" style={{ color: '#333333', fontSize: '32pt' }}>PERSONAL DETAILS</span><br /><br/>
                                <FormGroup floating>
                                    <Input onChange={(e) => { this.val_fname = e.target.value; }} id="fname" placeholder="First Name" defaultValue={this.state.user.FirstName} type="text" valid={this.state.valid_fname === true } invalid={this.state.valid_fname === false} />
                                    <Label for="fname">First Name <span style={{ color: '#FF0000' }}>*</span></Label>
                                    <FormFeedback invalid="true">
                                        First name is required.
                                    </FormFeedback>
                                </FormGroup>

                                <FormGroup floating>
                                    <Input onChange={(e) => { this.val_lname = e.target.value }} id="lname" placeholder="Last Name" defaultValue={this.state.user.LastName} type="text" valid={this.state.valid_lname === true} invalid={this.state.valid_lname === false} />
                                    <Label for="lname">Last Name <span style={{ color: '#FF0000' }}>*</span></Label>
                                    <FormFeedback invalid="true">
                                        Last name is required.
                                    </FormFeedback>
                                </FormGroup>

                                <FormGroup floating>
                                    <Input onChange={(e) => { this.val_email = e.target.value }} id="email" placeholder="Email Address" defaultValue={this.state.user.EmailAddress} type="email" valid={this.state.valid_email === true} invalid={this.state.valid_email === false} />
                                    <Label for="email">Email Address <span style={{ color: '#FF0000' }}>*</span></Label>
                                    <FormFeedback invalid="true">
                                        Email address is required and needs to be a valid email.
                                    </FormFeedback>
                                </FormGroup>

                                <FormGroup switch>
                                    <Input onClick={() => {
                                        var val = this.state.user;
                                        val.isPublic = !val.isPublic;
                                        this.setState({ val_ispublic: val.isPublic, user: val });
                                    }} id="publicprofile" placeholder="Public Profile" defaultChecked={this.state.user.isPublic} type="switch" />
                                    <Label for="publicprofile">Public Profile</Label>
                                    
                                </FormGroup>

                                <hr />
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Button className="btn btn-success">SAVE CHANGES</Button>
                                    <Button className="btn btn-danger">REMOVE ACCOUNT</Button>
                                </div>
                                

                                
                            </div>
                        </div>
                    </div>
                </div>
                <br/><br/>
                <div>
                    <Nav tabs justified>
                        <NavItem active>
                            <NavLink style={{cursor: 'pointer'}} className={this.state.activeTab == '1' ? 'active' : '' } onClick={() => { this.setState({ activeTab: '1' }) } }>
                                Flight Logs
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{ cursor: 'pointer' }} className={this.state.activeTab == '2' ? 'active' : ''} onClick={() => { this.setState({ activeTab: '2' }) }}>
                                Aircraft Logs
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{ cursor: 'pointer' }} className={this.state.activeTab == '3' ? 'active' : ''} onClick={() => { this.setState({ activeTab: '3' }) }}>
                                YouTube Videos
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{ cursor: 'pointer' }} className={this.state.activeTab == '4' ? 'active' : ''} onClick={() => { this.setState({ activeTab: '4' }) }}>
                                Messages/Inbox {this.state.NewMessages > 0 ? <><Badge color="danger" pill>
                                    {this.state.NewMessages}
                                </Badge></> : <></> }
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink style={{ cursor: 'pointer' }} className={this.state.activeTab == '5' ? 'active' : ''} onClick={() => { this.setState({ activeTab: '5' }) }}>
                                Followers
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab} >
                        <TabPane tabId="1">
                            <br />
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <div className="row">
                                            <div className="col-6">
                                                <h4>
                                                    Flight Logs
                                                </h4>
                                            </div>
                                            <div className="col-6">
                                                <span style={{ color: '#333333', fontSize: '32px', fontFamily: 'Barlow' }}>{(totalLoggedTime / 3600).toFixed(0)}H {((totalLoggedTime % 3600) / 60).toFixed(0)}M</span><br />
                                                <span style={{ color: '#333333', fontSize: '10px', top: '-10px', position: 'relative' }}>Total Flight Time Logged</span>
                                            </div>
                                        </div>
                                        {this.state.profile && this.state.profile.profile ?
                                            <>
                                                
                                                {this.state.profile.profile.Flights.map((f: any) => {

                                                    return (<div className="col-12 registerDiv" key={f.FlightID} style={{borderTop: '1px solid rgba(0,0,0,.3)', marginTop: '5px', marginBottom: '5px'} }>
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <img src={'https://api.gaflighttracker.com/Functions/PlaneImage?img=' + f.CallSign } width="100%" />
                                                            </div>
                                                            <div className="col-8 row">

                                                                <div className="col-6">
                                                                    <b><span style={{ color: '#333333', fontSize: '20px', fontFamily: 'Barlow' }}>{f.CallSign}</span></b><br />
                                                                    <span style={{ color: '#333333', fontSize: '10px', top: '-10px', position: 'relative' }}>Call Sign</span>
                                                                </div>
                                                           
                                                                <div className="col-6">
                                                                    <b><span style={{ color: '#333333', fontSize: '20px', fontFamily: 'Barlow' }}>{new Date(parseInt(f.FlightDate.match(/\d+/)[0])).toLocaleString("en", { day: "2-digit" })}&nbsp;
                                                                        {new Date(parseInt(f.FlightDate.match(/\d+/)[0])).toLocaleString("en", { month: "short" }).toUpperCase()}&nbsp;
                                                                        {new Date(parseInt(f.FlightDate.match(/\d+/)[0])).toLocaleString("en", { year: "numeric" })}</span></b><br />
                                                                    <span style={{ color: '#333333', fontSize: '10px', top: '-10px', position: 'relative' }}>Flight Date</span>
                                                                </div>

                                                                <div className="col-6">
                                                                    <b><span style={{ color: '#333333', fontSize: '20px', fontFamily: 'Barlow' }}>{f.Departure}</span></b><br />
                                                                    <span style={{ color: '#333333', fontSize: '10px', top: '-10px', position: 'relative' }}>Depart</span>
                                                                </div>
                                                                <div className="col-6">
                                                                    <b><span style={{ color: '#333333', fontSize: '20px', fontFamily: 'Barlow' }}>{f.Arrival}</span></b><br />
                                                                    <span style={{ color: '#333333', fontSize: '10px', top: '-10px', position: 'relative' }}>Arrive</span>
                                                                </div>

                                                                <div className="col-12">
                                                                    <span style={{ color: '#333333', fontSize: '10px', position: 'relative' }}>Flight Notes</span><br/>
                                                                    <b><span style={{ color: '#333333', fontSize: '16px', fontFamily: 'Barlow' }}>{f.flightNotes}</span></b>
                                                                </div>
                                                            </div>

                                                            <hr/>
                                                            <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Button className="btn btn-danger">DELETE</Button>
                                                                {f.isShared ? <><Button className="btn btn-warning">UNSHARE</Button></> : <><Button className="btn btn-warning">SHARE</Button></>}
                                                                <Button className="btn btn-success">REVIEW</Button>
                                                            </div>
                                                        </div>
                                                    </div>)
                                                })} 
                                            </> : <></>}
                                            
                                            
                                    </div>

                                    <div className="col-md-6 col-sm-12">
                                       
                                    </div>
                                    
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <br />
                            <div className="container">
                                <h4>
                                    Aircraft Log
                                </h4>
                                {this.state.aircraftLog ? <>
                                    {this.state.aircraftLog.map((fl: any) => {
                                        return (<div key={fl.CallSign} className="registerDiv">
                                            <div className="row" style={{ position: 'relative' }}>
                                                <div className="col-md-2 col-sm-6">
                                                    <img src={'https://api.gaflighttracker.com/Functions/PlaneImage?img=' + fl.CallSign} width="100%" />
                                                </div>
                                                <div className="col-md-10 col-sm-6">
                                                   
                                                        <div className="col-12 aircraftLogCallSign">{fl.CallSign}</div>
                                                    <div className="col-12">Flights: {fl.Count}</div>
                                                    <div className="col-12">Total Hours: {(fl.FlightTime / 3600).toFixed(0)}H {((fl.FlightTime % 3600) / 60).toFixed(0)}M</div>
                                                </div>
                                            </div>
                                        </div>);

                                    })}
                                </> : <></>}
                            </div>
                        </TabPane>
                        <TabPane tabId="3">
                            <div className="container">
                                <br />
                                <h4>
                                    YouTube Videos
                                </h4>
                                <Button onClick={(e:any) => { this.setState({ showAddVideo: true }) }} className="btn btn-success" style={{
                                    marginTop: 10, marginBottom: 10
                                }}>+ ADD VIDEO</Button>
                                {(this.state.showAddVideo) ? <>
                                    <div className="row" style={{padding: 10} }>
                                        <div className="col-12">
                                            <FormGroup floating>
                                                <Input onChange={(e) => { this.val_addVideoURL = e.target.value }} id="addVideoURL" valid={true} placeholder="Video URL"  type="text" />
                                                <Label for="lname">Video URL <span style={{ color: '#FF0000' }}>*</span></Label>
                                                
                                            </FormGroup>
                                        </div>
                                        <div className="col-12">
                                            <FormGroup floating>
                                                <Input onChange={(e) => { this.val_addVideoTitle = e.target.value }} id="addVideoTitle" valid={true}  placeholder="Video Title" type="text" />
                                                <Label for="lname">Video Title <span style={{ color: '#FF0000' }}>*</span></Label>
                                                
                                            </FormGroup>
                                        </div>
                                        <div className="col-12">
                                            <FormGroup floating>
                                                <Input onChange={(e) => { this.val_addVideoDescription = e.target.value }} id="addVideoDescription" valid={true} placeholder="Video Description" type="textarea" />
                                                <Label for="lname">Video Description <span style={{ color: '#FF0000' }}>*</span></Label>
                                                
                                            </FormGroup>
                                        </div>
                                        <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Button onClick={(e) => { this.setState({showAddVideo: false}) }} className="btn btn-danger">CANCEL</Button>
                                            <Button className="btn btn-primary">SAVE</Button>
                                        </div>
                                    </div>
                                </> : <></>}
                                {(this.state.videos && this.state.videos.length > 0) ? <>
                                    {this.state.videos.map((v: any) => {
                                        return (
                                            <div className="col-12 row" key={v.videoID}>
                                                <iframe width="100%" height="315" src={"https://www.youtube.com/embed/" + v.videoURL} title={v.videoName} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                                <div className="col-8" style={{padding: 10} }>
                                                {v.videoName}<br />
                                                    {v.videoDescription}
                                                </div>
                                                <div className="col-4" style={{ textAlign: 'right', padding: 10 }}>
                                                    <Button className="btn btn-danger">DELETE</Button>
                                                </div>
                                                <hr />
                                            </div>
                                        )
                                    })}

                                </> : <p>You have not posted any videos.</p>}
                            </div>

                        </TabPane>
                        <TabPane tabId="4">
                            <div className="container">
                                <br />
                                <h4>
                                    Inbox
                                </h4>
                                <div className="row">
                                    <div className="col-6 col-md-3">
                                        From
                                    </div>
                                    <div className="col-6 col-md-3">
                                        Date
                                    </div>
                                   <hr />
                                </div>
                                {/*<div className="row">*/}
                                {/*    <div className="col-12">*/}
                                {/*        <span style={{ fontWeight: 'bold' }}>Unread Messages</span>*/}
                                {/*    </div>*/}
                                    {this.state.messages && this.state.messages.Unread ? <>
                                        {this.state.messages.Unread.map((msg:any) => {
                                            return (
                                                <div onClick={(e) => {
                                                    this.setState({ viewMessage: true, msgID: msg.msgID, message: msg });
                                                }} key={msg.msgID} className="row emailRow">
                                                    <div className="col-md-3 col-6" style={{
                                                        fontSize: '14pt'
                                                    }}>
                                                        <FontAwesomeIcon icon="envelope"></FontAwesomeIcon>&nbsp; <b>{msg.fromFName}</b>
                                                    </div>
                                                    <div className="col-md-3 col-6" style={{ fontWeight: 'bold', fontSize: '10pt' }}>
                                                        {new Date(parseInt(msg.msgDate.match(/\d+/)[0])).toLocaleString("en", { day: "2-digit" })}&nbsp;
                                                        {new Date(parseInt(msg.msgDate.match(/\d+/)[0])).toLocaleString("en", { month: "short" }).toUpperCase()}&nbsp;
                                                        {new Date(parseInt(msg.msgDate.match(/\d+/)[0])).toLocaleString("en", { year: "numeric" })}
                                                        &nbsp;&nbsp;&nbsp;
                                                        {new Date(parseInt(msg.msgDate.match(/\d+/)[0])).toLocaleString("en", { hour12: false, hour: '2-digit', minute: "2-digit", second: "2-digit" })}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </> : <></>}
                                {/*</div>*/}
                                {this.state.messages && this.state.messages.Read ? <>
                                    {this.state.messages.Read.map((msg: any) => {
                                        return (
                                            <div onClick={(e) => {
                                                this.setState({ viewMessage: true, msgID: msg.msgID, message: msg });
                                            }} key={msg.msgID} className="row emailRow">
                                                <div className="col-md-3 col-6" style={{
                                                    fontSize: '14pt'
                                                }}>
                                                    {msg.isReplied ? <FontAwesomeIcon icon="mail-reply"></FontAwesomeIcon> : <FontAwesomeIcon icon="envelope-open"></FontAwesomeIcon>}&nbsp; <b>{msg.fromFName}</b>
                                                </div>
                                                <div className="col-md-3 col-6" style={{ fontWeight: 'bold', fontSize: '10pt' }}>
                                                    {new Date(parseInt(msg.msgDate.match(/\d+/)[0])).toLocaleString("en", { day: "2-digit" })}&nbsp;
                                                    {new Date(parseInt(msg.msgDate.match(/\d+/)[0])).toLocaleString("en", { month: "short" }).toUpperCase()}&nbsp;
                                                    {new Date(parseInt(msg.msgDate.match(/\d+/)[0])).toLocaleString("en", { year: "numeric" })}
                                                    &nbsp;&nbsp;&nbsp;
                                                    {new Date(parseInt(msg.msgDate.match(/\d+/)[0])).toLocaleString("en", { hour12: false, hour: '2-digit', minute: "2-digit", second: "2-digit" })}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </> : <></>}
                            <br/><br/><br/>
                            </div>
                            
                        </TabPane>

                        <TabPane tabId="5">
                            <div className="container">
                                <br />
                                <h4>
                                    Followers &amp; Blocked Users
                                </h4>
                            </div>

                        </TabPane>
                    </TabContent>
                </div>
            </div>
            {this.state.viewMessage ? <>
                <div className="blackout"></div>
                <div className="loginPopupDiv">
                    <div className="loginPopup" style={{ alignItems: 'normal', backgroundColor: '#ffffff' }}>
                        <div className="closeButton" onClick={(e) => { this.setState({ viewMessage: false, msgID: null, message: null }) }}>
                            <FontAwesomeIcon icon="rectangle-xmark" />
                        </div>
                        <div className="container" style={{height: '100%'} }>
                            <div className="row">
                                <div className="col-3">FROM:</div>
                                <div className="col-9"><b><a href={"/ViewPilot/" + this.state.message.FromUser }>{this.state.message.fromFName}&nbsp;{this.state.message.fromLName}</a></b></div>
                                <div className="col-3">TO:</div>
                                <div className="col-9"><b>{this.state.user.FirstName}&nbsp;{this.state.user.LastName}</b></div>
                                <div className="col-3">DATE:</div>
                                <div className="col-9"><b>{new Date(parseInt(this.state.message.msgDate.match(/\d+/)[0])).toLocaleString("en", { day: "2-digit" })}&nbsp;
                                    {new Date(parseInt(this.state.message.msgDate.match(/\d+/)[0])).toLocaleString("en", { month: "short" }).toUpperCase()}&nbsp;
                                    {new Date(parseInt(this.state.message.msgDate.match(/\d+/)[0])).toLocaleString("en", { year: "numeric" })}
                                    &nbsp;&nbsp;&nbsp;
                                    {new Date(parseInt(this.state.message.msgDate.match(/\d+/)[0])).toLocaleString("en", { hour12: false, hour: '2-digit', minute: "2-digit", second: "2-digit" })}</b></div>
                                {this.state.message.isReplied ? <>
                                    <div className="col-12" style={{ backgroundColor: 'rgba(224, 164, 0,1)', fontSize: '9pt', padding: 10, marginTop: 10 }}>You replied to this message on {new Date(parseInt(this.state.message.ReplyDate.match(/\d+/)[0])).toLocaleString("en", { day: "2-digit" })}&nbsp;
                                        {new Date(parseInt(this.state.message.ReplyDate.match(/\d+/)[0])).toLocaleString("en", { month: "short" }).toUpperCase()}&nbsp;
                                        {new Date(parseInt(this.state.message.ReplyDate.match(/\d+/)[0])).toLocaleString("en", { year: "numeric" })}
                                        &nbsp;&nbsp;&nbsp;
                                        {new Date(parseInt(this.state.message.ReplyDate.match(/\d+/)[0])).toLocaleString("en", { hour12: false, hour: '2-digit', minute: "2-digit", second: "2-digit" })} </div>
                                </> : <></>}
                            </div>
                            <hr />
                            <div className="container" style={{
                                height: this.state.message.isReplied ? '300px': '350px', overflowY: 'auto', whiteSpace: 'pre-wrap', marginBottom: 15
                            }}>
                                
                                    {this.state.message.Msg}
                                
                            </div>
                            <hr/>
                            <div className="container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button className="btn btn-danger"><FontAwesomeIcon icon="trash"></FontAwesomeIcon>&nbsp;&nbsp;&nbsp;DELETE</Button>
                                <Button className="btn btn-warning"><FontAwesomeIcon icon="mail-reply"></FontAwesomeIcon>&nbsp;&nbsp;&nbsp;REPLY</Button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </> : <></> }
        </>);
    }
}

export default withRouter(MyAccount)