
class gaftAPI {

    baseUrl = "/Content/images/aircraft/t-sprite_c-";
    sprite = "_w-";
    sprite2 = "_s-yes.png";



    scale = window.devicePixelRatio ? window.devicePixelRatio : 1;

    constructor() {
        this.scale = Math.round(this.scale);
        this.scale = Math.max(1, this.scale);
        this.scale = Math.min(3, this.scale);
    }

    gaftAPI = {
        baseUrl: "/Content/images/aircraft/t-sprite_c-",
        sprite: "_w-",
        sprite2: "_s-yes.png",
        plane80: {
            "rotationDegrees": 15,
            "url": "1615461067/t-sprite_c-blue_w-80_s-yes.png",
            "w": 2672,
            "h": 3690,
            "icons": {
                "FGTR": {
                    "rotates": true,
                    "aliases": [
                        "A4",
                        "EUFI",
                        "F16",
                        "F18",
                        "F18C",
                        "F18D",
                        "FGR4",
                        "HAWK",
                        "HUNT",
                        "SB05",
                        "SB39",
                        "TOR",
                        "L39",
                        "E530",
                        "M346",
                        "AJET",
                        "AMX",
                        "T38",
                        "U2",
                        "M339",
                        "CORS",
                        "P51",
                        "F15",
                        "L159",
                        "SB32"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 0,
                                "w": 56,
                                "h": 56
                            },
                            "15": {
                                "x": 56,
                                "y": 0,
                                "w": 55,
                                "h": 55
                            },
                            "30": {
                                "x": 111,
                                "y": 0,
                                "w": 57,
                                "h": 57
                            },
                            "45": {
                                "x": 168,
                                "y": 0,
                                "w": 61,
                                "h": 61
                            },
                            "60": {
                                "x": 229,
                                "y": 0,
                                "w": 63,
                                "h": 63
                            },
                            "75": {
                                "x": 292,
                                "y": 0,
                                "w": 64,
                                "h": 64
                            },
                            "90": {
                                "x": 356,
                                "y": 0,
                                "w": 64,
                                "h": 64
                            },
                            "105": {
                                "x": 420,
                                "y": 0,
                                "w": 64,
                                "h": 64
                            },
                            "120": {
                                "x": 484,
                                "y": 0,
                                "w": 63,
                                "h": 63
                            },
                            "135": {
                                "x": 547,
                                "y": 0,
                                "w": 61,
                                "h": 61
                            },
                            "150": {
                                "x": 608,
                                "y": 0,
                                "w": 57,
                                "h": 57
                            },
                            "165": {
                                "x": 665,
                                "y": 0,
                                "w": 55,
                                "h": 55
                            },
                            "180": {
                                "x": 720,
                                "y": 0,
                                "w": 56,
                                "h": 56
                            },
                            "195": {
                                "x": 776,
                                "y": 0,
                                "w": 55,
                                "h": 55
                            },
                            "210": {
                                "x": 831,
                                "y": 0,
                                "w": 57,
                                "h": 57
                            },
                            "225": {
                                "x": 888,
                                "y": 0,
                                "w": 61,
                                "h": 61
                            },
                            "240": {
                                "x": 949,
                                "y": 0,
                                "w": 63,
                                "h": 63
                            },
                            "255": {
                                "x": 1012,
                                "y": 0,
                                "w": 64,
                                "h": 64
                            },
                            "270": {
                                "x": 1076,
                                "y": 0,
                                "w": 64,
                                "h": 64
                            },
                            "285": {
                                "x": 1140,
                                "y": 0,
                                "w": 64,
                                "h": 64
                            },
                            "300": {
                                "x": 1204,
                                "y": 0,
                                "w": 63,
                                "h": 63
                            },
                            "315": {
                                "x": 1267,
                                "y": 0,
                                "w": 61,
                                "h": 61
                            },
                            "330": {
                                "x": 1328,
                                "y": 0,
                                "w": 57,
                                "h": 57
                            },
                            "345": {
                                "x": 1385,
                                "y": 0,
                                "w": 55,
                                "h": 55
                            }
                        }
                    ]
                },
                "AS20": {
                    "rotates": true,
                    "aliases": [
                        "GLID",
                        "G109",
                        "SF25",
                        "ULAC",
                        "VENT",
                        "DG80",
                        "SONX",
                        "DUOD",
                        "AS29",
                        "ARCP",
                        "NIMB",
                        "PISI",
                        "AS26",
                        "S10S",
                        "AS31",
                        "DG40",
                        "AS25",
                        "DG1T",
                        "LAE1",
                        "AS28",
                        "RF4",
                        "AS21",
                        "LS8"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 123,
                                "w": 64,
                                "h": 64
                            },
                            "15": {
                                "x": 64,
                                "y": 123,
                                "w": 64,
                                "h": 64
                            },
                            "30": {
                                "x": 128,
                                "y": 123,
                                "w": 61,
                                "h": 61
                            },
                            "45": {
                                "x": 189,
                                "y": 123,
                                "w": 53,
                                "h": 53
                            },
                            "60": {
                                "x": 242,
                                "y": 123,
                                "w": 43,
                                "h": 43
                            },
                            "75": {
                                "x": 285,
                                "y": 123,
                                "w": 35,
                                "h": 35
                            },
                            "90": {
                                "x": 320,
                                "y": 123,
                                "w": 34,
                                "h": 34
                            },
                            "105": {
                                "x": 354,
                                "y": 123,
                                "w": 36,
                                "h": 36
                            },
                            "120": {
                                "x": 390,
                                "y": 123,
                                "w": 43,
                                "h": 43
                            },
                            "135": {
                                "x": 433,
                                "y": 123,
                                "w": 53,
                                "h": 53
                            },
                            "150": {
                                "x": 486,
                                "y": 123,
                                "w": 61,
                                "h": 61
                            },
                            "165": {
                                "x": 547,
                                "y": 123,
                                "w": 64,
                                "h": 64
                            },
                            "180": {
                                "x": 611,
                                "y": 123,
                                "w": 64,
                                "h": 64
                            },
                            "195": {
                                "x": 675,
                                "y": 123,
                                "w": 64,
                                "h": 64
                            },
                            "210": {
                                "x": 739,
                                "y": 123,
                                "w": 61,
                                "h": 61
                            },
                            "225": {
                                "x": 800,
                                "y": 123,
                                "w": 53,
                                "h": 53
                            },
                            "240": {
                                "x": 853,
                                "y": 123,
                                "w": 43,
                                "h": 43
                            },
                            "255": {
                                "x": 896,
                                "y": 123,
                                "w": 35,
                                "h": 35
                            },
                            "270": {
                                "x": 931,
                                "y": 123,
                                "w": 34,
                                "h": 34
                            },
                            "285": {
                                "x": 965,
                                "y": 123,
                                "w": 36,
                                "h": 36
                            },
                            "300": {
                                "x": 1001,
                                "y": 123,
                                "w": 43,
                                "h": 43
                            },
                            "315": {
                                "x": 1044,
                                "y": 123,
                                "w": 53,
                                "h": 53
                            },
                            "330": {
                                "x": 1097,
                                "y": 123,
                                "w": 61,
                                "h": 61
                            },
                            "345": {
                                "x": 1158,
                                "y": 123,
                                "w": 64,
                                "h": 64
                            }
                        }
                    ]
                },
                "C206": {
                    "rotates": true,
                    "aliases": [
                        "A210",
                        "AA5",
                        "AA5B",
                        "AC11",
                        "AP22",
                        "B36T",
                        "BE19",
                        "BE23",
                        "BE24",
                        "BE33",
                        "BE35",
                        "BE36",
                        "BL8",
                        "BL17",
                        "BREZ",
                        "C10T",
                        "C42",
                        "C72R",
                        "C77R",
                        "C82R",
                        "C82S",
                        "C150",
                        "C152",
                        "C160",
                        "C162",
                        "C170",
                        "C172",
                        "C175",
                        "C177",
                        "C180",
                        "C182",
                        "C185",
                        "C195",
                        "C205",
                        "C207",
                        "C208",
                        "C210",
                        "C240",
                        "C337",
                        "CH7A",
                        "CH60",
                        "CO6T",
                        "COL3",
                        "COL4",
                        "CRUZ",
                        "DA40",
                        "DR30",
                        "DR40",
                        "DV20",
                        "EFOX",
                        "EPIC",
                        "EVOL",
                        "EVOP",
                        "EVOT",
                        "EV97",
                        "EXPR",
                        "FBA2",
                        "FDCT",
                        "FOX",
                        "F8L",
                        "GA8",
                        "GLAS",
                        "GP4",
                        "G58",
                        "G115",
                        "HROC",
                        "HR10",
                        "HUSK",
                        "JAB2",
                        "JAB4",
                        "KODI",
                        "LA25",
                        "LNC2",
                        "LNC4",
                        "LNCE",
                        "M20P",
                        "M20T",
                        "MO20",
                        "NAVI",
                        "PA14",
                        "PA18",
                        "PA20",
                        "PA22",
                        "PA24",
                        "PA25",
                        "PA28",
                        "PA32",
                        "PA38",
                        "PA46",
                        "PC6T",
                        "PC12",
                        "PC21",
                        "PTS2",
                        "PUP",
                        "P28",
                        "P28A",
                        "P28B",
                        "P28R",
                        "P28T",
                        "P28U",
                        "P32R",
                        "P32T",
                        "P46T",
                        "P208",
                        "P210",
                        "P750",
                        "RV6",
                        "RV7",
                        "RV8",
                        "RV9",
                        "RV10",
                        "RV12",
                        "S22T",
                        "S208",
                        "SIRA",
                        "SM92",
                        "SR20",
                        "SR22",
                        "SYMP",
                        "TAMP",
                        "TBM7",
                        "TBM8",
                        "TB20",
                        "TRIN",
                        "Z42",
                        "D400",
                        "DA20",
                        "DIMO",
                        "ECHO",
                        "EVSS",
                        "GLST",
                        "PZ3T",
                        "TL20",
                        "WT9",
                        "E200",
                        "WA50",
                        "E500",
                        "M600",
                        "T206",
                        "T210",
                        "TBM9",
                        "BT36",
                        "P28S",
                        "YK50",
                        "PIVI",
                        "PC9",
                        "RV4",
                        "PC7",
                        "TB21",
                        "TOBA",
                        "AN2",
                        "AT8T",
                        "E314",
                        "HR20",
                        "DH60",
                        "G12T",
                        "RF6",
                        "DISC",
                        "M5",
                        "COY2",
                        "EUPA",
                        "AAT3",
                        "ALTO",
                        "P92",
                        "PIAT",
                        "KL07",
                        "DHC2",
                        "MF9",
                        "CH70",
                        "SU26",
                        "FK14",
                        "DR10",
                        "JUNR",
                        "TWEN",
                        "CH7B",
                        "T6",
                        "VL3",
                        "G140",
                        "S450",
                        "SPIT",
                        "R90R",
                        "SKRA",
                        "PNR2",
                        "CP30",
                        "BDOG",
                        "J3",
                        "C140",
                        "PA12",
                        "L8",
                        "C120",
                        "ST75",
                        "ERCO",
                        "PA11",
                        "C82T",
                        "AT5T",
                        "LGEZ",
                        "E300",
                        "M7",
                        "GC1",
                        "DHC1",
                        "PA16",
                        "AT6T",
                        "DH82",
                        "LNP4",
                        "RV3",
                        "NG5",
                        "ACAM",
                        "LEG2",
                        "D11",
                        "RALL",
                        "SREY",
                        "MT",
                        "ESCP",
                        "TF19",
                        "PTS1",
                        "CJ6",
                        "RV14",
                        "F260",
                        "P337",
                        "MM16",
                        "TEX2",
                        "SVNH",
                        "SV4",
                        "PNR3",
                        "MCR1",
                        "YK52",
                        "VTRA",
                        "AT3T",
                        "CP10",
                        "ALIG",
                        "TRF1",
                        "CH80",
                        "TL30",
                        "D140",
                        "MCR4",
                        "GX",
                        "A5",
                        "NIPR",
                        "FK9",
                        "G120",
                        "C336",
                        "CH75",
                        "IR23",
                        "D250",
                        "SLG2",
                        "D150",
                        "CT4",
                        "EAGL",
                        "SAKO",
                        "SUBA",
                        "PA17",
                        "T34P",
                        "D31",
                        "BX2",
                        "TRAL",
                        "MOR2",
                        "VEZE",
                        "BUSH",
                        "RF5",
                        "SX30",
                        "VELO",
                        "AP32",
                        "XL2",
                        "Z43",
                        "CC19",
                        "MR35",
                        "LA4",
                        "R200",
                        "WACF",
                        "AUS5",
                        "GY80",
                        "SHRK",
                        "JABI",
                        "DR22",
                        "BOLT",
                        "FAET",
                        "HUML",
                        "SB91",
                        "SKAR",
                        "PA15",
                        "AA1",
                        "SD4",
                        "T28",
                        "D253",
                        "B209",
                        "R300",
                        "TEXA",
                        "E400",
                        "J4",
                        "G3",
                        "PRIM",
                        "S108",
                        "BU31",
                        "J5",
                        "TOUR",
                        "SLG4",
                        "Z26",
                        "SEAW",
                        "MR25",
                        "DHC3",
                        "Y18T",
                        "TWST",
                        "P149",
                        "M6",
                        "PA36",
                        "D6SL",
                        "SMAX",
                        "HMNY"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 246,
                                "w": 48,
                                "h": 48
                            },
                            "15": {
                                "x": 48,
                                "y": 246,
                                "w": 49,
                                "h": 49
                            },
                            "30": {
                                "x": 97,
                                "y": 246,
                                "w": 47,
                                "h": 47
                            },
                            "45": {
                                "x": 144,
                                "y": 246,
                                "w": 44,
                                "h": 44
                            },
                            "60": {
                                "x": 188,
                                "y": 246,
                                "w": 43,
                                "h": 43
                            },
                            "75": {
                                "x": 231,
                                "y": 246,
                                "w": 41,
                                "h": 41
                            },
                            "90": {
                                "x": 272,
                                "y": 246,
                                "w": 42,
                                "h": 42
                            },
                            "105": {
                                "x": 314,
                                "y": 246,
                                "w": 42,
                                "h": 42
                            },
                            "120": {
                                "x": 356,
                                "y": 246,
                                "w": 43,
                                "h": 43
                            },
                            "135": {
                                "x": 399,
                                "y": 246,
                                "w": 44,
                                "h": 44
                            },
                            "150": {
                                "x": 443,
                                "y": 246,
                                "w": 47,
                                "h": 47
                            },
                            "165": {
                                "x": 490,
                                "y": 246,
                                "w": 49,
                                "h": 49
                            },
                            "180": {
                                "x": 539,
                                "y": 246,
                                "w": 48,
                                "h": 48
                            },
                            "195": {
                                "x": 587,
                                "y": 246,
                                "w": 49,
                                "h": 49
                            },
                            "210": {
                                "x": 636,
                                "y": 246,
                                "w": 47,
                                "h": 47
                            },
                            "225": {
                                "x": 683,
                                "y": 246,
                                "w": 44,
                                "h": 44
                            },
                            "240": {
                                "x": 727,
                                "y": 246,
                                "w": 43,
                                "h": 43
                            },
                            "255": {
                                "x": 770,
                                "y": 246,
                                "w": 41,
                                "h": 41
                            },
                            "270": {
                                "x": 811,
                                "y": 246,
                                "w": 42,
                                "h": 42
                            },
                            "285": {
                                "x": 853,
                                "y": 246,
                                "w": 42,
                                "h": 42
                            },
                            "300": {
                                "x": 895,
                                "y": 246,
                                "w": 43,
                                "h": 43
                            },
                            "315": {
                                "x": 938,
                                "y": 246,
                                "w": 44,
                                "h": 44
                            },
                            "330": {
                                "x": 982,
                                "y": 246,
                                "w": 47,
                                "h": 47
                            },
                            "345": {
                                "x": 1029,
                                "y": 246,
                                "w": 49,
                                "h": 49
                            }
                        }
                    ]
                },
                "C303": {
                    "rotates": true,
                    "aliases": [
                        "AC50",
                        "AC80",
                        "AC90",
                        "AC95",
                        "AEST",
                        "BE10",
                        "BE18",
                        "BE20",
                        "BE30",
                        "BE50",
                        "BE55",
                        "BE56",
                        "BE58",
                        "BE60",
                        "BE65",
                        "BE76",
                        "BE80",
                        "BE90",
                        "BE95",
                        "BE96",
                        "BE9L",
                        "BE9T",
                        "C310",
                        "C320",
                        "C335",
                        "C340",
                        "C401",
                        "C402",
                        "C404",
                        "C414",
                        "C421",
                        "C425",
                        "C441",
                        "C21T",
                        "D28T",
                        "DA42",
                        "F406",
                        "MU2",
                        "P34A",
                        "P68",
                        "P180",
                        "PA23",
                        "PA27",
                        "PA30",
                        "PA31",
                        "PA34",
                        "PA44",
                        "PA60",
                        "PAT4",
                        "PAY1",
                        "PAY2",
                        "PAY3",
                        "PAY4",
                        "SW2",
                        "SW3",
                        "TUCA",
                        "CL2T",
                        "DA62",
                        "B58T",
                        "D28D",
                        "GA7",
                        "P06T",
                        "DH3T"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 369,
                                "w": 56,
                                "h": 56
                            },
                            "15": {
                                "x": 56,
                                "y": 369,
                                "w": 57,
                                "h": 57
                            },
                            "30": {
                                "x": 113,
                                "y": 369,
                                "w": 55,
                                "h": 55
                            },
                            "45": {
                                "x": 168,
                                "y": 369,
                                "w": 53,
                                "h": 53
                            },
                            "60": {
                                "x": 221,
                                "y": 369,
                                "w": 50,
                                "h": 50
                            },
                            "75": {
                                "x": 271,
                                "y": 369,
                                "w": 52,
                                "h": 52
                            },
                            "90": {
                                "x": 323,
                                "y": 369,
                                "w": 52,
                                "h": 52
                            },
                            "105": {
                                "x": 375,
                                "y": 369,
                                "w": 51,
                                "h": 51
                            },
                            "120": {
                                "x": 426,
                                "y": 369,
                                "w": 50,
                                "h": 50
                            },
                            "135": {
                                "x": 476,
                                "y": 369,
                                "w": 53,
                                "h": 53
                            },
                            "150": {
                                "x": 529,
                                "y": 369,
                                "w": 55,
                                "h": 55
                            },
                            "165": {
                                "x": 584,
                                "y": 369,
                                "w": 57,
                                "h": 57
                            },
                            "180": {
                                "x": 641,
                                "y": 369,
                                "w": 56,
                                "h": 56
                            },
                            "195": {
                                "x": 697,
                                "y": 369,
                                "w": 57,
                                "h": 57
                            },
                            "210": {
                                "x": 754,
                                "y": 369,
                                "w": 55,
                                "h": 55
                            },
                            "225": {
                                "x": 809,
                                "y": 369,
                                "w": 53,
                                "h": 53
                            },
                            "240": {
                                "x": 862,
                                "y": 369,
                                "w": 50,
                                "h": 50
                            },
                            "255": {
                                "x": 912,
                                "y": 369,
                                "w": 52,
                                "h": 52
                            },
                            "270": {
                                "x": 964,
                                "y": 369,
                                "w": 52,
                                "h": 52
                            },
                            "285": {
                                "x": 1016,
                                "y": 369,
                                "w": 51,
                                "h": 51
                            },
                            "300": {
                                "x": 1067,
                                "y": 369,
                                "w": 50,
                                "h": 50
                            },
                            "315": {
                                "x": 1117,
                                "y": 369,
                                "w": 53,
                                "h": 53
                            },
                            "330": {
                                "x": 1170,
                                "y": 369,
                                "w": 55,
                                "h": 55
                            },
                            "345": {
                                "x": 1225,
                                "y": 369,
                                "w": 57,
                                "h": 57
                            }
                        }
                    ]
                },
                "LJ60": {
                    "rotates": true,
                    "aliases": [
                        "ASTR",
                        "BE40",
                        "C500",
                        "C501",
                        "C510",
                        "C525",
                        "C25A",
                        "C25B",
                        "C25C",
                        "C550",
                        "C551",
                        "C560",
                        "C56X",
                        "C650",
                        "C680",
                        "C750",
                        "CL30",
                        "CL35",
                        "CL60",
                        "CL64",
                        "E35L",
                        "E50P",
                        "E55P",
                        "EA50",
                        "F2TH",
                        "FA10",
                        "FA20",
                        "FA30",
                        "FA40",
                        "FA50",
                        "FA7X",
                        "F900",
                        "G150",
                        "G200",
                        "G250",
                        "G280",
                        "G550",
                        "GALX",
                        "GLF2",
                        "GLF3",
                        "GLF4",
                        "GLF5",
                        "GL5T",
                        "GLF6",
                        "GLEX",
                        "HA4T",
                        "H25B",
                        "H25C",
                        "L29B",
                        "LJ25",
                        "LJ31",
                        "LJ35",
                        "LJ36",
                        "LJ40",
                        "LJ45",
                        "LJ55",
                        "LJ70",
                        "LJ75",
                        "LJ85",
                        "LRJ",
                        "MU30",
                        "PRM1",
                        "SBR1",
                        "SBR2",
                        "WW24",
                        "FA8X",
                        "C55B",
                        "C700",
                        "E545",
                        "E550",
                        "C68A",
                        "C25M",
                        "HDJT",
                        "GA5C",
                        "GA6C",
                        "SF50",
                        "PC24",
                        "H25A",
                        "BE4W"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 492,
                                "w": 54,
                                "h": 54
                            },
                            "15": {
                                "x": 54,
                                "y": 492,
                                "w": 54,
                                "h": 54
                            },
                            "30": {
                                "x": 108,
                                "y": 492,
                                "w": 50,
                                "h": 50
                            },
                            "45": {
                                "x": 158,
                                "y": 492,
                                "w": 55,
                                "h": 55
                            },
                            "60": {
                                "x": 213,
                                "y": 492,
                                "w": 63,
                                "h": 63
                            },
                            "75": {
                                "x": 276,
                                "y": 492,
                                "w": 65,
                                "h": 65
                            },
                            "90": {
                                "x": 341,
                                "y": 492,
                                "w": 64,
                                "h": 64
                            },
                            "105": {
                                "x": 405,
                                "y": 492,
                                "w": 65,
                                "h": 65
                            },
                            "120": {
                                "x": 470,
                                "y": 492,
                                "w": 63,
                                "h": 63
                            },
                            "135": {
                                "x": 533,
                                "y": 492,
                                "w": 56,
                                "h": 56
                            },
                            "150": {
                                "x": 589,
                                "y": 492,
                                "w": 50,
                                "h": 50
                            },
                            "165": {
                                "x": 639,
                                "y": 492,
                                "w": 54,
                                "h": 54
                            },
                            "180": {
                                "x": 693,
                                "y": 492,
                                "w": 54,
                                "h": 54
                            },
                            "195": {
                                "x": 747,
                                "y": 492,
                                "w": 54,
                                "h": 54
                            },
                            "210": {
                                "x": 801,
                                "y": 492,
                                "w": 50,
                                "h": 50
                            },
                            "225": {
                                "x": 851,
                                "y": 492,
                                "w": 55,
                                "h": 55
                            },
                            "240": {
                                "x": 906,
                                "y": 492,
                                "w": 63,
                                "h": 63
                            },
                            "255": {
                                "x": 969,
                                "y": 492,
                                "w": 65,
                                "h": 65
                            },
                            "270": {
                                "x": 1034,
                                "y": 492,
                                "w": 64,
                                "h": 64
                            },
                            "285": {
                                "x": 1098,
                                "y": 492,
                                "w": 65,
                                "h": 65
                            },
                            "300": {
                                "x": 1163,
                                "y": 492,
                                "w": 63,
                                "h": 63
                            },
                            "315": {
                                "x": 1226,
                                "y": 492,
                                "w": 56,
                                "h": 56
                            },
                            "330": {
                                "x": 1282,
                                "y": 492,
                                "w": 50,
                                "h": 50
                            },
                            "345": {
                                "x": 1332,
                                "y": 492,
                                "w": 54,
                                "h": 54
                            }
                        }
                    ]
                },
                "Q300": {
                    "rotates": true,
                    "aliases": [
                        "A400",
                        "A748",
                        "AN12",
                        "AN24",
                        "AN26",
                        "AT42",
                        "AT72",
                        "AT43",
                        "AT44",
                        "AT45",
                        "AT46",
                        "AT73",
                        "AT74",
                        "AT75",
                        "AT76",
                        "ATP",
                        "B190",
                        "B350",
                        "BE99",
                        "BN2P",
                        "BN2T",
                        "C30J",
                        "C130",
                        "C212",
                        "CN35",
                        "CVLP",
                        "CVLT",
                        "D228",
                        "D328",
                        "DC3",
                        "DC3T",
                        "DC6",
                        "D6F",
                        "DHC6",
                        "DHC7",
                        "DH8A",
                        "DH8B",
                        "DH8C",
                        "DH8D",
                        "E110",
                        "E120",
                        "F50",
                        "G159",
                        "JS31",
                        "JS32",
                        "JS41",
                        "L188",
                        "L410",
                        "SC7",
                        "SF34",
                        "SB20",
                        "SH33",
                        "SH36",
                        "SW4",
                        "C295",
                        "AN32",
                        "P3",
                        "C27J",
                        "CL2P",
                        "V10",
                        "A140",
                        "F27",
                        "CONI"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 615,
                                "w": 64,
                                "h": 64
                            },
                            "15": {
                                "x": 64,
                                "y": 615,
                                "w": 64,
                                "h": 64
                            },
                            "30": {
                                "x": 128,
                                "y": 615,
                                "w": 60,
                                "h": 60
                            },
                            "45": {
                                "x": 188,
                                "y": 615,
                                "w": 60,
                                "h": 60
                            },
                            "60": {
                                "x": 248,
                                "y": 615,
                                "w": 62,
                                "h": 62
                            },
                            "75": {
                                "x": 310,
                                "y": 615,
                                "w": 64,
                                "h": 64
                            },
                            "90": {
                                "x": 374,
                                "y": 615,
                                "w": 63,
                                "h": 63
                            },
                            "105": {
                                "x": 437,
                                "y": 615,
                                "w": 64,
                                "h": 64
                            },
                            "120": {
                                "x": 501,
                                "y": 615,
                                "w": 62,
                                "h": 62
                            },
                            "135": {
                                "x": 563,
                                "y": 615,
                                "w": 61,
                                "h": 61
                            },
                            "150": {
                                "x": 624,
                                "y": 615,
                                "w": 61,
                                "h": 61
                            },
                            "165": {
                                "x": 685,
                                "y": 615,
                                "w": 64,
                                "h": 64
                            },
                            "180": {
                                "x": 749,
                                "y": 615,
                                "w": 64,
                                "h": 64
                            },
                            "195": {
                                "x": 813,
                                "y": 615,
                                "w": 64,
                                "h": 64
                            },
                            "210": {
                                "x": 877,
                                "y": 615,
                                "w": 60,
                                "h": 60
                            },
                            "225": {
                                "x": 937,
                                "y": 615,
                                "w": 60,
                                "h": 60
                            },
                            "240": {
                                "x": 997,
                                "y": 615,
                                "w": 62,
                                "h": 62
                            },
                            "255": {
                                "x": 1059,
                                "y": 615,
                                "w": 64,
                                "h": 64
                            },
                            "270": {
                                "x": 1123,
                                "y": 615,
                                "w": 63,
                                "h": 63
                            },
                            "285": {
                                "x": 1186,
                                "y": 615,
                                "w": 64,
                                "h": 64
                            },
                            "300": {
                                "x": 1250,
                                "y": 615,
                                "w": 62,
                                "h": 62
                            },
                            "315": {
                                "x": 1312,
                                "y": 615,
                                "w": 61,
                                "h": 61
                            },
                            "330": {
                                "x": 1373,
                                "y": 615,
                                "w": 61,
                                "h": 61
                            },
                            "345": {
                                "x": 1434,
                                "y": 615,
                                "w": 64,
                                "h": 64
                            }
                        }
                    ]
                },
                "B736": {
                    "rotates": true,
                    "aliases": [
                        "A318",
                        "A319",
                        "A19N",
                        "B735",
                        "BCS1",
                        "BCS3",
                        "E170",
                        "E190",
                        "S100",
                        "SU95",
                        "E75L",
                        "E75S",
                        "E195",
                        "B732",
                        "MRJ9",
                        "A148",
                        "A158",
                        "A178",
                        "E275",
                        "E290",
                        "E295",
                        "BER2",
                        "AN72"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 738,
                                "w": 63,
                                "h": 63
                            },
                            "15": {
                                "x": 63,
                                "y": 738,
                                "w": 61,
                                "h": 61
                            },
                            "30": {
                                "x": 124,
                                "y": 738,
                                "w": 56,
                                "h": 56
                            },
                            "45": {
                                "x": 180,
                                "y": 738,
                                "w": 57,
                                "h": 57
                            },
                            "60": {
                                "x": 237,
                                "y": 738,
                                "w": 63,
                                "h": 63
                            },
                            "75": {
                                "x": 300,
                                "y": 738,
                                "w": 64,
                                "h": 64
                            },
                            "90": {
                                "x": 364,
                                "y": 738,
                                "w": 64,
                                "h": 64
                            },
                            "105": {
                                "x": 428,
                                "y": 738,
                                "w": 64,
                                "h": 64
                            },
                            "120": {
                                "x": 492,
                                "y": 738,
                                "w": 63,
                                "h": 63
                            },
                            "135": {
                                "x": 555,
                                "y": 738,
                                "w": 57,
                                "h": 57
                            },
                            "150": {
                                "x": 612,
                                "y": 738,
                                "w": 56,
                                "h": 56
                            },
                            "165": {
                                "x": 668,
                                "y": 738,
                                "w": 61,
                                "h": 61
                            },
                            "180": {
                                "x": 729,
                                "y": 738,
                                "w": 63,
                                "h": 63
                            },
                            "195": {
                                "x": 792,
                                "y": 738,
                                "w": 61,
                                "h": 61
                            },
                            "210": {
                                "x": 853,
                                "y": 738,
                                "w": 56,
                                "h": 56
                            },
                            "225": {
                                "x": 909,
                                "y": 738,
                                "w": 57,
                                "h": 57
                            },
                            "240": {
                                "x": 966,
                                "y": 738,
                                "w": 63,
                                "h": 63
                            },
                            "255": {
                                "x": 1029,
                                "y": 738,
                                "w": 64,
                                "h": 64
                            },
                            "270": {
                                "x": 1093,
                                "y": 738,
                                "w": 64,
                                "h": 64
                            },
                            "285": {
                                "x": 1157,
                                "y": 738,
                                "w": 64,
                                "h": 64
                            },
                            "300": {
                                "x": 1221,
                                "y": 738,
                                "w": 63,
                                "h": 63
                            },
                            "315": {
                                "x": 1284,
                                "y": 738,
                                "w": 57,
                                "h": 57
                            },
                            "330": {
                                "x": 1341,
                                "y": 738,
                                "w": 56,
                                "h": 56
                            },
                            "345": {
                                "x": 1397,
                                "y": 738,
                                "w": 61,
                                "h": 61
                            }
                        }
                    ]
                },
                "F100": {
                    "rotates": true,
                    "aliases": [
                        "AJ27",
                        "B712",
                        "B717",
                        "B721",
                        "B722",
                        "CRJ",
                        "CRJ1",
                        "CRJ2",
                        "CRJ7",
                        "CRJ9",
                        "CRJX",
                        "DC91",
                        "DC93",
                        "DC95",
                        "E45X",
                        "E135",
                        "E145",
                        "F70",
                        "IL62",
                        "MD80",
                        "MD81",
                        "MD82",
                        "MD83",
                        "MD87",
                        "MD88",
                        "MD90",
                        "R721",
                        "T154",
                        "YK42",
                        "T134",
                        "YK40",
                        "GL7T",
                        "F28"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 861,
                                "w": 56,
                                "h": 56
                            },
                            "15": {
                                "x": 56,
                                "y": 861,
                                "w": 55,
                                "h": 55
                            },
                            "30": {
                                "x": 111,
                                "y": 861,
                                "w": 53,
                                "h": 53
                            },
                            "45": {
                                "x": 164,
                                "y": 861,
                                "w": 57,
                                "h": 57
                            },
                            "60": {
                                "x": 221,
                                "y": 861,
                                "w": 64,
                                "h": 64
                            },
                            "75": {
                                "x": 285,
                                "y": 861,
                                "w": 66,
                                "h": 66
                            },
                            "90": {
                                "x": 351,
                                "y": 861,
                                "w": 64,
                                "h": 64
                            },
                            "105": {
                                "x": 415,
                                "y": 861,
                                "w": 66,
                                "h": 66
                            },
                            "120": {
                                "x": 481,
                                "y": 861,
                                "w": 64,
                                "h": 64
                            },
                            "135": {
                                "x": 545,
                                "y": 861,
                                "w": 57,
                                "h": 57
                            },
                            "150": {
                                "x": 602,
                                "y": 861,
                                "w": 51,
                                "h": 51
                            },
                            "165": {
                                "x": 653,
                                "y": 861,
                                "w": 55,
                                "h": 55
                            },
                            "180": {
                                "x": 708,
                                "y": 861,
                                "w": 56,
                                "h": 56
                            },
                            "195": {
                                "x": 764,
                                "y": 861,
                                "w": 55,
                                "h": 55
                            },
                            "210": {
                                "x": 819,
                                "y": 861,
                                "w": 53,
                                "h": 53
                            },
                            "225": {
                                "x": 872,
                                "y": 861,
                                "w": 57,
                                "h": 57
                            },
                            "240": {
                                "x": 929,
                                "y": 861,
                                "w": 64,
                                "h": 64
                            },
                            "255": {
                                "x": 993,
                                "y": 861,
                                "w": 66,
                                "h": 66
                            },
                            "270": {
                                "x": 1059,
                                "y": 861,
                                "w": 64,
                                "h": 64
                            },
                            "285": {
                                "x": 1123,
                                "y": 861,
                                "w": 66,
                                "h": 66
                            },
                            "300": {
                                "x": 1189,
                                "y": 861,
                                "w": 64,
                                "h": 64
                            },
                            "315": {
                                "x": 1253,
                                "y": 861,
                                "w": 57,
                                "h": 57
                            },
                            "330": {
                                "x": 1310,
                                "y": 861,
                                "w": 51,
                                "h": 51
                            },
                            "345": {
                                "x": 1361,
                                "y": 861,
                                "w": 55,
                                "h": 55
                            }
                        }
                    ]
                },
                "RJ85": {
                    "rotates": true,
                    "aliases": [
                        "B461",
                        "B462",
                        "B463",
                        "C17",
                        "IL76",
                        "RJ1H",
                        "RJ70",
                        "RJ100",
                        "J328"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 984,
                                "w": 64,
                                "h": 64
                            },
                            "15": {
                                "x": 64,
                                "y": 984,
                                "w": 62,
                                "h": 62
                            },
                            "30": {
                                "x": 126,
                                "y": 984,
                                "w": 59,
                                "h": 59
                            },
                            "45": {
                                "x": 185,
                                "y": 984,
                                "w": 60,
                                "h": 60
                            },
                            "60": {
                                "x": 245,
                                "y": 984,
                                "w": 66,
                                "h": 66
                            },
                            "75": {
                                "x": 311,
                                "y": 984,
                                "w": 67,
                                "h": 67
                            },
                            "90": {
                                "x": 378,
                                "y": 984,
                                "w": 64,
                                "h": 64
                            },
                            "105": {
                                "x": 442,
                                "y": 984,
                                "w": 67,
                                "h": 67
                            },
                            "120": {
                                "x": 509,
                                "y": 984,
                                "w": 66,
                                "h": 66
                            },
                            "135": {
                                "x": 575,
                                "y": 984,
                                "w": 60,
                                "h": 60
                            },
                            "150": {
                                "x": 635,
                                "y": 984,
                                "w": 59,
                                "h": 59
                            },
                            "165": {
                                "x": 694,
                                "y": 984,
                                "w": 62,
                                "h": 62
                            },
                            "180": {
                                "x": 756,
                                "y": 984,
                                "w": 64,
                                "h": 64
                            },
                            "195": {
                                "x": 820,
                                "y": 984,
                                "w": 62,
                                "h": 62
                            },
                            "210": {
                                "x": 882,
                                "y": 984,
                                "w": 59,
                                "h": 59
                            },
                            "225": {
                                "x": 941,
                                "y": 984,
                                "w": 60,
                                "h": 60
                            },
                            "240": {
                                "x": 1001,
                                "y": 984,
                                "w": 66,
                                "h": 66
                            },
                            "255": {
                                "x": 1067,
                                "y": 984,
                                "w": 67,
                                "h": 67
                            },
                            "270": {
                                "x": 1134,
                                "y": 984,
                                "w": 64,
                                "h": 64
                            },
                            "285": {
                                "x": 1198,
                                "y": 984,
                                "w": 67,
                                "h": 67
                            },
                            "300": {
                                "x": 1265,
                                "y": 984,
                                "w": 66,
                                "h": 66
                            },
                            "315": {
                                "x": 1331,
                                "y": 984,
                                "w": 60,
                                "h": 60
                            },
                            "330": {
                                "x": 1391,
                                "y": 984,
                                "w": 59,
                                "h": 59
                            },
                            "345": {
                                "x": 1450,
                                "y": 984,
                                "w": 62,
                                "h": 62
                            }
                        }
                    ]
                },
                "A320": {
                    "rotates": true,
                    "aliases": [
                        "A321",
                        "A20N",
                        "A21N"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 1107,
                                "w": 70,
                                "h": 70
                            },
                            "15": {
                                "x": 70,
                                "y": 1107,
                                "w": 69,
                                "h": 69
                            },
                            "30": {
                                "x": 139,
                                "y": 1107,
                                "w": 63,
                                "h": 63
                            },
                            "45": {
                                "x": 202,
                                "y": 1107,
                                "w": 61,
                                "h": 61
                            },
                            "60": {
                                "x": 263,
                                "y": 1107,
                                "w": 68,
                                "h": 68
                            },
                            "75": {
                                "x": 331,
                                "y": 1107,
                                "w": 72,
                                "h": 72
                            },
                            "90": {
                                "x": 403,
                                "y": 1107,
                                "w": 72,
                                "h": 72
                            },
                            "105": {
                                "x": 475,
                                "y": 1107,
                                "w": 72,
                                "h": 72
                            },
                            "120": {
                                "x": 547,
                                "y": 1107,
                                "w": 68,
                                "h": 68
                            },
                            "135": {
                                "x": 615,
                                "y": 1107,
                                "w": 62,
                                "h": 62
                            },
                            "150": {
                                "x": 677,
                                "y": 1107,
                                "w": 63,
                                "h": 63
                            },
                            "165": {
                                "x": 740,
                                "y": 1107,
                                "w": 68,
                                "h": 68
                            },
                            "180": {
                                "x": 808,
                                "y": 1107,
                                "w": 70,
                                "h": 70
                            },
                            "195": {
                                "x": 878,
                                "y": 1107,
                                "w": 69,
                                "h": 69
                            },
                            "210": {
                                "x": 947,
                                "y": 1107,
                                "w": 63,
                                "h": 63
                            },
                            "225": {
                                "x": 1010,
                                "y": 1107,
                                "w": 61,
                                "h": 61
                            },
                            "240": {
                                "x": 1071,
                                "y": 1107,
                                "w": 68,
                                "h": 68
                            },
                            "255": {
                                "x": 1139,
                                "y": 1107,
                                "w": 72,
                                "h": 72
                            },
                            "270": {
                                "x": 1211,
                                "y": 1107,
                                "w": 72,
                                "h": 72
                            },
                            "285": {
                                "x": 1283,
                                "y": 1107,
                                "w": 72,
                                "h": 72
                            },
                            "300": {
                                "x": 1355,
                                "y": 1107,
                                "w": 68,
                                "h": 68
                            },
                            "315": {
                                "x": 1423,
                                "y": 1107,
                                "w": 62,
                                "h": 62
                            },
                            "330": {
                                "x": 1485,
                                "y": 1107,
                                "w": 63,
                                "h": 63
                            },
                            "345": {
                                "x": 1548,
                                "y": 1107,
                                "w": 68,
                                "h": 68
                            }
                        }
                    ]
                },
                "B738": {
                    "rotates": true,
                    "aliases": [
                        "B733",
                        "B734",
                        "B737",
                        "B739",
                        "B37M",
                        "B38M",
                        "B39M",
                        "B73H",
                        "P8"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 1230,
                                "w": 71,
                                "h": 71
                            },
                            "15": {
                                "x": 71,
                                "y": 1230,
                                "w": 68,
                                "h": 68
                            },
                            "30": {
                                "x": 139,
                                "y": 1230,
                                "w": 64,
                                "h": 64
                            },
                            "45": {
                                "x": 203,
                                "y": 1230,
                                "w": 63,
                                "h": 63
                            },
                            "60": {
                                "x": 266,
                                "y": 1230,
                                "w": 71,
                                "h": 71
                            },
                            "75": {
                                "x": 337,
                                "y": 1230,
                                "w": 73,
                                "h": 73
                            },
                            "90": {
                                "x": 410,
                                "y": 1230,
                                "w": 72,
                                "h": 72
                            },
                            "105": {
                                "x": 482,
                                "y": 1230,
                                "w": 73,
                                "h": 73
                            },
                            "120": {
                                "x": 555,
                                "y": 1230,
                                "w": 69,
                                "h": 69
                            },
                            "135": {
                                "x": 624,
                                "y": 1230,
                                "w": 63,
                                "h": 63
                            },
                            "150": {
                                "x": 687,
                                "y": 1230,
                                "w": 64,
                                "h": 64
                            },
                            "165": {
                                "x": 751,
                                "y": 1230,
                                "w": 69,
                                "h": 69
                            },
                            "180": {
                                "x": 820,
                                "y": 1230,
                                "w": 71,
                                "h": 71
                            },
                            "195": {
                                "x": 891,
                                "y": 1230,
                                "w": 68,
                                "h": 68
                            },
                            "210": {
                                "x": 959,
                                "y": 1230,
                                "w": 64,
                                "h": 64
                            },
                            "225": {
                                "x": 1023,
                                "y": 1230,
                                "w": 63,
                                "h": 63
                            },
                            "240": {
                                "x": 1086,
                                "y": 1230,
                                "w": 71,
                                "h": 71
                            },
                            "255": {
                                "x": 1157,
                                "y": 1230,
                                "w": 73,
                                "h": 73
                            },
                            "270": {
                                "x": 1230,
                                "y": 1230,
                                "w": 72,
                                "h": 72
                            },
                            "285": {
                                "x": 1302,
                                "y": 1230,
                                "w": 73,
                                "h": 73
                            },
                            "300": {
                                "x": 1375,
                                "y": 1230,
                                "w": 69,
                                "h": 69
                            },
                            "315": {
                                "x": 1444,
                                "y": 1230,
                                "w": 63,
                                "h": 63
                            },
                            "330": {
                                "x": 1507,
                                "y": 1230,
                                "w": 64,
                                "h": 64
                            },
                            "345": {
                                "x": 1571,
                                "y": 1230,
                                "w": 69,
                                "h": 69
                            }
                        }
                    ]
                },
                "B757": {
                    "rotates": true,
                    "aliases": [
                        "B752",
                        "B753",
                        "T204",
                        "T214"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 1353,
                                "w": 68,
                                "h": 68
                            },
                            "15": {
                                "x": 68,
                                "y": 1353,
                                "w": 67,
                                "h": 67
                            },
                            "30": {
                                "x": 135,
                                "y": 1353,
                                "w": 62,
                                "h": 62
                            },
                            "45": {
                                "x": 197,
                                "y": 1353,
                                "w": 72,
                                "h": 72
                            },
                            "60": {
                                "x": 269,
                                "y": 1353,
                                "w": 82,
                                "h": 82
                            },
                            "75": {
                                "x": 351,
                                "y": 1353,
                                "w": 87,
                                "h": 87
                            },
                            "90": {
                                "x": 438,
                                "y": 1353,
                                "w": 88,
                                "h": 88
                            },
                            "105": {
                                "x": 526,
                                "y": 1353,
                                "w": 87,
                                "h": 87
                            },
                            "120": {
                                "x": 613,
                                "y": 1353,
                                "w": 82,
                                "h": 82
                            },
                            "135": {
                                "x": 695,
                                "y": 1353,
                                "w": 72,
                                "h": 72
                            },
                            "150": {
                                "x": 767,
                                "y": 1353,
                                "w": 62,
                                "h": 62
                            },
                            "165": {
                                "x": 829,
                                "y": 1353,
                                "w": 67,
                                "h": 67
                            },
                            "180": {
                                "x": 896,
                                "y": 1353,
                                "w": 68,
                                "h": 68
                            },
                            "195": {
                                "x": 964,
                                "y": 1353,
                                "w": 67,
                                "h": 67
                            },
                            "210": {
                                "x": 1031,
                                "y": 1353,
                                "w": 62,
                                "h": 62
                            },
                            "225": {
                                "x": 1093,
                                "y": 1353,
                                "w": 72,
                                "h": 72
                            },
                            "240": {
                                "x": 1165,
                                "y": 1353,
                                "w": 82,
                                "h": 82
                            },
                            "255": {
                                "x": 1247,
                                "y": 1353,
                                "w": 87,
                                "h": 87
                            },
                            "270": {
                                "x": 1334,
                                "y": 1353,
                                "w": 88,
                                "h": 88
                            },
                            "285": {
                                "x": 1422,
                                "y": 1353,
                                "w": 87,
                                "h": 87
                            },
                            "300": {
                                "x": 1509,
                                "y": 1353,
                                "w": 82,
                                "h": 82
                            },
                            "315": {
                                "x": 1591,
                                "y": 1353,
                                "w": 72,
                                "h": 72
                            },
                            "330": {
                                "x": 1663,
                                "y": 1353,
                                "w": 62,
                                "h": 62
                            },
                            "345": {
                                "x": 1725,
                                "y": 1353,
                                "w": 67,
                                "h": 67
                            }
                        }
                    ]
                },
                "A3ST": {
                    "rotates": true,
                    "aliases": [
                        "A337"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 1476,
                                "w": 84,
                                "h": 84
                            },
                            "15": {
                                "x": 84,
                                "y": 1476,
                                "w": 82,
                                "h": 82
                            },
                            "30": {
                                "x": 166,
                                "y": 1476,
                                "w": 77,
                                "h": 77
                            },
                            "45": {
                                "x": 243,
                                "y": 1476,
                                "w": 78,
                                "h": 78
                            },
                            "60": {
                                "x": 321,
                                "y": 1476,
                                "w": 86,
                                "h": 86
                            },
                            "75": {
                                "x": 407,
                                "y": 1476,
                                "w": 89,
                                "h": 89
                            },
                            "90": {
                                "x": 496,
                                "y": 1476,
                                "w": 88,
                                "h": 88
                            },
                            "105": {
                                "x": 584,
                                "y": 1476,
                                "w": 89,
                                "h": 89
                            },
                            "120": {
                                "x": 673,
                                "y": 1476,
                                "w": 86,
                                "h": 86
                            },
                            "135": {
                                "x": 759,
                                "y": 1476,
                                "w": 78,
                                "h": 78
                            },
                            "150": {
                                "x": 837,
                                "y": 1476,
                                "w": 77,
                                "h": 77
                            },
                            "165": {
                                "x": 914,
                                "y": 1476,
                                "w": 82,
                                "h": 82
                            },
                            "180": {
                                "x": 996,
                                "y": 1476,
                                "w": 84,
                                "h": 84
                            },
                            "195": {
                                "x": 1080,
                                "y": 1476,
                                "w": 82,
                                "h": 82
                            },
                            "210": {
                                "x": 1162,
                                "y": 1476,
                                "w": 77,
                                "h": 77
                            },
                            "225": {
                                "x": 1239,
                                "y": 1476,
                                "w": 78,
                                "h": 78
                            },
                            "240": {
                                "x": 1317,
                                "y": 1476,
                                "w": 86,
                                "h": 86
                            },
                            "255": {
                                "x": 1403,
                                "y": 1476,
                                "w": 89,
                                "h": 89
                            },
                            "270": {
                                "x": 1492,
                                "y": 1476,
                                "w": 88,
                                "h": 88
                            },
                            "285": {
                                "x": 1580,
                                "y": 1476,
                                "w": 89,
                                "h": 89
                            },
                            "300": {
                                "x": 1669,
                                "y": 1476,
                                "w": 86,
                                "h": 86
                            },
                            "315": {
                                "x": 1755,
                                "y": 1476,
                                "w": 78,
                                "h": 78
                            },
                            "330": {
                                "x": 1833,
                                "y": 1476,
                                "w": 77,
                                "h": 77
                            },
                            "345": {
                                "x": 1910,
                                "y": 1476,
                                "w": 82,
                                "h": 82
                            }
                        }
                    ]
                },
                "MD11": {
                    "rotates": true,
                    "aliases": [
                        "DC10",
                        "L101"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 1599,
                                "w": 78,
                                "h": 78
                            },
                            "15": {
                                "x": 78,
                                "y": 1599,
                                "w": 76,
                                "h": 76
                            },
                            "30": {
                                "x": 154,
                                "y": 1599,
                                "w": 71,
                                "h": 71
                            },
                            "45": {
                                "x": 225,
                                "y": 1599,
                                "w": 76,
                                "h": 76
                            },
                            "60": {
                                "x": 301,
                                "y": 1599,
                                "w": 82,
                                "h": 82
                            },
                            "75": {
                                "x": 383,
                                "y": 1599,
                                "w": 87,
                                "h": 87
                            },
                            "90": {
                                "x": 470,
                                "y": 1599,
                                "w": 88,
                                "h": 88
                            },
                            "105": {
                                "x": 558,
                                "y": 1599,
                                "w": 88,
                                "h": 88
                            },
                            "120": {
                                "x": 646,
                                "y": 1599,
                                "w": 82,
                                "h": 82
                            },
                            "135": {
                                "x": 728,
                                "y": 1599,
                                "w": 76,
                                "h": 76
                            },
                            "150": {
                                "x": 804,
                                "y": 1599,
                                "w": 70,
                                "h": 70
                            },
                            "165": {
                                "x": 874,
                                "y": 1599,
                                "w": 76,
                                "h": 76
                            },
                            "180": {
                                "x": 950,
                                "y": 1599,
                                "w": 78,
                                "h": 78
                            },
                            "195": {
                                "x": 1028,
                                "y": 1599,
                                "w": 76,
                                "h": 76
                            },
                            "210": {
                                "x": 1104,
                                "y": 1599,
                                "w": 71,
                                "h": 71
                            },
                            "225": {
                                "x": 1175,
                                "y": 1599,
                                "w": 76,
                                "h": 76
                            },
                            "240": {
                                "x": 1251,
                                "y": 1599,
                                "w": 82,
                                "h": 82
                            },
                            "255": {
                                "x": 1333,
                                "y": 1599,
                                "w": 87,
                                "h": 87
                            },
                            "270": {
                                "x": 1420,
                                "y": 1599,
                                "w": 88,
                                "h": 88
                            },
                            "285": {
                                "x": 1508,
                                "y": 1599,
                                "w": 88,
                                "h": 88
                            },
                            "300": {
                                "x": 1596,
                                "y": 1599,
                                "w": 82,
                                "h": 82
                            },
                            "315": {
                                "x": 1678,
                                "y": 1599,
                                "w": 76,
                                "h": 76
                            },
                            "330": {
                                "x": 1754,
                                "y": 1599,
                                "w": 70,
                                "h": 70
                            },
                            "345": {
                                "x": 1824,
                                "y": 1599,
                                "w": 76,
                                "h": 76
                            }
                        }
                    ]
                },
                "A330": {
                    "rotates": true,
                    "aliases": [
                        "B762",
                        "B763",
                        "B764",
                        "A300",
                        "A30B",
                        "A306",
                        "A310",
                        "A332",
                        "A333",
                        "A338",
                        "A339",
                        "A359",
                        "B787",
                        "B788",
                        "B789",
                        "B78X"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 1722,
                                "w": 80,
                                "h": 80
                            },
                            "15": {
                                "x": 80,
                                "y": 1722,
                                "w": 78,
                                "h": 78
                            },
                            "30": {
                                "x": 158,
                                "y": 1722,
                                "w": 72,
                                "h": 72
                            },
                            "45": {
                                "x": 230,
                                "y": 1722,
                                "w": 68,
                                "h": 68
                            },
                            "60": {
                                "x": 298,
                                "y": 1722,
                                "w": 77,
                                "h": 77
                            },
                            "75": {
                                "x": 375,
                                "y": 1722,
                                "w": 81,
                                "h": 81
                            },
                            "90": {
                                "x": 456,
                                "y": 1722,
                                "w": 80,
                                "h": 80
                            },
                            "105": {
                                "x": 536,
                                "y": 1722,
                                "w": 80,
                                "h": 80
                            },
                            "120": {
                                "x": 616,
                                "y": 1722,
                                "w": 76,
                                "h": 76
                            },
                            "135": {
                                "x": 692,
                                "y": 1722,
                                "w": 68,
                                "h": 68
                            },
                            "150": {
                                "x": 760,
                                "y": 1722,
                                "w": 72,
                                "h": 72
                            },
                            "165": {
                                "x": 832,
                                "y": 1722,
                                "w": 78,
                                "h": 78
                            },
                            "180": {
                                "x": 910,
                                "y": 1722,
                                "w": 80,
                                "h": 80
                            },
                            "195": {
                                "x": 990,
                                "y": 1722,
                                "w": 78,
                                "h": 78
                            },
                            "210": {
                                "x": 1068,
                                "y": 1722,
                                "w": 72,
                                "h": 72
                            },
                            "225": {
                                "x": 1140,
                                "y": 1722,
                                "w": 68,
                                "h": 68
                            },
                            "240": {
                                "x": 1208,
                                "y": 1722,
                                "w": 77,
                                "h": 77
                            },
                            "255": {
                                "x": 1285,
                                "y": 1722,
                                "w": 81,
                                "h": 81
                            },
                            "270": {
                                "x": 1366,
                                "y": 1722,
                                "w": 80,
                                "h": 80
                            },
                            "285": {
                                "x": 1446,
                                "y": 1722,
                                "w": 80,
                                "h": 80
                            },
                            "300": {
                                "x": 1526,
                                "y": 1722,
                                "w": 76,
                                "h": 76
                            },
                            "315": {
                                "x": 1602,
                                "y": 1722,
                                "w": 68,
                                "h": 68
                            },
                            "330": {
                                "x": 1670,
                                "y": 1722,
                                "w": 72,
                                "h": 72
                            },
                            "345": {
                                "x": 1742,
                                "y": 1722,
                                "w": 78,
                                "h": 78
                            }
                        }
                    ]
                },
                "A340": {
                    "rotates": true,
                    "aliases": [
                        "A342",
                        "A343",
                        "B703",
                        "DC86",
                        "DC87",
                        "E6",
                        "IL86",
                        "IL96",
                        "C135",
                        "E3CF",
                        "R135",
                        "K35R",
                        "E3TF",
                        "K35E",
                        "A345",
                        "A346"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 1845,
                                "w": 88,
                                "h": 88
                            },
                            "15": {
                                "x": 88,
                                "y": 1845,
                                "w": 86,
                                "h": 86
                            },
                            "30": {
                                "x": 174,
                                "y": 1845,
                                "w": 79,
                                "h": 79
                            },
                            "45": {
                                "x": 253,
                                "y": 1845,
                                "w": 76,
                                "h": 76
                            },
                            "60": {
                                "x": 329,
                                "y": 1845,
                                "w": 85,
                                "h": 85
                            },
                            "75": {
                                "x": 414,
                                "y": 1845,
                                "w": 89,
                                "h": 89
                            },
                            "90": {
                                "x": 503,
                                "y": 1845,
                                "w": 88,
                                "h": 88
                            },
                            "105": {
                                "x": 591,
                                "y": 1845,
                                "w": 90,
                                "h": 90
                            },
                            "120": {
                                "x": 681,
                                "y": 1845,
                                "w": 85,
                                "h": 85
                            },
                            "135": {
                                "x": 766,
                                "y": 1845,
                                "w": 76,
                                "h": 76
                            },
                            "150": {
                                "x": 842,
                                "y": 1845,
                                "w": 79,
                                "h": 79
                            },
                            "165": {
                                "x": 921,
                                "y": 1845,
                                "w": 86,
                                "h": 86
                            },
                            "180": {
                                "x": 1007,
                                "y": 1845,
                                "w": 88,
                                "h": 88
                            },
                            "195": {
                                "x": 1095,
                                "y": 1845,
                                "w": 86,
                                "h": 86
                            },
                            "210": {
                                "x": 1181,
                                "y": 1845,
                                "w": 79,
                                "h": 79
                            },
                            "225": {
                                "x": 1260,
                                "y": 1845,
                                "w": 76,
                                "h": 76
                            },
                            "240": {
                                "x": 1336,
                                "y": 1845,
                                "w": 85,
                                "h": 85
                            },
                            "255": {
                                "x": 1421,
                                "y": 1845,
                                "w": 89,
                                "h": 89
                            },
                            "270": {
                                "x": 1510,
                                "y": 1845,
                                "w": 88,
                                "h": 88
                            },
                            "285": {
                                "x": 1598,
                                "y": 1845,
                                "w": 90,
                                "h": 90
                            },
                            "300": {
                                "x": 1688,
                                "y": 1845,
                                "w": 85,
                                "h": 85
                            },
                            "315": {
                                "x": 1773,
                                "y": 1845,
                                "w": 76,
                                "h": 76
                            },
                            "330": {
                                "x": 1849,
                                "y": 1845,
                                "w": 79,
                                "h": 79
                            },
                            "345": {
                                "x": 1928,
                                "y": 1845,
                                "w": 86,
                                "h": 86
                            }
                        }
                    ]
                },
                "B777": {
                    "rotates": true,
                    "aliases": [
                        "B772",
                        "B773",
                        "B77L",
                        "B77W",
                        "B778",
                        "B779",
                        "A35K"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 1968,
                                "w": 86,
                                "h": 86
                            },
                            "15": {
                                "x": 86,
                                "y": 1968,
                                "w": 84,
                                "h": 84
                            },
                            "30": {
                                "x": 170,
                                "y": 1968,
                                "w": 77,
                                "h": 77
                            },
                            "45": {
                                "x": 247,
                                "y": 1968,
                                "w": 76,
                                "h": 76
                            },
                            "60": {
                                "x": 323,
                                "y": 1968,
                                "w": 84,
                                "h": 84
                            },
                            "75": {
                                "x": 407,
                                "y": 1968,
                                "w": 89,
                                "h": 89
                            },
                            "90": {
                                "x": 496,
                                "y": 1968,
                                "w": 88,
                                "h": 88
                            },
                            "105": {
                                "x": 584,
                                "y": 1968,
                                "w": 89,
                                "h": 89
                            },
                            "120": {
                                "x": 673,
                                "y": 1968,
                                "w": 84,
                                "h": 84
                            },
                            "135": {
                                "x": 757,
                                "y": 1968,
                                "w": 76,
                                "h": 76
                            },
                            "150": {
                                "x": 833,
                                "y": 1968,
                                "w": 77,
                                "h": 77
                            },
                            "165": {
                                "x": 910,
                                "y": 1968,
                                "w": 84,
                                "h": 84
                            },
                            "180": {
                                "x": 994,
                                "y": 1968,
                                "w": 86,
                                "h": 86
                            },
                            "195": {
                                "x": 1080,
                                "y": 1968,
                                "w": 84,
                                "h": 84
                            },
                            "210": {
                                "x": 1164,
                                "y": 1968,
                                "w": 77,
                                "h": 77
                            },
                            "225": {
                                "x": 1241,
                                "y": 1968,
                                "w": 76,
                                "h": 76
                            },
                            "240": {
                                "x": 1317,
                                "y": 1968,
                                "w": 84,
                                "h": 84
                            },
                            "255": {
                                "x": 1401,
                                "y": 1968,
                                "w": 89,
                                "h": 89
                            },
                            "270": {
                                "x": 1490,
                                "y": 1968,
                                "w": 88,
                                "h": 88
                            },
                            "285": {
                                "x": 1578,
                                "y": 1968,
                                "w": 89,
                                "h": 89
                            },
                            "300": {
                                "x": 1667,
                                "y": 1968,
                                "w": 84,
                                "h": 84
                            },
                            "315": {
                                "x": 1751,
                                "y": 1968,
                                "w": 76,
                                "h": 76
                            },
                            "330": {
                                "x": 1827,
                                "y": 1968,
                                "w": 77,
                                "h": 77
                            },
                            "345": {
                                "x": 1904,
                                "y": 1968,
                                "w": 84,
                                "h": 84
                            }
                        }
                    ]
                },
                "B747": {
                    "rotates": true,
                    "aliases": [
                        "A124",
                        "B741",
                        "B742",
                        "B743",
                        "B744",
                        "B74S",
                        "B748",
                        "BLCF",
                        "C5"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 2091,
                                "w": 80,
                                "h": 80
                            },
                            "15": {
                                "x": 80,
                                "y": 2091,
                                "w": 79,
                                "h": 79
                            },
                            "30": {
                                "x": 159,
                                "y": 2091,
                                "w": 72,
                                "h": 72
                            },
                            "45": {
                                "x": 231,
                                "y": 2091,
                                "w": 76,
                                "h": 76
                            },
                            "60": {
                                "x": 307,
                                "y": 2091,
                                "w": 84,
                                "h": 84
                            },
                            "75": {
                                "x": 391,
                                "y": 2091,
                                "w": 88,
                                "h": 88
                            },
                            "90": {
                                "x": 479,
                                "y": 2091,
                                "w": 88,
                                "h": 88
                            },
                            "105": {
                                "x": 567,
                                "y": 2091,
                                "w": 88,
                                "h": 88
                            },
                            "120": {
                                "x": 655,
                                "y": 2091,
                                "w": 84,
                                "h": 84
                            },
                            "135": {
                                "x": 739,
                                "y": 2091,
                                "w": 76,
                                "h": 76
                            },
                            "150": {
                                "x": 815,
                                "y": 2091,
                                "w": 73,
                                "h": 73
                            },
                            "165": {
                                "x": 888,
                                "y": 2091,
                                "w": 79,
                                "h": 79
                            },
                            "180": {
                                "x": 967,
                                "y": 2091,
                                "w": 80,
                                "h": 80
                            },
                            "195": {
                                "x": 1047,
                                "y": 2091,
                                "w": 79,
                                "h": 79
                            },
                            "210": {
                                "x": 1126,
                                "y": 2091,
                                "w": 72,
                                "h": 72
                            },
                            "225": {
                                "x": 1198,
                                "y": 2091,
                                "w": 76,
                                "h": 76
                            },
                            "240": {
                                "x": 1274,
                                "y": 2091,
                                "w": 84,
                                "h": 84
                            },
                            "255": {
                                "x": 1358,
                                "y": 2091,
                                "w": 88,
                                "h": 88
                            },
                            "270": {
                                "x": 1446,
                                "y": 2091,
                                "w": 88,
                                "h": 88
                            },
                            "285": {
                                "x": 1534,
                                "y": 2091,
                                "w": 88,
                                "h": 88
                            },
                            "300": {
                                "x": 1622,
                                "y": 2091,
                                "w": 84,
                                "h": 84
                            },
                            "315": {
                                "x": 1706,
                                "y": 2091,
                                "w": 76,
                                "h": 76
                            },
                            "330": {
                                "x": 1782,
                                "y": 2091,
                                "w": 73,
                                "h": 73
                            },
                            "345": {
                                "x": 1855,
                                "y": 2091,
                                "w": 79,
                                "h": 79
                            }
                        }
                    ]
                },
                "A380": {
                    "rotates": true,
                    "aliases": [
                        "A388",
                        "A389"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 2214,
                                "w": 88,
                                "h": 88
                            },
                            "15": {
                                "x": 88,
                                "y": 2214,
                                "w": 88,
                                "h": 88
                            },
                            "30": {
                                "x": 176,
                                "y": 2214,
                                "w": 82,
                                "h": 82
                            },
                            "45": {
                                "x": 258,
                                "y": 2214,
                                "w": 76,
                                "h": 76
                            },
                            "60": {
                                "x": 334,
                                "y": 2214,
                                "w": 81,
                                "h": 81
                            },
                            "75": {
                                "x": 415,
                                "y": 2214,
                                "w": 85,
                                "h": 85
                            },
                            "90": {
                                "x": 500,
                                "y": 2214,
                                "w": 87,
                                "h": 87
                            },
                            "105": {
                                "x": 587,
                                "y": 2214,
                                "w": 85,
                                "h": 85
                            },
                            "120": {
                                "x": 672,
                                "y": 2214,
                                "w": 82,
                                "h": 82
                            },
                            "135": {
                                "x": 754,
                                "y": 2214,
                                "w": 76,
                                "h": 76
                            },
                            "150": {
                                "x": 830,
                                "y": 2214,
                                "w": 82,
                                "h": 82
                            },
                            "165": {
                                "x": 912,
                                "y": 2214,
                                "w": 88,
                                "h": 88
                            },
                            "180": {
                                "x": 1000,
                                "y": 2214,
                                "w": 88,
                                "h": 88
                            },
                            "195": {
                                "x": 1088,
                                "y": 2214,
                                "w": 88,
                                "h": 88
                            },
                            "210": {
                                "x": 1176,
                                "y": 2214,
                                "w": 82,
                                "h": 82
                            },
                            "225": {
                                "x": 1258,
                                "y": 2214,
                                "w": 76,
                                "h": 76
                            },
                            "240": {
                                "x": 1334,
                                "y": 2214,
                                "w": 81,
                                "h": 81
                            },
                            "255": {
                                "x": 1415,
                                "y": 2214,
                                "w": 85,
                                "h": 85
                            },
                            "270": {
                                "x": 1500,
                                "y": 2214,
                                "w": 87,
                                "h": 87
                            },
                            "285": {
                                "x": 1587,
                                "y": 2214,
                                "w": 85,
                                "h": 85
                            },
                            "300": {
                                "x": 1672,
                                "y": 2214,
                                "w": 82,
                                "h": 82
                            },
                            "315": {
                                "x": 1754,
                                "y": 2214,
                                "w": 76,
                                "h": 76
                            },
                            "330": {
                                "x": 1830,
                                "y": 2214,
                                "w": 82,
                                "h": 82
                            },
                            "345": {
                                "x": 1912,
                                "y": 2214,
                                "w": 88,
                                "h": 88
                            }
                        }
                    ]
                },
                "A225": {
                    "rotates": true,
                    "aliases": [],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 2337,
                                "w": 88,
                                "h": 88
                            },
                            "15": {
                                "x": 88,
                                "y": 2337,
                                "w": 87,
                                "h": 87
                            },
                            "30": {
                                "x": 175,
                                "y": 2337,
                                "w": 82,
                                "h": 82
                            },
                            "45": {
                                "x": 257,
                                "y": 2337,
                                "w": 77,
                                "h": 77
                            },
                            "60": {
                                "x": 334,
                                "y": 2337,
                                "w": 85,
                                "h": 85
                            },
                            "75": {
                                "x": 419,
                                "y": 2337,
                                "w": 90,
                                "h": 90
                            },
                            "90": {
                                "x": 509,
                                "y": 2337,
                                "w": 88,
                                "h": 88
                            },
                            "105": {
                                "x": 597,
                                "y": 2337,
                                "w": 89,
                                "h": 89
                            },
                            "120": {
                                "x": 686,
                                "y": 2337,
                                "w": 85,
                                "h": 85
                            },
                            "135": {
                                "x": 771,
                                "y": 2337,
                                "w": 77,
                                "h": 77
                            },
                            "150": {
                                "x": 848,
                                "y": 2337,
                                "w": 82,
                                "h": 82
                            },
                            "165": {
                                "x": 930,
                                "y": 2337,
                                "w": 87,
                                "h": 87
                            },
                            "180": {
                                "x": 1017,
                                "y": 2337,
                                "w": 88,
                                "h": 88
                            },
                            "195": {
                                "x": 1105,
                                "y": 2337,
                                "w": 87,
                                "h": 87
                            },
                            "210": {
                                "x": 1192,
                                "y": 2337,
                                "w": 82,
                                "h": 82
                            },
                            "225": {
                                "x": 1274,
                                "y": 2337,
                                "w": 77,
                                "h": 77
                            },
                            "240": {
                                "x": 1351,
                                "y": 2337,
                                "w": 85,
                                "h": 85
                            },
                            "255": {
                                "x": 1436,
                                "y": 2337,
                                "w": 90,
                                "h": 90
                            },
                            "270": {
                                "x": 1526,
                                "y": 2337,
                                "w": 88,
                                "h": 88
                            },
                            "285": {
                                "x": 1614,
                                "y": 2337,
                                "w": 89,
                                "h": 89
                            },
                            "300": {
                                "x": 1703,
                                "y": 2337,
                                "w": 85,
                                "h": 85
                            },
                            "315": {
                                "x": 1788,
                                "y": 2337,
                                "w": 77,
                                "h": 77
                            },
                            "330": {
                                "x": 1865,
                                "y": 2337,
                                "w": 82,
                                "h": 82
                            },
                            "345": {
                                "x": 1947,
                                "y": 2337,
                                "w": 87,
                                "h": 87
                            }
                        }
                    ]
                },
                "SI2": {
                    "rotates": true,
                    "aliases": [
                        "SOL2"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 2460,
                                "w": 88,
                                "h": 88
                            },
                            "15": {
                                "x": 88,
                                "y": 2460,
                                "w": 88,
                                "h": 88
                            },
                            "30": {
                                "x": 176,
                                "y": 2460,
                                "w": 82,
                                "h": 82
                            },
                            "45": {
                                "x": 258,
                                "y": 2460,
                                "w": 72,
                                "h": 72
                            },
                            "60": {
                                "x": 330,
                                "y": 2460,
                                "w": 57,
                                "h": 57
                            },
                            "75": {
                                "x": 387,
                                "y": 2460,
                                "w": 49,
                                "h": 49
                            },
                            "90": {
                                "x": 436,
                                "y": 2460,
                                "w": 45,
                                "h": 45
                            },
                            "105": {
                                "x": 481,
                                "y": 2460,
                                "w": 49,
                                "h": 49
                            },
                            "120": {
                                "x": 530,
                                "y": 2460,
                                "w": 57,
                                "h": 57
                            },
                            "135": {
                                "x": 587,
                                "y": 2460,
                                "w": 72,
                                "h": 72
                            },
                            "150": {
                                "x": 659,
                                "y": 2460,
                                "w": 82,
                                "h": 82
                            },
                            "165": {
                                "x": 741,
                                "y": 2460,
                                "w": 88,
                                "h": 88
                            },
                            "180": {
                                "x": 829,
                                "y": 2460,
                                "w": 88,
                                "h": 88
                            },
                            "195": {
                                "x": 917,
                                "y": 2460,
                                "w": 88,
                                "h": 88
                            },
                            "210": {
                                "x": 1005,
                                "y": 2460,
                                "w": 82,
                                "h": 82
                            },
                            "225": {
                                "x": 1087,
                                "y": 2460,
                                "w": 72,
                                "h": 72
                            },
                            "240": {
                                "x": 1159,
                                "y": 2460,
                                "w": 57,
                                "h": 57
                            },
                            "255": {
                                "x": 1216,
                                "y": 2460,
                                "w": 49,
                                "h": 49
                            },
                            "270": {
                                "x": 1265,
                                "y": 2460,
                                "w": 45,
                                "h": 45
                            },
                            "285": {
                                "x": 1310,
                                "y": 2460,
                                "w": 49,
                                "h": 49
                            },
                            "300": {
                                "x": 1359,
                                "y": 2460,
                                "w": 57,
                                "h": 57
                            },
                            "315": {
                                "x": 1416,
                                "y": 2460,
                                "w": 72,
                                "h": 72
                            },
                            "330": {
                                "x": 1488,
                                "y": 2460,
                                "w": 82,
                                "h": 82
                            },
                            "345": {
                                "x": 1570,
                                "y": 2460,
                                "w": 88,
                                "h": 88
                            }
                        }
                    ]
                },
                "EC": {
                    "rotates": true,
                    "aliases": [
                        "A002",
                        "A109",
                        "A119",
                        "A129",
                        "A139",
                        "A149",
                        "A169",
                        "A189",
                        "ALH",
                        "ALO2",
                        "ALO3",
                        "AS32",
                        "AS3B",
                        "AS50",
                        "AS55",
                        "AS65",
                        "B06",
                        "B06T",
                        "B105",
                        "B212",
                        "B222",
                        "B230",
                        "B407",
                        "B412",
                        "B427",
                        "B429",
                        "B430",
                        "B47G",
                        "B47J",
                        "BK17",
                        "BSTP",
                        "EC20",
                        "EC25",
                        "EC30",
                        "EC35",
                        "EC45",
                        "EC55",
                        "EC75",
                        "EH10",
                        "EXPL",
                        "FREL",
                        "GAZL",
                        "H2",
                        "H269",
                        "H47",
                        "H500",
                        "H53",
                        "H53S",
                        "H60",
                        "H64",
                        "HUCO",
                        "KA32",
                        "KA50",
                        "KA52",
                        "KMAX",
                        "LAMA",
                        "LYNX",
                        "MI26",
                        "MI38",
                        "MI8",
                        "NH90",
                        "OH1",
                        "PUMA",
                        "R22",
                        "R44",
                        "R66",
                        "RVAL",
                        "S61",
                        "S61R",
                        "S76",
                        "S92",
                        "SUCO",
                        "TIGR",
                        "UH1",
                        "UH1Y",
                        "V22",
                        "B505",
                        "G2CA",
                        "GYRO",
                        "H160",
                        "CDUS",
                        "MM24",
                        "EXEC",
                        "CLON",
                        "S64",
                        "EN48",
                        "B17",
                        "W3",
                        "S330",
                        "H46",
                        "MD60",
                        "B214",
                        "EN28",
                        "MD52"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 2583,
                                "w": 88,
                                "h": 88
                            },
                            "15": {
                                "x": 88,
                                "y": 2583,
                                "w": 108,
                                "h": 108
                            },
                            "30": {
                                "x": 196,
                                "y": 2583,
                                "w": 120,
                                "h": 120
                            },
                            "45": {
                                "x": 316,
                                "y": 2583,
                                "w": 124,
                                "h": 124
                            },
                            "60": {
                                "x": 440,
                                "y": 2583,
                                "w": 120,
                                "h": 120
                            },
                            "75": {
                                "x": 560,
                                "y": 2583,
                                "w": 108,
                                "h": 108
                            },
                            "90": {
                                "x": 668,
                                "y": 2583,
                                "w": 88,
                                "h": 88
                            },
                            "105": {
                                "x": 756,
                                "y": 2583,
                                "w": 108,
                                "h": 108
                            },
                            "120": {
                                "x": 864,
                                "y": 2583,
                                "w": 120,
                                "h": 120
                            },
                            "135": {
                                "x": 984,
                                "y": 2583,
                                "w": 124,
                                "h": 124
                            },
                            "150": {
                                "x": 1108,
                                "y": 2583,
                                "w": 120,
                                "h": 120
                            },
                            "165": {
                                "x": 1228,
                                "y": 2583,
                                "w": 108,
                                "h": 108
                            },
                            "180": {
                                "x": 1336,
                                "y": 2583,
                                "w": 88,
                                "h": 88
                            },
                            "195": {
                                "x": 1424,
                                "y": 2583,
                                "w": 108,
                                "h": 108
                            },
                            "210": {
                                "x": 1532,
                                "y": 2583,
                                "w": 120,
                                "h": 120
                            },
                            "225": {
                                "x": 1652,
                                "y": 2583,
                                "w": 124,
                                "h": 124
                            },
                            "240": {
                                "x": 1776,
                                "y": 2583,
                                "w": 120,
                                "h": 120
                            },
                            "255": {
                                "x": 1896,
                                "y": 2583,
                                "w": 108,
                                "h": 108
                            },
                            "270": {
                                "x": 2004,
                                "y": 2583,
                                "w": 88,
                                "h": 88
                            },
                            "285": {
                                "x": 2092,
                                "y": 2583,
                                "w": 108,
                                "h": 108
                            },
                            "300": {
                                "x": 2200,
                                "y": 2583,
                                "w": 120,
                                "h": 120
                            },
                            "315": {
                                "x": 2320,
                                "y": 2583,
                                "w": 124,
                                "h": 124
                            },
                            "330": {
                                "x": 2444,
                                "y": 2583,
                                "w": 120,
                                "h": 120
                            },
                            "345": {
                                "x": 2564,
                                "y": 2583,
                                "w": 108,
                                "h": 108
                            }
                        },
                        {
                            "0": {
                                "x": 0,
                                "y": 2706,
                                "w": 88,
                                "h": 88
                            },
                            "15": {
                                "x": 88,
                                "y": 2706,
                                "w": 108,
                                "h": 108
                            },
                            "30": {
                                "x": 196,
                                "y": 2706,
                                "w": 120,
                                "h": 120
                            },
                            "45": {
                                "x": 316,
                                "y": 2706,
                                "w": 124,
                                "h": 124
                            },
                            "60": {
                                "x": 440,
                                "y": 2706,
                                "w": 120,
                                "h": 120
                            },
                            "75": {
                                "x": 560,
                                "y": 2706,
                                "w": 108,
                                "h": 108
                            },
                            "90": {
                                "x": 668,
                                "y": 2706,
                                "w": 88,
                                "h": 88
                            },
                            "105": {
                                "x": 756,
                                "y": 2706,
                                "w": 108,
                                "h": 108
                            },
                            "120": {
                                "x": 864,
                                "y": 2706,
                                "w": 120,
                                "h": 120
                            },
                            "135": {
                                "x": 984,
                                "y": 2706,
                                "w": 124,
                                "h": 124
                            },
                            "150": {
                                "x": 1108,
                                "y": 2706,
                                "w": 120,
                                "h": 120
                            },
                            "165": {
                                "x": 1228,
                                "y": 2706,
                                "w": 108,
                                "h": 108
                            },
                            "180": {
                                "x": 1336,
                                "y": 2706,
                                "w": 88,
                                "h": 88
                            },
                            "195": {
                                "x": 1424,
                                "y": 2706,
                                "w": 108,
                                "h": 108
                            },
                            "210": {
                                "x": 1532,
                                "y": 2706,
                                "w": 120,
                                "h": 120
                            },
                            "225": {
                                "x": 1652,
                                "y": 2706,
                                "w": 124,
                                "h": 124
                            },
                            "240": {
                                "x": 1776,
                                "y": 2706,
                                "w": 120,
                                "h": 120
                            },
                            "255": {
                                "x": 1896,
                                "y": 2706,
                                "w": 108,
                                "h": 108
                            },
                            "270": {
                                "x": 2004,
                                "y": 2706,
                                "w": 88,
                                "h": 88
                            },
                            "285": {
                                "x": 2092,
                                "y": 2706,
                                "w": 108,
                                "h": 108
                            },
                            "300": {
                                "x": 2200,
                                "y": 2706,
                                "w": 120,
                                "h": 120
                            },
                            "315": {
                                "x": 2320,
                                "y": 2706,
                                "w": 124,
                                "h": 124
                            },
                            "330": {
                                "x": 2444,
                                "y": 2706,
                                "w": 120,
                                "h": 120
                            },
                            "345": {
                                "x": 2564,
                                "y": 2706,
                                "w": 108,
                                "h": 108
                            }
                        }
                    ]
                },
                "BALL": {
                    "rotates": false,
                    "aliases": [
                        "LOON",
                        "SHIP"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 2829,
                                "w": 59,
                                "h": 59
                            }
                        }
                    ]
                },
                "GRND": {
                    "rotates": true,
                    "aliases": [],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 2952,
                                "w": 34,
                                "h": 34
                            },
                            "15": {
                                "x": 34,
                                "y": 2952,
                                "w": 41,
                                "h": 41
                            },
                            "30": {
                                "x": 75,
                                "y": 2952,
                                "w": 47,
                                "h": 47
                            },
                            "45": {
                                "x": 122,
                                "y": 2952,
                                "w": 50,
                                "h": 50
                            },
                            "60": {
                                "x": 172,
                                "y": 2952,
                                "w": 52,
                                "h": 52
                            },
                            "75": {
                                "x": 224,
                                "y": 2952,
                                "w": 51,
                                "h": 51
                            },
                            "90": {
                                "x": 275,
                                "y": 2952,
                                "w": 48,
                                "h": 48
                            },
                            "105": {
                                "x": 323,
                                "y": 2952,
                                "w": 51,
                                "h": 51
                            },
                            "120": {
                                "x": 374,
                                "y": 2952,
                                "w": 52,
                                "h": 52
                            },
                            "135": {
                                "x": 426,
                                "y": 2952,
                                "w": 50,
                                "h": 50
                            },
                            "150": {
                                "x": 476,
                                "y": 2952,
                                "w": 47,
                                "h": 47
                            },
                            "165": {
                                "x": 523,
                                "y": 2952,
                                "w": 41,
                                "h": 41
                            },
                            "180": {
                                "x": 564,
                                "y": 2952,
                                "w": 34,
                                "h": 34
                            },
                            "195": {
                                "x": 598,
                                "y": 2952,
                                "w": 41,
                                "h": 41
                            },
                            "210": {
                                "x": 639,
                                "y": 2952,
                                "w": 47,
                                "h": 47
                            },
                            "225": {
                                "x": 686,
                                "y": 2952,
                                "w": 50,
                                "h": 50
                            },
                            "240": {
                                "x": 736,
                                "y": 2952,
                                "w": 52,
                                "h": 52
                            },
                            "255": {
                                "x": 788,
                                "y": 2952,
                                "w": 51,
                                "h": 51
                            },
                            "270": {
                                "x": 839,
                                "y": 2952,
                                "w": 48,
                                "h": 48
                            },
                            "285": {
                                "x": 887,
                                "y": 2952,
                                "w": 51,
                                "h": 51
                            },
                            "300": {
                                "x": 938,
                                "y": 2952,
                                "w": 52,
                                "h": 52
                            },
                            "315": {
                                "x": 990,
                                "y": 2952,
                                "w": 50,
                                "h": 50
                            },
                            "330": {
                                "x": 1040,
                                "y": 2952,
                                "w": 47,
                                "h": 47
                            },
                            "345": {
                                "x": 1087,
                                "y": 2952,
                                "w": 41,
                                "h": 41
                            }
                        }
                    ]
                },
                "SLEI": {
                    "rotates": true,
                    "aliases": [],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 3075,
                                "w": 88,
                                "h": 88
                            },
                            "15": {
                                "x": 88,
                                "y": 3075,
                                "w": 108,
                                "h": 108
                            },
                            "30": {
                                "x": 196,
                                "y": 3075,
                                "w": 120,
                                "h": 120
                            },
                            "45": {
                                "x": 316,
                                "y": 3075,
                                "w": 124,
                                "h": 124
                            },
                            "60": {
                                "x": 440,
                                "y": 3075,
                                "w": 120,
                                "h": 120
                            },
                            "75": {
                                "x": 560,
                                "y": 3075,
                                "w": 108,
                                "h": 108
                            },
                            "90": {
                                "x": 668,
                                "y": 3075,
                                "w": 88,
                                "h": 88
                            },
                            "105": {
                                "x": 756,
                                "y": 3075,
                                "w": 108,
                                "h": 108
                            },
                            "120": {
                                "x": 864,
                                "y": 3075,
                                "w": 120,
                                "h": 120
                            },
                            "135": {
                                "x": 984,
                                "y": 3075,
                                "w": 124,
                                "h": 124
                            },
                            "150": {
                                "x": 1108,
                                "y": 3075,
                                "w": 120,
                                "h": 120
                            },
                            "165": {
                                "x": 1228,
                                "y": 3075,
                                "w": 108,
                                "h": 108
                            },
                            "180": {
                                "x": 1336,
                                "y": 3075,
                                "w": 88,
                                "h": 88
                            },
                            "195": {
                                "x": 1424,
                                "y": 3075,
                                "w": 108,
                                "h": 108
                            },
                            "210": {
                                "x": 1532,
                                "y": 3075,
                                "w": 120,
                                "h": 120
                            },
                            "225": {
                                "x": 1652,
                                "y": 3075,
                                "w": 124,
                                "h": 124
                            },
                            "240": {
                                "x": 1776,
                                "y": 3075,
                                "w": 120,
                                "h": 120
                            },
                            "255": {
                                "x": 1896,
                                "y": 3075,
                                "w": 108,
                                "h": 108
                            },
                            "270": {
                                "x": 2004,
                                "y": 3075,
                                "w": 88,
                                "h": 88
                            },
                            "285": {
                                "x": 2092,
                                "y": 3075,
                                "w": 108,
                                "h": 108
                            },
                            "300": {
                                "x": 2200,
                                "y": 3075,
                                "w": 120,
                                "h": 120
                            },
                            "315": {
                                "x": 2320,
                                "y": 3075,
                                "w": 124,
                                "h": 124
                            },
                            "330": {
                                "x": 2444,
                                "y": 3075,
                                "w": 120,
                                "h": 120
                            },
                            "345": {
                                "x": 2564,
                                "y": 3075,
                                "w": 108,
                                "h": 108
                            }
                        },
                        {
                            "0": {
                                "x": 0,
                                "y": 3198,
                                "w": 88,
                                "h": 88
                            },
                            "15": {
                                "x": 88,
                                "y": 3198,
                                "w": 108,
                                "h": 108
                            },
                            "30": {
                                "x": 196,
                                "y": 3198,
                                "w": 120,
                                "h": 120
                            },
                            "45": {
                                "x": 316,
                                "y": 3198,
                                "w": 124,
                                "h": 124
                            },
                            "60": {
                                "x": 440,
                                "y": 3198,
                                "w": 120,
                                "h": 120
                            },
                            "75": {
                                "x": 560,
                                "y": 3198,
                                "w": 108,
                                "h": 108
                            },
                            "90": {
                                "x": 668,
                                "y": 3198,
                                "w": 88,
                                "h": 88
                            },
                            "105": {
                                "x": 756,
                                "y": 3198,
                                "w": 108,
                                "h": 108
                            },
                            "120": {
                                "x": 864,
                                "y": 3198,
                                "w": 120,
                                "h": 120
                            },
                            "135": {
                                "x": 984,
                                "y": 3198,
                                "w": 124,
                                "h": 124
                            },
                            "150": {
                                "x": 1108,
                                "y": 3198,
                                "w": 120,
                                "h": 120
                            },
                            "165": {
                                "x": 1228,
                                "y": 3198,
                                "w": 108,
                                "h": 108
                            },
                            "180": {
                                "x": 1336,
                                "y": 3198,
                                "w": 88,
                                "h": 88
                            },
                            "195": {
                                "x": 1424,
                                "y": 3198,
                                "w": 108,
                                "h": 108
                            },
                            "210": {
                                "x": 1532,
                                "y": 3198,
                                "w": 120,
                                "h": 120
                            },
                            "225": {
                                "x": 1652,
                                "y": 3198,
                                "w": 124,
                                "h": 124
                            },
                            "240": {
                                "x": 1776,
                                "y": 3198,
                                "w": 120,
                                "h": 120
                            },
                            "255": {
                                "x": 1896,
                                "y": 3198,
                                "w": 108,
                                "h": 108
                            },
                            "270": {
                                "x": 2004,
                                "y": 3198,
                                "w": 88,
                                "h": 88
                            },
                            "285": {
                                "x": 2092,
                                "y": 3198,
                                "w": 108,
                                "h": 108
                            },
                            "300": {
                                "x": 2200,
                                "y": 3198,
                                "w": 120,
                                "h": 120
                            },
                            "315": {
                                "x": 2320,
                                "y": 3198,
                                "w": 124,
                                "h": 124
                            },
                            "330": {
                                "x": 2444,
                                "y": 3198,
                                "w": 120,
                                "h": 120
                            },
                            "345": {
                                "x": 2564,
                                "y": 3198,
                                "w": 108,
                                "h": 108
                            }
                        }
                    ]
                },
                "DRON": {
                    "rotates": true,
                    "aliases": [],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 3321,
                                "w": 56,
                                "h": 56
                            },
                            "15": {
                                "x": 56,
                                "y": 3321,
                                "w": 58,
                                "h": 58
                            },
                            "30": {
                                "x": 114,
                                "y": 3321,
                                "w": 56,
                                "h": 56
                            },
                            "45": {
                                "x": 170,
                                "y": 3321,
                                "w": 52,
                                "h": 52
                            },
                            "60": {
                                "x": 222,
                                "y": 3321,
                                "w": 56,
                                "h": 56
                            },
                            "75": {
                                "x": 278,
                                "y": 3321,
                                "w": 58,
                                "h": 58
                            },
                            "90": {
                                "x": 336,
                                "y": 3321,
                                "w": 56,
                                "h": 56
                            },
                            "105": {
                                "x": 392,
                                "y": 3321,
                                "w": 58,
                                "h": 58
                            },
                            "120": {
                                "x": 450,
                                "y": 3321,
                                "w": 56,
                                "h": 56
                            },
                            "135": {
                                "x": 506,
                                "y": 3321,
                                "w": 52,
                                "h": 52
                            },
                            "150": {
                                "x": 558,
                                "y": 3321,
                                "w": 56,
                                "h": 56
                            },
                            "165": {
                                "x": 614,
                                "y": 3321,
                                "w": 58,
                                "h": 58
                            },
                            "180": {
                                "x": 672,
                                "y": 3321,
                                "w": 56,
                                "h": 56
                            },
                            "195": {
                                "x": 728,
                                "y": 3321,
                                "w": 58,
                                "h": 58
                            },
                            "210": {
                                "x": 786,
                                "y": 3321,
                                "w": 56,
                                "h": 56
                            },
                            "225": {
                                "x": 842,
                                "y": 3321,
                                "w": 52,
                                "h": 52
                            },
                            "240": {
                                "x": 894,
                                "y": 3321,
                                "w": 56,
                                "h": 56
                            },
                            "255": {
                                "x": 950,
                                "y": 3321,
                                "w": 58,
                                "h": 58
                            },
                            "270": {
                                "x": 1008,
                                "y": 3321,
                                "w": 56,
                                "h": 56
                            },
                            "285": {
                                "x": 1064,
                                "y": 3321,
                                "w": 58,
                                "h": 58
                            },
                            "300": {
                                "x": 1122,
                                "y": 3321,
                                "w": 56,
                                "h": 56
                            },
                            "315": {
                                "x": 1178,
                                "y": 3321,
                                "w": 52,
                                "h": 52
                            },
                            "330": {
                                "x": 1230,
                                "y": 3321,
                                "w": 56,
                                "h": 56
                            },
                            "345": {
                                "x": 1286,
                                "y": 3321,
                                "w": 58,
                                "h": 58
                            }
                        }
                    ]
                },
                "SAT": {
                    "rotates": true,
                    "aliases": [],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 3444,
                                "w": 72,
                                "h": 72
                            },
                            "15": {
                                "x": 72,
                                "y": 3444,
                                "w": 72,
                                "h": 72
                            },
                            "30": {
                                "x": 144,
                                "y": 3444,
                                "w": 68,
                                "h": 68
                            },
                            "45": {
                                "x": 212,
                                "y": 3444,
                                "w": 60,
                                "h": 60
                            },
                            "60": {
                                "x": 272,
                                "y": 3444,
                                "w": 50,
                                "h": 50
                            },
                            "75": {
                                "x": 322,
                                "y": 3444,
                                "w": 36,
                                "h": 36
                            },
                            "90": {
                                "x": 358,
                                "y": 3444,
                                "w": 26,
                                "h": 26
                            },
                            "105": {
                                "x": 384,
                                "y": 3444,
                                "w": 36,
                                "h": 36
                            },
                            "120": {
                                "x": 420,
                                "y": 3444,
                                "w": 50,
                                "h": 50
                            },
                            "135": {
                                "x": 470,
                                "y": 3444,
                                "w": 60,
                                "h": 60
                            },
                            "150": {
                                "x": 530,
                                "y": 3444,
                                "w": 68,
                                "h": 68
                            },
                            "165": {
                                "x": 598,
                                "y": 3444,
                                "w": 72,
                                "h": 72
                            },
                            "180": {
                                "x": 670,
                                "y": 3444,
                                "w": 72,
                                "h": 72
                            },
                            "195": {
                                "x": 742,
                                "y": 3444,
                                "w": 72,
                                "h": 72
                            },
                            "210": {
                                "x": 814,
                                "y": 3444,
                                "w": 68,
                                "h": 68
                            },
                            "225": {
                                "x": 882,
                                "y": 3444,
                                "w": 60,
                                "h": 60
                            },
                            "240": {
                                "x": 942,
                                "y": 3444,
                                "w": 50,
                                "h": 50
                            },
                            "255": {
                                "x": 992,
                                "y": 3444,
                                "w": 36,
                                "h": 36
                            },
                            "270": {
                                "x": 1028,
                                "y": 3444,
                                "w": 26,
                                "h": 26
                            },
                            "285": {
                                "x": 1054,
                                "y": 3444,
                                "w": 36,
                                "h": 36
                            },
                            "300": {
                                "x": 1090,
                                "y": 3444,
                                "w": 50,
                                "h": 50
                            },
                            "315": {
                                "x": 1140,
                                "y": 3444,
                                "w": 60,
                                "h": 60
                            },
                            "330": {
                                "x": 1200,
                                "y": 3444,
                                "w": 68,
                                "h": 68
                            },
                            "345": {
                                "x": 1268,
                                "y": 3444,
                                "w": 72,
                                "h": 72
                            }
                        }
                    ]
                },
                "ISS": {
                    "rotates": true,
                    "aliases": [],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 3567,
                                "w": 68,
                                "h": 68
                            },
                            "15": {
                                "x": 68,
                                "y": 3567,
                                "w": 75,
                                "h": 75
                            },
                            "30": {
                                "x": 143,
                                "y": 3567,
                                "w": 80,
                                "h": 80
                            },
                            "45": {
                                "x": 223,
                                "y": 3567,
                                "w": 79,
                                "h": 79
                            },
                            "60": {
                                "x": 302,
                                "y": 3567,
                                "w": 76,
                                "h": 76
                            },
                            "75": {
                                "x": 378,
                                "y": 3567,
                                "w": 68,
                                "h": 68
                            },
                            "90": {
                                "x": 446,
                                "y": 3567,
                                "w": 56,
                                "h": 56
                            },
                            "105": {
                                "x": 502,
                                "y": 3567,
                                "w": 68,
                                "h": 68
                            },
                            "120": {
                                "x": 570,
                                "y": 3567,
                                "w": 76,
                                "h": 76
                            },
                            "135": {
                                "x": 646,
                                "y": 3567,
                                "w": 79,
                                "h": 79
                            },
                            "150": {
                                "x": 725,
                                "y": 3567,
                                "w": 80,
                                "h": 80
                            },
                            "165": {
                                "x": 805,
                                "y": 3567,
                                "w": 75,
                                "h": 75
                            },
                            "180": {
                                "x": 880,
                                "y": 3567,
                                "w": 68,
                                "h": 68
                            },
                            "195": {
                                "x": 948,
                                "y": 3567,
                                "w": 75,
                                "h": 75
                            },
                            "210": {
                                "x": 1023,
                                "y": 3567,
                                "w": 80,
                                "h": 80
                            },
                            "225": {
                                "x": 1103,
                                "y": 3567,
                                "w": 79,
                                "h": 79
                            },
                            "240": {
                                "x": 1182,
                                "y": 3567,
                                "w": 76,
                                "h": 76
                            },
                            "255": {
                                "x": 1258,
                                "y": 3567,
                                "w": 68,
                                "h": 68
                            },
                            "270": {
                                "x": 1326,
                                "y": 3567,
                                "w": 56,
                                "h": 56
                            },
                            "285": {
                                "x": 1382,
                                "y": 3567,
                                "w": 68,
                                "h": 68
                            },
                            "300": {
                                "x": 1450,
                                "y": 3567,
                                "w": 76,
                                "h": 76
                            },
                            "315": {
                                "x": 1526,
                                "y": 3567,
                                "w": 79,
                                "h": 79
                            },
                            "330": {
                                "x": 1605,
                                "y": 3567,
                                "w": 80,
                                "h": 80
                            },
                            "345": {
                                "x": 1685,
                                "y": 3567,
                                "w": 75,
                                "h": 75
                            }
                        }
                    ]
                }
            }
        },
        plane35: {
            "rotationDegrees": 15,
            "url": "1658913471/t-sprite_c-yellow_w-35_s-yes.png",
            "w": 1192,
            "h": 1650,
            "icons": {
                "FGTR": {
                    "rotates": true,
                    "aliases": [
                        "A4",
                        "EUFI",
                        "F16",
                        "F18",
                        "F18C",
                        "F18D",
                        "FGR4",
                        "HAWK",
                        "HUNT",
                        "SB05",
                        "SB39",
                        "TOR",
                        "L39",
                        "E530",
                        "M346",
                        "AJET",
                        "AMX",
                        "T38",
                        "U2",
                        "M339",
                        "CORS",
                        "P51",
                        "F15",
                        "L159",
                        "SB32"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 0,
                                "w": 28,
                                "h": 28
                            },
                            "15": {
                                "x": 28,
                                "y": 0,
                                "w": 27,
                                "h": 27
                            },
                            "30": {
                                "x": 55,
                                "y": 0,
                                "w": 27,
                                "h": 27
                            },
                            "45": {
                                "x": 82,
                                "y": 0,
                                "w": 29,
                                "h": 29
                            },
                            "60": {
                                "x": 111,
                                "y": 0,
                                "w": 29,
                                "h": 29
                            },
                            "75": {
                                "x": 140,
                                "y": 0,
                                "w": 29,
                                "h": 29
                            },
                            "90": {
                                "x": 169,
                                "y": 0,
                                "w": 28,
                                "h": 28
                            },
                            "105": {
                                "x": 197,
                                "y": 0,
                                "w": 29,
                                "h": 29
                            },
                            "120": {
                                "x": 226,
                                "y": 0,
                                "w": 30,
                                "h": 30
                            },
                            "135": {
                                "x": 256,
                                "y": 0,
                                "w": 29,
                                "h": 29
                            },
                            "150": {
                                "x": 285,
                                "y": 0,
                                "w": 27,
                                "h": 27
                            },
                            "165": {
                                "x": 312,
                                "y": 0,
                                "w": 27,
                                "h": 27
                            },
                            "180": {
                                "x": 339,
                                "y": 0,
                                "w": 28,
                                "h": 28
                            },
                            "195": {
                                "x": 367,
                                "y": 0,
                                "w": 27,
                                "h": 27
                            },
                            "210": {
                                "x": 394,
                                "y": 0,
                                "w": 27,
                                "h": 27
                            },
                            "225": {
                                "x": 421,
                                "y": 0,
                                "w": 29,
                                "h": 29
                            },
                            "240": {
                                "x": 450,
                                "y": 0,
                                "w": 29,
                                "h": 29
                            },
                            "255": {
                                "x": 479,
                                "y": 0,
                                "w": 29,
                                "h": 29
                            },
                            "270": {
                                "x": 508,
                                "y": 0,
                                "w": 28,
                                "h": 28
                            },
                            "285": {
                                "x": 536,
                                "y": 0,
                                "w": 29,
                                "h": 29
                            },
                            "300": {
                                "x": 565,
                                "y": 0,
                                "w": 30,
                                "h": 30
                            },
                            "315": {
                                "x": 595,
                                "y": 0,
                                "w": 29,
                                "h": 29
                            },
                            "330": {
                                "x": 624,
                                "y": 0,
                                "w": 27,
                                "h": 27
                            },
                            "345": {
                                "x": 651,
                                "y": 0,
                                "w": 27,
                                "h": 27
                            }
                        }
                    ]
                },
                "AS20": {
                    "rotates": true,
                    "aliases": [
                        "GLID",
                        "G109",
                        "SF25",
                        "ULAC",
                        "VENT",
                        "DG80",
                        "SONX",
                        "DUOD",
                        "AS29",
                        "ARCP",
                        "NIMB",
                        "PISI",
                        "AS26",
                        "S10S",
                        "AS31",
                        "DG40",
                        "AS25",
                        "DG1T",
                        "LAE1",
                        "AS28",
                        "RF4",
                        "AS21",
                        "LS8"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 55,
                                "w": 28,
                                "h": 28
                            },
                            "15": {
                                "x": 28,
                                "y": 55,
                                "w": 29,
                                "h": 29
                            },
                            "30": {
                                "x": 57,
                                "y": 55,
                                "w": 27,
                                "h": 27
                            },
                            "45": {
                                "x": 84,
                                "y": 55,
                                "w": 25,
                                "h": 25
                            },
                            "60": {
                                "x": 109,
                                "y": 55,
                                "w": 21,
                                "h": 21
                            },
                            "75": {
                                "x": 130,
                                "y": 55,
                                "w": 18,
                                "h": 18
                            },
                            "90": {
                                "x": 148,
                                "y": 55,
                                "w": 18,
                                "h": 18
                            },
                            "105": {
                                "x": 166,
                                "y": 55,
                                "w": 18,
                                "h": 18
                            },
                            "120": {
                                "x": 184,
                                "y": 55,
                                "w": 20,
                                "h": 20
                            },
                            "135": {
                                "x": 204,
                                "y": 55,
                                "w": 24,
                                "h": 24
                            },
                            "150": {
                                "x": 228,
                                "y": 55,
                                "w": 28,
                                "h": 28
                            },
                            "165": {
                                "x": 256,
                                "y": 55,
                                "w": 29,
                                "h": 29
                            },
                            "180": {
                                "x": 285,
                                "y": 55,
                                "w": 28,
                                "h": 28
                            },
                            "195": {
                                "x": 313,
                                "y": 55,
                                "w": 29,
                                "h": 29
                            },
                            "210": {
                                "x": 342,
                                "y": 55,
                                "w": 27,
                                "h": 27
                            },
                            "225": {
                                "x": 369,
                                "y": 55,
                                "w": 25,
                                "h": 25
                            },
                            "240": {
                                "x": 394,
                                "y": 55,
                                "w": 21,
                                "h": 21
                            },
                            "255": {
                                "x": 415,
                                "y": 55,
                                "w": 18,
                                "h": 18
                            },
                            "270": {
                                "x": 433,
                                "y": 55,
                                "w": 18,
                                "h": 18
                            },
                            "285": {
                                "x": 451,
                                "y": 55,
                                "w": 18,
                                "h": 18
                            },
                            "300": {
                                "x": 469,
                                "y": 55,
                                "w": 20,
                                "h": 20
                            },
                            "315": {
                                "x": 489,
                                "y": 55,
                                "w": 24,
                                "h": 24
                            },
                            "330": {
                                "x": 513,
                                "y": 55,
                                "w": 28,
                                "h": 28
                            },
                            "345": {
                                "x": 541,
                                "y": 55,
                                "w": 29,
                                "h": 29
                            }
                        }
                    ]
                },
                "C206": {
                    "rotates": true,
                    "aliases": [
                        "A210",
                        "AA5",
                        "AA5B",
                        "AC11",
                        "AP22",
                        "B36T",
                        "BE19",
                        "BE23",
                        "BE24",
                        "BE33",
                        "BE35",
                        "BE36",
                        "BL8",
                        "BL17",
                        "BREZ",
                        "C10T",
                        "C42",
                        "C72R",
                        "C77R",
                        "C82R",
                        "C82S",
                        "C150",
                        "C152",
                        "C160",
                        "C162",
                        "C170",
                        "C172",
                        "C175",
                        "C177",
                        "C180",
                        "C182",
                        "C185",
                        "C195",
                        "C205",
                        "C207",
                        "C208",
                        "C210",
                        "C240",
                        "C337",
                        "CH7A",
                        "CH60",
                        "CO6T",
                        "COL3",
                        "COL4",
                        "CRUZ",
                        "DA40",
                        "DR30",
                        "DR40",
                        "DV20",
                        "EFOX",
                        "EPIC",
                        "EVOL",
                        "EVOP",
                        "EVOT",
                        "EV97",
                        "EXPR",
                        "FBA2",
                        "FDCT",
                        "FOX",
                        "F8L",
                        "GA8",
                        "GLAS",
                        "GP4",
                        "G58",
                        "G115",
                        "HROC",
                        "HR10",
                        "HUSK",
                        "JAB2",
                        "JAB4",
                        "KODI",
                        "LA25",
                        "LNC2",
                        "LNC4",
                        "LNCE",
                        "M20P",
                        "M20T",
                        "MO20",
                        "NAVI",
                        "PA14",
                        "PA18",
                        "PA20",
                        "PA22",
                        "PA24",
                        "PA25",
                        "PA28",
                        "PA32",
                        "PA38",
                        "PA46",
                        "PC6T",
                        "PC12",
                        "PC21",
                        "PTS2",
                        "PUP",
                        "P28",
                        "P28A",
                        "P28B",
                        "P28R",
                        "P28T",
                        "P28U",
                        "P32R",
                        "P32T",
                        "P46T",
                        "P208",
                        "P210",
                        "P750",
                        "RV6",
                        "RV7",
                        "RV8",
                        "RV9",
                        "RV10",
                        "RV12",
                        "S22T",
                        "S208",
                        "SIRA",
                        "SM92",
                        "SR20",
                        "SR22",
                        "SYMP",
                        "TAMP",
                        "TBM7",
                        "TBM8",
                        "TB20",
                        "TRIN",
                        "Z42",
                        "D400",
                        "DA20",
                        "DIMO",
                        "ECHO",
                        "EVSS",
                        "GLST",
                        "PZ3T",
                        "TL20",
                        "WT9",
                        "E200",
                        "WA50",
                        "E500",
                        "M600",
                        "T206",
                        "T210",
                        "TBM9",
                        "BT36",
                        "P28S",
                        "YK50",
                        "PIVI",
                        "PC9",
                        "RV4",
                        "PC7",
                        "TB21",
                        "TOBA",
                        "AN2",
                        "AT8T",
                        "E314",
                        "HR20",
                        "DH60",
                        "G12T",
                        "RF6",
                        "DISC",
                        "M5",
                        "COY2",
                        "EUPA",
                        "AAT3",
                        "ALTO",
                        "P92",
                        "PIAT",
                        "KL07",
                        "DHC2",
                        "MF9",
                        "CH70",
                        "SU26",
                        "FK14",
                        "DR10",
                        "JUNR",
                        "TWEN",
                        "CH7B",
                        "T6",
                        "VL3",
                        "G140",
                        "S450",
                        "SPIT",
                        "R90R",
                        "SKRA",
                        "PNR2",
                        "CP30",
                        "BDOG",
                        "J3",
                        "C140",
                        "PA12",
                        "L8",
                        "C120",
                        "ST75",
                        "ERCO",
                        "PA11",
                        "C82T",
                        "AT5T",
                        "LGEZ",
                        "E300",
                        "M7",
                        "GC1",
                        "DHC1",
                        "PA16",
                        "AT6T",
                        "DH82",
                        "LNP4",
                        "RV3",
                        "NG5",
                        "ACAM",
                        "LEG2",
                        "D11",
                        "RALL",
                        "SREY",
                        "MT",
                        "ESCP",
                        "TF19",
                        "PTS1",
                        "CJ6",
                        "RV14",
                        "F260",
                        "P337",
                        "MM16",
                        "TEX2",
                        "SVNH",
                        "SV4",
                        "PNR3",
                        "MCR1",
                        "YK52",
                        "VTRA",
                        "AT3T",
                        "CP10",
                        "ALIG",
                        "TRF1",
                        "CH80",
                        "TL30",
                        "D140",
                        "MCR4",
                        "GX",
                        "A5",
                        "NIPR",
                        "FK9",
                        "G120",
                        "C336",
                        "CH75",
                        "IR23",
                        "D250",
                        "SLG2",
                        "D150",
                        "CT4",
                        "EAGL",
                        "SAKO",
                        "SUBA",
                        "PA17",
                        "T34P",
                        "D31",
                        "BX2",
                        "TRAL",
                        "MOR2",
                        "VEZE",
                        "BUSH",
                        "RF5",
                        "SX30",
                        "VELO",
                        "AP32",
                        "XL2",
                        "Z43",
                        "CC19",
                        "MR35",
                        "LA4",
                        "R200",
                        "WACF",
                        "AUS5",
                        "GY80",
                        "SHRK",
                        "JABI",
                        "DR22",
                        "BOLT",
                        "FAET",
                        "HUML",
                        "SB91",
                        "SKAR",
                        "PA15",
                        "AA1",
                        "SD4",
                        "T28",
                        "D253",
                        "B209",
                        "R300",
                        "TEXA",
                        "E400",
                        "J4",
                        "G3",
                        "PRIM",
                        "S108",
                        "BU31",
                        "J5",
                        "TOUR",
                        "SLG4",
                        "Z26",
                        "SEAW",
                        "MR25",
                        "DHC3",
                        "Y18T",
                        "TWST",
                        "P149",
                        "M6",
                        "PA36",
                        "D6SL",
                        "SMAX",
                        "HMNY"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 110,
                                "w": 21,
                                "h": 21
                            },
                            "15": {
                                "x": 21,
                                "y": 110,
                                "w": 22,
                                "h": 22
                            },
                            "30": {
                                "x": 43,
                                "y": 110,
                                "w": 22,
                                "h": 22
                            },
                            "45": {
                                "x": 65,
                                "y": 110,
                                "w": 21,
                                "h": 21
                            },
                            "60": {
                                "x": 86,
                                "y": 110,
                                "w": 21,
                                "h": 21
                            },
                            "75": {
                                "x": 107,
                                "y": 110,
                                "w": 20,
                                "h": 20
                            },
                            "90": {
                                "x": 127,
                                "y": 110,
                                "w": 20,
                                "h": 20
                            },
                            "105": {
                                "x": 147,
                                "y": 110,
                                "w": 20,
                                "h": 20
                            },
                            "120": {
                                "x": 167,
                                "y": 110,
                                "w": 21,
                                "h": 21
                            },
                            "135": {
                                "x": 188,
                                "y": 110,
                                "w": 21,
                                "h": 21
                            },
                            "150": {
                                "x": 209,
                                "y": 110,
                                "w": 22,
                                "h": 22
                            },
                            "165": {
                                "x": 231,
                                "y": 110,
                                "w": 22,
                                "h": 22
                            },
                            "180": {
                                "x": 253,
                                "y": 110,
                                "w": 21,
                                "h": 21
                            },
                            "195": {
                                "x": 274,
                                "y": 110,
                                "w": 22,
                                "h": 22
                            },
                            "210": {
                                "x": 296,
                                "y": 110,
                                "w": 22,
                                "h": 22
                            },
                            "225": {
                                "x": 318,
                                "y": 110,
                                "w": 21,
                                "h": 21
                            },
                            "240": {
                                "x": 339,
                                "y": 110,
                                "w": 21,
                                "h": 21
                            },
                            "255": {
                                "x": 360,
                                "y": 110,
                                "w": 20,
                                "h": 20
                            },
                            "270": {
                                "x": 380,
                                "y": 110,
                                "w": 20,
                                "h": 20
                            },
                            "285": {
                                "x": 400,
                                "y": 110,
                                "w": 20,
                                "h": 20
                            },
                            "300": {
                                "x": 420,
                                "y": 110,
                                "w": 21,
                                "h": 21
                            },
                            "315": {
                                "x": 441,
                                "y": 110,
                                "w": 21,
                                "h": 21
                            },
                            "330": {
                                "x": 462,
                                "y": 110,
                                "w": 22,
                                "h": 22
                            },
                            "345": {
                                "x": 484,
                                "y": 110,
                                "w": 22,
                                "h": 22
                            }
                        }
                    ]
                },
                "C303": {
                    "rotates": true,
                    "aliases": [
                        "AC50",
                        "AC80",
                        "AC90",
                        "AC95",
                        "AEST",
                        "BE10",
                        "BE18",
                        "BE20",
                        "BE30",
                        "BE50",
                        "BE55",
                        "BE56",
                        "BE58",
                        "BE60",
                        "BE65",
                        "BE76",
                        "BE80",
                        "BE90",
                        "BE95",
                        "BE96",
                        "BE9L",
                        "BE9T",
                        "C310",
                        "C320",
                        "C335",
                        "C340",
                        "C401",
                        "C402",
                        "C404",
                        "C414",
                        "C421",
                        "C425",
                        "C441",
                        "C21T",
                        "D28T",
                        "DA42",
                        "F406",
                        "MU2",
                        "P34A",
                        "P68",
                        "P180",
                        "PA23",
                        "PA27",
                        "PA30",
                        "PA31",
                        "PA34",
                        "PA44",
                        "PA60",
                        "PAT4",
                        "PAY1",
                        "PAY2",
                        "PAY3",
                        "PAY4",
                        "SW2",
                        "SW3",
                        "TUCA",
                        "CL2T",
                        "DA62",
                        "B58T",
                        "D28D",
                        "GA7",
                        "P06T",
                        "DH3T"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 165,
                                "w": 25,
                                "h": 25
                            },
                            "15": {
                                "x": 25,
                                "y": 165,
                                "w": 26,
                                "h": 26
                            },
                            "30": {
                                "x": 51,
                                "y": 165,
                                "w": 26,
                                "h": 26
                            },
                            "45": {
                                "x": 77,
                                "y": 165,
                                "w": 25,
                                "h": 25
                            },
                            "60": {
                                "x": 102,
                                "y": 165,
                                "w": 24,
                                "h": 24
                            },
                            "75": {
                                "x": 126,
                                "y": 165,
                                "w": 25,
                                "h": 25
                            },
                            "90": {
                                "x": 151,
                                "y": 165,
                                "w": 25,
                                "h": 25
                            },
                            "105": {
                                "x": 176,
                                "y": 165,
                                "w": 25,
                                "h": 25
                            },
                            "120": {
                                "x": 201,
                                "y": 165,
                                "w": 24,
                                "h": 24
                            },
                            "135": {
                                "x": 225,
                                "y": 165,
                                "w": 25,
                                "h": 25
                            },
                            "150": {
                                "x": 250,
                                "y": 165,
                                "w": 26,
                                "h": 26
                            },
                            "165": {
                                "x": 276,
                                "y": 165,
                                "w": 26,
                                "h": 26
                            },
                            "180": {
                                "x": 302,
                                "y": 165,
                                "w": 25,
                                "h": 25
                            },
                            "195": {
                                "x": 327,
                                "y": 165,
                                "w": 26,
                                "h": 26
                            },
                            "210": {
                                "x": 353,
                                "y": 165,
                                "w": 26,
                                "h": 26
                            },
                            "225": {
                                "x": 379,
                                "y": 165,
                                "w": 25,
                                "h": 25
                            },
                            "240": {
                                "x": 404,
                                "y": 165,
                                "w": 24,
                                "h": 24
                            },
                            "255": {
                                "x": 428,
                                "y": 165,
                                "w": 25,
                                "h": 25
                            },
                            "270": {
                                "x": 453,
                                "y": 165,
                                "w": 25,
                                "h": 25
                            },
                            "285": {
                                "x": 478,
                                "y": 165,
                                "w": 25,
                                "h": 25
                            },
                            "300": {
                                "x": 503,
                                "y": 165,
                                "w": 24,
                                "h": 24
                            },
                            "315": {
                                "x": 527,
                                "y": 165,
                                "w": 25,
                                "h": 25
                            },
                            "330": {
                                "x": 552,
                                "y": 165,
                                "w": 26,
                                "h": 26
                            },
                            "345": {
                                "x": 578,
                                "y": 165,
                                "w": 26,
                                "h": 26
                            }
                        }
                    ]
                },
                "LJ60": {
                    "rotates": true,
                    "aliases": [
                        "ASTR",
                        "BE40",
                        "C500",
                        "C501",
                        "C510",
                        "C525",
                        "C25A",
                        "C25B",
                        "C25C",
                        "C550",
                        "C551",
                        "C560",
                        "C56X",
                        "C650",
                        "C680",
                        "C750",
                        "CL30",
                        "CL35",
                        "CL60",
                        "CL64",
                        "E35L",
                        "E50P",
                        "E55P",
                        "EA50",
                        "F2TH",
                        "FA10",
                        "FA20",
                        "FA30",
                        "FA40",
                        "FA50",
                        "FA7X",
                        "F900",
                        "G150",
                        "G200",
                        "G250",
                        "G280",
                        "G550",
                        "GALX",
                        "GLF2",
                        "GLF3",
                        "GLF4",
                        "GLF5",
                        "GL5T",
                        "GLF6",
                        "GLEX",
                        "HA4T",
                        "H25B",
                        "H25C",
                        "L29B",
                        "LJ25",
                        "LJ31",
                        "LJ35",
                        "LJ36",
                        "LJ40",
                        "LJ45",
                        "LJ55",
                        "LJ70",
                        "LJ75",
                        "LJ85",
                        "LRJ",
                        "MU30",
                        "PRM1",
                        "SBR1",
                        "SBR2",
                        "WW24",
                        "FA8X",
                        "C55B",
                        "C700",
                        "E545",
                        "E550",
                        "C68A",
                        "C25M",
                        "HDJT",
                        "GA5C",
                        "GA6C",
                        "SF50",
                        "PC24",
                        "H25A",
                        "BE4W"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 220,
                                "w": 26,
                                "h": 26
                            },
                            "15": {
                                "x": 26,
                                "y": 220,
                                "w": 26,
                                "h": 26
                            },
                            "30": {
                                "x": 52,
                                "y": 220,
                                "w": 24,
                                "h": 24
                            },
                            "45": {
                                "x": 76,
                                "y": 220,
                                "w": 26,
                                "h": 26
                            },
                            "60": {
                                "x": 102,
                                "y": 220,
                                "w": 28,
                                "h": 28
                            },
                            "75": {
                                "x": 130,
                                "y": 220,
                                "w": 29,
                                "h": 29
                            },
                            "90": {
                                "x": 159,
                                "y": 220,
                                "w": 28,
                                "h": 28
                            },
                            "105": {
                                "x": 187,
                                "y": 220,
                                "w": 29,
                                "h": 29
                            },
                            "120": {
                                "x": 216,
                                "y": 220,
                                "w": 29,
                                "h": 29
                            },
                            "135": {
                                "x": 245,
                                "y": 220,
                                "w": 26,
                                "h": 26
                            },
                            "150": {
                                "x": 271,
                                "y": 220,
                                "w": 25,
                                "h": 25
                            },
                            "165": {
                                "x": 296,
                                "y": 220,
                                "w": 26,
                                "h": 26
                            },
                            "180": {
                                "x": 322,
                                "y": 220,
                                "w": 26,
                                "h": 26
                            },
                            "195": {
                                "x": 348,
                                "y": 220,
                                "w": 26,
                                "h": 26
                            },
                            "210": {
                                "x": 374,
                                "y": 220,
                                "w": 24,
                                "h": 24
                            },
                            "225": {
                                "x": 398,
                                "y": 220,
                                "w": 26,
                                "h": 26
                            },
                            "240": {
                                "x": 424,
                                "y": 220,
                                "w": 28,
                                "h": 28
                            },
                            "255": {
                                "x": 452,
                                "y": 220,
                                "w": 29,
                                "h": 29
                            },
                            "270": {
                                "x": 481,
                                "y": 220,
                                "w": 28,
                                "h": 28
                            },
                            "285": {
                                "x": 509,
                                "y": 220,
                                "w": 29,
                                "h": 29
                            },
                            "300": {
                                "x": 538,
                                "y": 220,
                                "w": 29,
                                "h": 29
                            },
                            "315": {
                                "x": 567,
                                "y": 220,
                                "w": 26,
                                "h": 26
                            },
                            "330": {
                                "x": 593,
                                "y": 220,
                                "w": 25,
                                "h": 25
                            },
                            "345": {
                                "x": 618,
                                "y": 220,
                                "w": 26,
                                "h": 26
                            }
                        }
                    ]
                },
                "Q300": {
                    "rotates": true,
                    "aliases": [
                        "A400",
                        "A748",
                        "AN12",
                        "B17",
                        "AN24",
                        "AN26",
                        "AT42",
                        "AT72",
                        "AT43",
                        "AT44",
                        "AT45",
                        "AT46",
                        "AT73",
                        "AT74",
                        "AT75",
                        "AT76",
                        "ATP",
                        "B190",
                        "B350",
                        "BE99",
                        "BN2P",
                        "BN2T",
                        "C30J",
                        "C130",
                        "C212",
                        "CN35",
                        "CVLP",
                        "CVLT",
                        "D228",
                        "D328",
                        "DC3",
                        "DC3T",
                        "DC6",
                        "D6F",
                        "DHC6",
                        "DHC7",
                        "DH8A",
                        "DH8B",
                        "DH8C",
                        "DH8D",
                        "E110",
                        "E120",
                        "F50",
                        "G159",
                        "JS31",
                        "JS32",
                        "JS41",
                        "L188",
                        "L410",
                        "SC7",
                        "SF34",
                        "SB20",
                        "SH33",
                        "SH36",
                        "SW4",
                        "C295",
                        "AN32",
                        "P3",
                        "C27J",
                        "CL2P",
                        "V10",
                        "A140",
                        "F27",
                        "CONI"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 275,
                                "w": 28,
                                "h": 28
                            },
                            "15": {
                                "x": 28,
                                "y": 275,
                                "w": 29,
                                "h": 29
                            },
                            "30": {
                                "x": 57,
                                "y": 275,
                                "w": 28,
                                "h": 28
                            },
                            "45": {
                                "x": 85,
                                "y": 275,
                                "w": 28,
                                "h": 28
                            },
                            "60": {
                                "x": 113,
                                "y": 275,
                                "w": 29,
                                "h": 29
                            },
                            "75": {
                                "x": 142,
                                "y": 275,
                                "w": 29,
                                "h": 29
                            },
                            "90": {
                                "x": 171,
                                "y": 275,
                                "w": 28,
                                "h": 28
                            },
                            "105": {
                                "x": 199,
                                "y": 275,
                                "w": 29,
                                "h": 29
                            },
                            "120": {
                                "x": 228,
                                "y": 275,
                                "w": 28,
                                "h": 28
                            },
                            "135": {
                                "x": 256,
                                "y": 275,
                                "w": 28,
                                "h": 28
                            },
                            "150": {
                                "x": 284,
                                "y": 275,
                                "w": 28,
                                "h": 28
                            },
                            "165": {
                                "x": 312,
                                "y": 275,
                                "w": 29,
                                "h": 29
                            },
                            "180": {
                                "x": 341,
                                "y": 275,
                                "w": 28,
                                "h": 28
                            },
                            "195": {
                                "x": 369,
                                "y": 275,
                                "w": 29,
                                "h": 29
                            },
                            "210": {
                                "x": 398,
                                "y": 275,
                                "w": 28,
                                "h": 28
                            },
                            "225": {
                                "x": 426,
                                "y": 275,
                                "w": 28,
                                "h": 28
                            },
                            "240": {
                                "x": 454,
                                "y": 275,
                                "w": 29,
                                "h": 29
                            },
                            "255": {
                                "x": 483,
                                "y": 275,
                                "w": 29,
                                "h": 29
                            },
                            "270": {
                                "x": 512,
                                "y": 275,
                                "w": 28,
                                "h": 28
                            },
                            "285": {
                                "x": 540,
                                "y": 275,
                                "w": 29,
                                "h": 29
                            },
                            "300": {
                                "x": 569,
                                "y": 275,
                                "w": 28,
                                "h": 28
                            },
                            "315": {
                                "x": 597,
                                "y": 275,
                                "w": 28,
                                "h": 28
                            },
                            "330": {
                                "x": 625,
                                "y": 275,
                                "w": 28,
                                "h": 28
                            },
                            "345": {
                                "x": 653,
                                "y": 275,
                                "w": 29,
                                "h": 29
                            }
                        }
                    ]
                },
                "B736": {
                    "rotates": true,
                    "aliases": [
                        "A318",
                        "A319",
                        "A19N",
                        "B735",
                        "BCS1",
                        "BCS3",
                        "E170",
                        "E190",
                        "S100",
                        "SU95",
                        "E75L",
                        "E75S",
                        "E195",
                        "B732",
                        "MRJ9",
                        "A148",
                        "A158",
                        "A178",
                        "E275",
                        "E290",
                        "E295",
                        "BER2",
                        "AN72"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 330,
                                "w": 26,
                                "h": 26
                            },
                            "15": {
                                "x": 26,
                                "y": 330,
                                "w": 27,
                                "h": 27
                            },
                            "30": {
                                "x": 53,
                                "y": 330,
                                "w": 26,
                                "h": 26
                            },
                            "45": {
                                "x": 79,
                                "y": 330,
                                "w": 27,
                                "h": 27
                            },
                            "60": {
                                "x": 106,
                                "y": 330,
                                "w": 28,
                                "h": 28
                            },
                            "75": {
                                "x": 134,
                                "y": 330,
                                "w": 29,
                                "h": 29
                            },
                            "90": {
                                "x": 163,
                                "y": 330,
                                "w": 28,
                                "h": 28
                            },
                            "105": {
                                "x": 191,
                                "y": 330,
                                "w": 28,
                                "h": 28
                            },
                            "120": {
                                "x": 219,
                                "y": 330,
                                "w": 29,
                                "h": 29
                            },
                            "135": {
                                "x": 248,
                                "y": 330,
                                "w": 26,
                                "h": 26
                            },
                            "150": {
                                "x": 274,
                                "y": 330,
                                "w": 25,
                                "h": 25
                            },
                            "165": {
                                "x": 299,
                                "y": 330,
                                "w": 27,
                                "h": 27
                            },
                            "180": {
                                "x": 326,
                                "y": 330,
                                "w": 26,
                                "h": 26
                            },
                            "195": {
                                "x": 352,
                                "y": 330,
                                "w": 27,
                                "h": 27
                            },
                            "210": {
                                "x": 379,
                                "y": 330,
                                "w": 26,
                                "h": 26
                            },
                            "225": {
                                "x": 405,
                                "y": 330,
                                "w": 27,
                                "h": 27
                            },
                            "240": {
                                "x": 432,
                                "y": 330,
                                "w": 28,
                                "h": 28
                            },
                            "255": {
                                "x": 460,
                                "y": 330,
                                "w": 29,
                                "h": 29
                            },
                            "270": {
                                "x": 489,
                                "y": 330,
                                "w": 28,
                                "h": 28
                            },
                            "285": {
                                "x": 517,
                                "y": 330,
                                "w": 28,
                                "h": 28
                            },
                            "300": {
                                "x": 545,
                                "y": 330,
                                "w": 29,
                                "h": 29
                            },
                            "315": {
                                "x": 574,
                                "y": 330,
                                "w": 26,
                                "h": 26
                            },
                            "330": {
                                "x": 600,
                                "y": 330,
                                "w": 25,
                                "h": 25
                            },
                            "345": {
                                "x": 625,
                                "y": 330,
                                "w": 27,
                                "h": 27
                            }
                        }
                    ]
                },
                "F100": {
                    "rotates": true,
                    "aliases": [
                        "AJ27",
                        "B712",
                        "B717",
                        "B721",
                        "B722",
                        "CRJ",
                        "CRJ1",
                        "CRJ2",
                        "CRJ7",
                        "CRJ9",
                        "CRJX",
                        "DC91",
                        "DC93",
                        "DC95",
                        "E45X",
                        "E135",
                        "E145",
                        "F70",
                        "IL62",
                        "MD80",
                        "MD81",
                        "MD82",
                        "MD83",
                        "MD87",
                        "MD88",
                        "MD90",
                        "R721",
                        "T154",
                        "YK42",
                        "T134",
                        "YK40",
                        "GL7T",
                        "F28"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 385,
                                "w": 28,
                                "h": 28
                            },
                            "15": {
                                "x": 28,
                                "y": 385,
                                "w": 26,
                                "h": 26
                            },
                            "30": {
                                "x": 54,
                                "y": 385,
                                "w": 24,
                                "h": 24
                            },
                            "45": {
                                "x": 78,
                                "y": 385,
                                "w": 27,
                                "h": 27
                            },
                            "60": {
                                "x": 105,
                                "y": 385,
                                "w": 29,
                                "h": 29
                            },
                            "75": {
                                "x": 134,
                                "y": 385,
                                "w": 29,
                                "h": 29
                            },
                            "90": {
                                "x": 163,
                                "y": 385,
                                "w": 28,
                                "h": 28
                            },
                            "105": {
                                "x": 191,
                                "y": 385,
                                "w": 29,
                                "h": 29
                            },
                            "120": {
                                "x": 220,
                                "y": 385,
                                "w": 29,
                                "h": 29
                            },
                            "135": {
                                "x": 249,
                                "y": 385,
                                "w": 26,
                                "h": 26
                            },
                            "150": {
                                "x": 275,
                                "y": 385,
                                "w": 24,
                                "h": 24
                            },
                            "165": {
                                "x": 299,
                                "y": 385,
                                "w": 26,
                                "h": 26
                            },
                            "180": {
                                "x": 325,
                                "y": 385,
                                "w": 28,
                                "h": 28
                            },
                            "195": {
                                "x": 353,
                                "y": 385,
                                "w": 26,
                                "h": 26
                            },
                            "210": {
                                "x": 379,
                                "y": 385,
                                "w": 24,
                                "h": 24
                            },
                            "225": {
                                "x": 403,
                                "y": 385,
                                "w": 27,
                                "h": 27
                            },
                            "240": {
                                "x": 430,
                                "y": 385,
                                "w": 29,
                                "h": 29
                            },
                            "255": {
                                "x": 459,
                                "y": 385,
                                "w": 29,
                                "h": 29
                            },
                            "270": {
                                "x": 488,
                                "y": 385,
                                "w": 28,
                                "h": 28
                            },
                            "285": {
                                "x": 516,
                                "y": 385,
                                "w": 29,
                                "h": 29
                            },
                            "300": {
                                "x": 545,
                                "y": 385,
                                "w": 29,
                                "h": 29
                            },
                            "315": {
                                "x": 574,
                                "y": 385,
                                "w": 26,
                                "h": 26
                            },
                            "330": {
                                "x": 600,
                                "y": 385,
                                "w": 24,
                                "h": 24
                            },
                            "345": {
                                "x": 624,
                                "y": 385,
                                "w": 26,
                                "h": 26
                            }
                        }
                    ]
                },
                "RJ85": {
                    "rotates": true,
                    "aliases": [
                        "B461",
                        "B462",
                        "B463",
                        "C17",
                        "IL76",
                        "RJ1H",
                        "RJ70",
                        "RJ100",
                        "J328"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 440,
                                "w": 26,
                                "h": 26
                            },
                            "15": {
                                "x": 26,
                                "y": 440,
                                "w": 27,
                                "h": 27
                            },
                            "30": {
                                "x": 53,
                                "y": 440,
                                "w": 27,
                                "h": 27
                            },
                            "45": {
                                "x": 80,
                                "y": 440,
                                "w": 28,
                                "h": 28
                            },
                            "60": {
                                "x": 108,
                                "y": 440,
                                "w": 29,
                                "h": 29
                            },
                            "75": {
                                "x": 137,
                                "y": 440,
                                "w": 30,
                                "h": 30
                            },
                            "90": {
                                "x": 167,
                                "y": 440,
                                "w": 28,
                                "h": 28
                            },
                            "105": {
                                "x": 195,
                                "y": 440,
                                "w": 29,
                                "h": 29
                            },
                            "120": {
                                "x": 224,
                                "y": 440,
                                "w": 30,
                                "h": 30
                            },
                            "135": {
                                "x": 254,
                                "y": 440,
                                "w": 29,
                                "h": 29
                            },
                            "150": {
                                "x": 283,
                                "y": 440,
                                "w": 28,
                                "h": 28
                            },
                            "165": {
                                "x": 311,
                                "y": 440,
                                "w": 26,
                                "h": 26
                            },
                            "180": {
                                "x": 337,
                                "y": 440,
                                "w": 26,
                                "h": 26
                            },
                            "195": {
                                "x": 363,
                                "y": 440,
                                "w": 27,
                                "h": 27
                            },
                            "210": {
                                "x": 390,
                                "y": 440,
                                "w": 27,
                                "h": 27
                            },
                            "225": {
                                "x": 417,
                                "y": 440,
                                "w": 28,
                                "h": 28
                            },
                            "240": {
                                "x": 445,
                                "y": 440,
                                "w": 29,
                                "h": 29
                            },
                            "255": {
                                "x": 474,
                                "y": 440,
                                "w": 30,
                                "h": 30
                            },
                            "270": {
                                "x": 504,
                                "y": 440,
                                "w": 28,
                                "h": 28
                            },
                            "285": {
                                "x": 532,
                                "y": 440,
                                "w": 29,
                                "h": 29
                            },
                            "300": {
                                "x": 561,
                                "y": 440,
                                "w": 30,
                                "h": 30
                            },
                            "315": {
                                "x": 591,
                                "y": 440,
                                "w": 29,
                                "h": 29
                            },
                            "330": {
                                "x": 620,
                                "y": 440,
                                "w": 28,
                                "h": 28
                            },
                            "345": {
                                "x": 648,
                                "y": 440,
                                "w": 26,
                                "h": 26
                            }
                        }
                    ]
                },
                "A320": {
                    "rotates": true,
                    "aliases": [
                        "A321",
                        "A20N",
                        "A21N"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 495,
                                "w": 30,
                                "h": 30
                            },
                            "15": {
                                "x": 30,
                                "y": 495,
                                "w": 30,
                                "h": 30
                            },
                            "30": {
                                "x": 60,
                                "y": 495,
                                "w": 28,
                                "h": 28
                            },
                            "45": {
                                "x": 88,
                                "y": 495,
                                "w": 29,
                                "h": 29
                            },
                            "60": {
                                "x": 117,
                                "y": 495,
                                "w": 31,
                                "h": 31
                            },
                            "75": {
                                "x": 148,
                                "y": 495,
                                "w": 32,
                                "h": 32
                            },
                            "90": {
                                "x": 180,
                                "y": 495,
                                "w": 32,
                                "h": 32
                            },
                            "105": {
                                "x": 212,
                                "y": 495,
                                "w": 32,
                                "h": 32
                            },
                            "120": {
                                "x": 244,
                                "y": 495,
                                "w": 31,
                                "h": 31
                            },
                            "135": {
                                "x": 275,
                                "y": 495,
                                "w": 29,
                                "h": 29
                            },
                            "150": {
                                "x": 304,
                                "y": 495,
                                "w": 29,
                                "h": 29
                            },
                            "165": {
                                "x": 333,
                                "y": 495,
                                "w": 31,
                                "h": 31
                            },
                            "180": {
                                "x": 364,
                                "y": 495,
                                "w": 30,
                                "h": 30
                            },
                            "195": {
                                "x": 394,
                                "y": 495,
                                "w": 30,
                                "h": 30
                            },
                            "210": {
                                "x": 424,
                                "y": 495,
                                "w": 28,
                                "h": 28
                            },
                            "225": {
                                "x": 452,
                                "y": 495,
                                "w": 29,
                                "h": 29
                            },
                            "240": {
                                "x": 481,
                                "y": 495,
                                "w": 31,
                                "h": 31
                            },
                            "255": {
                                "x": 512,
                                "y": 495,
                                "w": 32,
                                "h": 32
                            },
                            "270": {
                                "x": 544,
                                "y": 495,
                                "w": 32,
                                "h": 32
                            },
                            "285": {
                                "x": 576,
                                "y": 495,
                                "w": 32,
                                "h": 32
                            },
                            "300": {
                                "x": 608,
                                "y": 495,
                                "w": 31,
                                "h": 31
                            },
                            "315": {
                                "x": 639,
                                "y": 495,
                                "w": 29,
                                "h": 29
                            },
                            "330": {
                                "x": 668,
                                "y": 495,
                                "w": 29,
                                "h": 29
                            },
                            "345": {
                                "x": 697,
                                "y": 495,
                                "w": 31,
                                "h": 31
                            }
                        }
                    ]
                },
                "B738": {
                    "rotates": true,
                    "aliases": [
                        "B733",
                        "B734",
                        "B737",
                        "B739",
                        "B37M",
                        "B38M",
                        "B39M",
                        "B73H",
                        "P8"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 550,
                                "w": 30,
                                "h": 30
                            },
                            "15": {
                                "x": 30,
                                "y": 550,
                                "w": 31,
                                "h": 31
                            },
                            "30": {
                                "x": 61,
                                "y": 550,
                                "w": 29,
                                "h": 29
                            },
                            "45": {
                                "x": 90,
                                "y": 550,
                                "w": 29,
                                "h": 29
                            },
                            "60": {
                                "x": 119,
                                "y": 550,
                                "w": 32,
                                "h": 32
                            },
                            "75": {
                                "x": 151,
                                "y": 550,
                                "w": 32,
                                "h": 32
                            },
                            "90": {
                                "x": 183,
                                "y": 550,
                                "w": 32,
                                "h": 32
                            },
                            "105": {
                                "x": 215,
                                "y": 550,
                                "w": 32,
                                "h": 32
                            },
                            "120": {
                                "x": 247,
                                "y": 550,
                                "w": 32,
                                "h": 32
                            },
                            "135": {
                                "x": 279,
                                "y": 550,
                                "w": 30,
                                "h": 30
                            },
                            "150": {
                                "x": 309,
                                "y": 550,
                                "w": 29,
                                "h": 29
                            },
                            "165": {
                                "x": 338,
                                "y": 550,
                                "w": 31,
                                "h": 31
                            },
                            "180": {
                                "x": 369,
                                "y": 550,
                                "w": 30,
                                "h": 30
                            },
                            "195": {
                                "x": 399,
                                "y": 550,
                                "w": 31,
                                "h": 31
                            },
                            "210": {
                                "x": 430,
                                "y": 550,
                                "w": 29,
                                "h": 29
                            },
                            "225": {
                                "x": 459,
                                "y": 550,
                                "w": 29,
                                "h": 29
                            },
                            "240": {
                                "x": 488,
                                "y": 550,
                                "w": 32,
                                "h": 32
                            },
                            "255": {
                                "x": 520,
                                "y": 550,
                                "w": 32,
                                "h": 32
                            },
                            "270": {
                                "x": 552,
                                "y": 550,
                                "w": 32,
                                "h": 32
                            },
                            "285": {
                                "x": 584,
                                "y": 550,
                                "w": 32,
                                "h": 32
                            },
                            "300": {
                                "x": 616,
                                "y": 550,
                                "w": 32,
                                "h": 32
                            },
                            "315": {
                                "x": 648,
                                "y": 550,
                                "w": 30,
                                "h": 30
                            },
                            "330": {
                                "x": 678,
                                "y": 550,
                                "w": 29,
                                "h": 29
                            },
                            "345": {
                                "x": 707,
                                "y": 550,
                                "w": 31,
                                "h": 31
                            }
                        }
                    ]
                },
                "B757": {
                    "rotates": true,
                    "aliases": [
                        "B752",
                        "B753",
                        "T204",
                        "T214"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 605,
                                "w": 33,
                                "h": 33
                            },
                            "15": {
                                "x": 33,
                                "y": 605,
                                "w": 32,
                                "h": 32
                            },
                            "30": {
                                "x": 65,
                                "y": 605,
                                "w": 31,
                                "h": 31
                            },
                            "45": {
                                "x": 96,
                                "y": 605,
                                "w": 35,
                                "h": 35
                            },
                            "60": {
                                "x": 131,
                                "y": 605,
                                "w": 38,
                                "h": 38
                            },
                            "75": {
                                "x": 169,
                                "y": 605,
                                "w": 39,
                                "h": 39
                            },
                            "90": {
                                "x": 208,
                                "y": 605,
                                "w": 39,
                                "h": 39
                            },
                            "105": {
                                "x": 247,
                                "y": 605,
                                "w": 39,
                                "h": 39
                            },
                            "120": {
                                "x": 286,
                                "y": 605,
                                "w": 38,
                                "h": 38
                            },
                            "135": {
                                "x": 324,
                                "y": 605,
                                "w": 35,
                                "h": 35
                            },
                            "150": {
                                "x": 359,
                                "y": 605,
                                "w": 31,
                                "h": 31
                            },
                            "165": {
                                "x": 390,
                                "y": 605,
                                "w": 32,
                                "h": 32
                            },
                            "180": {
                                "x": 422,
                                "y": 605,
                                "w": 33,
                                "h": 33
                            },
                            "195": {
                                "x": 455,
                                "y": 605,
                                "w": 32,
                                "h": 32
                            },
                            "210": {
                                "x": 487,
                                "y": 605,
                                "w": 31,
                                "h": 31
                            },
                            "225": {
                                "x": 518,
                                "y": 605,
                                "w": 35,
                                "h": 35
                            },
                            "240": {
                                "x": 553,
                                "y": 605,
                                "w": 38,
                                "h": 38
                            },
                            "255": {
                                "x": 591,
                                "y": 605,
                                "w": 39,
                                "h": 39
                            },
                            "270": {
                                "x": 630,
                                "y": 605,
                                "w": 39,
                                "h": 39
                            },
                            "285": {
                                "x": 669,
                                "y": 605,
                                "w": 39,
                                "h": 39
                            },
                            "300": {
                                "x": 708,
                                "y": 605,
                                "w": 38,
                                "h": 38
                            },
                            "315": {
                                "x": 746,
                                "y": 605,
                                "w": 35,
                                "h": 35
                            },
                            "330": {
                                "x": 781,
                                "y": 605,
                                "w": 31,
                                "h": 31
                            },
                            "345": {
                                "x": 812,
                                "y": 605,
                                "w": 32,
                                "h": 32
                            }
                        }
                    ]
                },
                "A3ST": {
                    "rotates": true,
                    "aliases": [
                        "A337"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 660,
                                "w": 39,
                                "h": 39
                            },
                            "15": {
                                "x": 39,
                                "y": 660,
                                "w": 38,
                                "h": 38
                            },
                            "30": {
                                "x": 77,
                                "y": 660,
                                "w": 35,
                                "h": 35
                            },
                            "45": {
                                "x": 112,
                                "y": 660,
                                "w": 37,
                                "h": 37
                            },
                            "60": {
                                "x": 149,
                                "y": 660,
                                "w": 40,
                                "h": 40
                            },
                            "75": {
                                "x": 189,
                                "y": 660,
                                "w": 41,
                                "h": 41
                            },
                            "90": {
                                "x": 230,
                                "y": 660,
                                "w": 39,
                                "h": 39
                            },
                            "105": {
                                "x": 269,
                                "y": 660,
                                "w": 41,
                                "h": 41
                            },
                            "120": {
                                "x": 310,
                                "y": 660,
                                "w": 40,
                                "h": 40
                            },
                            "135": {
                                "x": 350,
                                "y": 660,
                                "w": 37,
                                "h": 37
                            },
                            "150": {
                                "x": 387,
                                "y": 660,
                                "w": 35,
                                "h": 35
                            },
                            "165": {
                                "x": 422,
                                "y": 660,
                                "w": 37,
                                "h": 37
                            },
                            "180": {
                                "x": 459,
                                "y": 660,
                                "w": 39,
                                "h": 39
                            },
                            "195": {
                                "x": 498,
                                "y": 660,
                                "w": 38,
                                "h": 38
                            },
                            "210": {
                                "x": 536,
                                "y": 660,
                                "w": 35,
                                "h": 35
                            },
                            "225": {
                                "x": 571,
                                "y": 660,
                                "w": 37,
                                "h": 37
                            },
                            "240": {
                                "x": 608,
                                "y": 660,
                                "w": 40,
                                "h": 40
                            },
                            "255": {
                                "x": 648,
                                "y": 660,
                                "w": 41,
                                "h": 41
                            },
                            "270": {
                                "x": 689,
                                "y": 660,
                                "w": 39,
                                "h": 39
                            },
                            "285": {
                                "x": 728,
                                "y": 660,
                                "w": 41,
                                "h": 41
                            },
                            "300": {
                                "x": 769,
                                "y": 660,
                                "w": 40,
                                "h": 40
                            },
                            "315": {
                                "x": 809,
                                "y": 660,
                                "w": 37,
                                "h": 37
                            },
                            "330": {
                                "x": 846,
                                "y": 660,
                                "w": 35,
                                "h": 35
                            },
                            "345": {
                                "x": 881,
                                "y": 660,
                                "w": 37,
                                "h": 37
                            }
                        }
                    ]
                },
                "MD11": {
                    "rotates": true,
                    "aliases": [
                        "DC10",
                        "L101"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 715,
                                "w": 37,
                                "h": 37
                            },
                            "15": {
                                "x": 37,
                                "y": 715,
                                "w": 36,
                                "h": 36
                            },
                            "30": {
                                "x": 73,
                                "y": 715,
                                "w": 33,
                                "h": 33
                            },
                            "45": {
                                "x": 106,
                                "y": 715,
                                "w": 35,
                                "h": 35
                            },
                            "60": {
                                "x": 141,
                                "y": 715,
                                "w": 38,
                                "h": 38
                            },
                            "75": {
                                "x": 179,
                                "y": 715,
                                "w": 40,
                                "h": 40
                            },
                            "90": {
                                "x": 219,
                                "y": 715,
                                "w": 39,
                                "h": 39
                            },
                            "105": {
                                "x": 258,
                                "y": 715,
                                "w": 40,
                                "h": 40
                            },
                            "120": {
                                "x": 298,
                                "y": 715,
                                "w": 38,
                                "h": 38
                            },
                            "135": {
                                "x": 336,
                                "y": 715,
                                "w": 35,
                                "h": 35
                            },
                            "150": {
                                "x": 371,
                                "y": 715,
                                "w": 33,
                                "h": 33
                            },
                            "165": {
                                "x": 404,
                                "y": 715,
                                "w": 36,
                                "h": 36
                            },
                            "180": {
                                "x": 440,
                                "y": 715,
                                "w": 37,
                                "h": 37
                            },
                            "195": {
                                "x": 477,
                                "y": 715,
                                "w": 36,
                                "h": 36
                            },
                            "210": {
                                "x": 513,
                                "y": 715,
                                "w": 33,
                                "h": 33
                            },
                            "225": {
                                "x": 546,
                                "y": 715,
                                "w": 35,
                                "h": 35
                            },
                            "240": {
                                "x": 581,
                                "y": 715,
                                "w": 38,
                                "h": 38
                            },
                            "255": {
                                "x": 619,
                                "y": 715,
                                "w": 40,
                                "h": 40
                            },
                            "270": {
                                "x": 659,
                                "y": 715,
                                "w": 39,
                                "h": 39
                            },
                            "285": {
                                "x": 698,
                                "y": 715,
                                "w": 40,
                                "h": 40
                            },
                            "300": {
                                "x": 738,
                                "y": 715,
                                "w": 38,
                                "h": 38
                            },
                            "315": {
                                "x": 776,
                                "y": 715,
                                "w": 35,
                                "h": 35
                            },
                            "330": {
                                "x": 811,
                                "y": 715,
                                "w": 33,
                                "h": 33
                            },
                            "345": {
                                "x": 844,
                                "y": 715,
                                "w": 36,
                                "h": 36
                            }
                        }
                    ]
                },
                "A330": {
                    "rotates": true,
                    "aliases": [
                        "B762",
                        "B763",
                        "B764",
                        "A300",
                        "A30B",
                        "A306",
                        "A310",
                        "A332",
                        "A333",
                        "A338",
                        "A339",
                        "A359",
                        "B787",
                        "B788",
                        "B789",
                        "B78X"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 770,
                                "w": 35,
                                "h": 35
                            },
                            "15": {
                                "x": 35,
                                "y": 770,
                                "w": 34,
                                "h": 34
                            },
                            "30": {
                                "x": 69,
                                "y": 770,
                                "w": 32,
                                "h": 32
                            },
                            "45": {
                                "x": 101,
                                "y": 770,
                                "w": 32,
                                "h": 32
                            },
                            "60": {
                                "x": 133,
                                "y": 770,
                                "w": 34,
                                "h": 34
                            },
                            "75": {
                                "x": 167,
                                "y": 770,
                                "w": 36,
                                "h": 36
                            },
                            "90": {
                                "x": 203,
                                "y": 770,
                                "w": 35,
                                "h": 35
                            },
                            "105": {
                                "x": 238,
                                "y": 770,
                                "w": 36,
                                "h": 36
                            },
                            "120": {
                                "x": 274,
                                "y": 770,
                                "w": 35,
                                "h": 35
                            },
                            "135": {
                                "x": 309,
                                "y": 770,
                                "w": 32,
                                "h": 32
                            },
                            "150": {
                                "x": 341,
                                "y": 770,
                                "w": 32,
                                "h": 32
                            },
                            "165": {
                                "x": 373,
                                "y": 770,
                                "w": 35,
                                "h": 35
                            },
                            "180": {
                                "x": 408,
                                "y": 770,
                                "w": 35,
                                "h": 35
                            },
                            "195": {
                                "x": 443,
                                "y": 770,
                                "w": 34,
                                "h": 34
                            },
                            "210": {
                                "x": 477,
                                "y": 770,
                                "w": 32,
                                "h": 32
                            },
                            "225": {
                                "x": 509,
                                "y": 770,
                                "w": 32,
                                "h": 32
                            },
                            "240": {
                                "x": 541,
                                "y": 770,
                                "w": 34,
                                "h": 34
                            },
                            "255": {
                                "x": 575,
                                "y": 770,
                                "w": 36,
                                "h": 36
                            },
                            "270": {
                                "x": 611,
                                "y": 770,
                                "w": 35,
                                "h": 35
                            },
                            "285": {
                                "x": 646,
                                "y": 770,
                                "w": 36,
                                "h": 36
                            },
                            "300": {
                                "x": 682,
                                "y": 770,
                                "w": 35,
                                "h": 35
                            },
                            "315": {
                                "x": 717,
                                "y": 770,
                                "w": 32,
                                "h": 32
                            },
                            "330": {
                                "x": 749,
                                "y": 770,
                                "w": 32,
                                "h": 32
                            },
                            "345": {
                                "x": 781,
                                "y": 770,
                                "w": 35,
                                "h": 35
                            }
                        }
                    ]
                },
                "A340": {
                    "rotates": true,
                    "aliases": [
                        "A342",
                        "A343",
                        "B703",
                        "DC86",
                        "DC87",
                        "E6",
                        "IL86",
                        "IL96",
                        "C135",
                        "E3CF",
                        "R135",
                        "K35R",
                        "E3TF",
                        "K35E",
                        "A345",
                        "A346"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 825,
                                "w": 39,
                                "h": 39
                            },
                            "15": {
                                "x": 39,
                                "y": 825,
                                "w": 39,
                                "h": 39
                            },
                            "30": {
                                "x": 78,
                                "y": 825,
                                "w": 36,
                                "h": 36
                            },
                            "45": {
                                "x": 114,
                                "y": 825,
                                "w": 35,
                                "h": 35
                            },
                            "60": {
                                "x": 149,
                                "y": 825,
                                "w": 39,
                                "h": 39
                            },
                            "75": {
                                "x": 188,
                                "y": 825,
                                "w": 40,
                                "h": 40
                            },
                            "90": {
                                "x": 228,
                                "y": 825,
                                "w": 39,
                                "h": 39
                            },
                            "105": {
                                "x": 267,
                                "y": 825,
                                "w": 40,
                                "h": 40
                            },
                            "120": {
                                "x": 307,
                                "y": 825,
                                "w": 39,
                                "h": 39
                            },
                            "135": {
                                "x": 346,
                                "y": 825,
                                "w": 35,
                                "h": 35
                            },
                            "150": {
                                "x": 381,
                                "y": 825,
                                "w": 36,
                                "h": 36
                            },
                            "165": {
                                "x": 417,
                                "y": 825,
                                "w": 39,
                                "h": 39
                            },
                            "180": {
                                "x": 456,
                                "y": 825,
                                "w": 39,
                                "h": 39
                            },
                            "195": {
                                "x": 495,
                                "y": 825,
                                "w": 39,
                                "h": 39
                            },
                            "210": {
                                "x": 534,
                                "y": 825,
                                "w": 36,
                                "h": 36
                            },
                            "225": {
                                "x": 570,
                                "y": 825,
                                "w": 35,
                                "h": 35
                            },
                            "240": {
                                "x": 605,
                                "y": 825,
                                "w": 39,
                                "h": 39
                            },
                            "255": {
                                "x": 644,
                                "y": 825,
                                "w": 40,
                                "h": 40
                            },
                            "270": {
                                "x": 684,
                                "y": 825,
                                "w": 39,
                                "h": 39
                            },
                            "285": {
                                "x": 723,
                                "y": 825,
                                "w": 40,
                                "h": 40
                            },
                            "300": {
                                "x": 763,
                                "y": 825,
                                "w": 39,
                                "h": 39
                            },
                            "315": {
                                "x": 802,
                                "y": 825,
                                "w": 35,
                                "h": 35
                            },
                            "330": {
                                "x": 837,
                                "y": 825,
                                "w": 36,
                                "h": 36
                            },
                            "345": {
                                "x": 873,
                                "y": 825,
                                "w": 39,
                                "h": 39
                            }
                        }
                    ]
                },
                "B777": {
                    "rotates": true,
                    "aliases": [
                        "B772",
                        "B773",
                        "B77L",
                        "B77W",
                        "B778",
                        "B779",
                        "A35K"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 880,
                                "w": 38,
                                "h": 38
                            },
                            "15": {
                                "x": 38,
                                "y": 880,
                                "w": 37,
                                "h": 37
                            },
                            "30": {
                                "x": 75,
                                "y": 880,
                                "w": 35,
                                "h": 35
                            },
                            "45": {
                                "x": 110,
                                "y": 880,
                                "w": 36,
                                "h": 36
                            },
                            "60": {
                                "x": 146,
                                "y": 880,
                                "w": 38,
                                "h": 38
                            },
                            "75": {
                                "x": 184,
                                "y": 880,
                                "w": 39,
                                "h": 39
                            },
                            "90": {
                                "x": 223,
                                "y": 880,
                                "w": 39,
                                "h": 39
                            },
                            "105": {
                                "x": 262,
                                "y": 880,
                                "w": 39,
                                "h": 39
                            },
                            "120": {
                                "x": 301,
                                "y": 880,
                                "w": 38,
                                "h": 38
                            },
                            "135": {
                                "x": 339,
                                "y": 880,
                                "w": 36,
                                "h": 36
                            },
                            "150": {
                                "x": 375,
                                "y": 880,
                                "w": 34,
                                "h": 34
                            },
                            "165": {
                                "x": 409,
                                "y": 880,
                                "w": 37,
                                "h": 37
                            },
                            "180": {
                                "x": 446,
                                "y": 880,
                                "w": 38,
                                "h": 38
                            },
                            "195": {
                                "x": 484,
                                "y": 880,
                                "w": 37,
                                "h": 37
                            },
                            "210": {
                                "x": 521,
                                "y": 880,
                                "w": 35,
                                "h": 35
                            },
                            "225": {
                                "x": 556,
                                "y": 880,
                                "w": 36,
                                "h": 36
                            },
                            "240": {
                                "x": 592,
                                "y": 880,
                                "w": 38,
                                "h": 38
                            },
                            "255": {
                                "x": 630,
                                "y": 880,
                                "w": 39,
                                "h": 39
                            },
                            "270": {
                                "x": 669,
                                "y": 880,
                                "w": 39,
                                "h": 39
                            },
                            "285": {
                                "x": 708,
                                "y": 880,
                                "w": 39,
                                "h": 39
                            },
                            "300": {
                                "x": 747,
                                "y": 880,
                                "w": 38,
                                "h": 38
                            },
                            "315": {
                                "x": 785,
                                "y": 880,
                                "w": 36,
                                "h": 36
                            },
                            "330": {
                                "x": 821,
                                "y": 880,
                                "w": 34,
                                "h": 34
                            },
                            "345": {
                                "x": 855,
                                "y": 880,
                                "w": 37,
                                "h": 37
                            }
                        }
                    ]
                },
                "B747": {
                    "rotates": true,
                    "aliases": [
                        "A124",
                        "B741",
                        "B742",
                        "B743",
                        "B744",
                        "B74S",
                        "B748",
                        "BLCF",
                        "C5"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 935,
                                "w": 39,
                                "h": 39
                            },
                            "15": {
                                "x": 39,
                                "y": 935,
                                "w": 37,
                                "h": 37
                            },
                            "30": {
                                "x": 76,
                                "y": 935,
                                "w": 33,
                                "h": 33
                            },
                            "45": {
                                "x": 109,
                                "y": 935,
                                "w": 35,
                                "h": 35
                            },
                            "60": {
                                "x": 144,
                                "y": 935,
                                "w": 38,
                                "h": 38
                            },
                            "75": {
                                "x": 182,
                                "y": 935,
                                "w": 39,
                                "h": 39
                            },
                            "90": {
                                "x": 221,
                                "y": 935,
                                "w": 39,
                                "h": 39
                            },
                            "105": {
                                "x": 260,
                                "y": 935,
                                "w": 40,
                                "h": 40
                            },
                            "120": {
                                "x": 300,
                                "y": 935,
                                "w": 38,
                                "h": 38
                            },
                            "135": {
                                "x": 338,
                                "y": 935,
                                "w": 35,
                                "h": 35
                            },
                            "150": {
                                "x": 373,
                                "y": 935,
                                "w": 34,
                                "h": 34
                            },
                            "165": {
                                "x": 407,
                                "y": 935,
                                "w": 38,
                                "h": 38
                            },
                            "180": {
                                "x": 445,
                                "y": 935,
                                "w": 39,
                                "h": 39
                            },
                            "195": {
                                "x": 484,
                                "y": 935,
                                "w": 37,
                                "h": 37
                            },
                            "210": {
                                "x": 521,
                                "y": 935,
                                "w": 33,
                                "h": 33
                            },
                            "225": {
                                "x": 554,
                                "y": 935,
                                "w": 35,
                                "h": 35
                            },
                            "240": {
                                "x": 589,
                                "y": 935,
                                "w": 38,
                                "h": 38
                            },
                            "255": {
                                "x": 627,
                                "y": 935,
                                "w": 39,
                                "h": 39
                            },
                            "270": {
                                "x": 666,
                                "y": 935,
                                "w": 39,
                                "h": 39
                            },
                            "285": {
                                "x": 705,
                                "y": 935,
                                "w": 40,
                                "h": 40
                            },
                            "300": {
                                "x": 745,
                                "y": 935,
                                "w": 38,
                                "h": 38
                            },
                            "315": {
                                "x": 783,
                                "y": 935,
                                "w": 35,
                                "h": 35
                            },
                            "330": {
                                "x": 818,
                                "y": 935,
                                "w": 34,
                                "h": 34
                            },
                            "345": {
                                "x": 852,
                                "y": 935,
                                "w": 38,
                                "h": 38
                            }
                        }
                    ]
                },
                "A380": {
                    "rotates": true,
                    "aliases": [
                        "A388",
                        "A389"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 990,
                                "w": 39,
                                "h": 39
                            },
                            "15": {
                                "x": 39,
                                "y": 990,
                                "w": 40,
                                "h": 40
                            },
                            "30": {
                                "x": 79,
                                "y": 990,
                                "w": 37,
                                "h": 37
                            },
                            "45": {
                                "x": 116,
                                "y": 990,
                                "w": 35,
                                "h": 35
                            },
                            "60": {
                                "x": 151,
                                "y": 990,
                                "w": 38,
                                "h": 38
                            },
                            "75": {
                                "x": 189,
                                "y": 990,
                                "w": 40,
                                "h": 40
                            },
                            "90": {
                                "x": 229,
                                "y": 990,
                                "w": 39,
                                "h": 39
                            },
                            "105": {
                                "x": 268,
                                "y": 990,
                                "w": 40,
                                "h": 40
                            },
                            "120": {
                                "x": 308,
                                "y": 990,
                                "w": 38,
                                "h": 38
                            },
                            "135": {
                                "x": 346,
                                "y": 990,
                                "w": 35,
                                "h": 35
                            },
                            "150": {
                                "x": 381,
                                "y": 990,
                                "w": 37,
                                "h": 37
                            },
                            "165": {
                                "x": 418,
                                "y": 990,
                                "w": 40,
                                "h": 40
                            },
                            "180": {
                                "x": 458,
                                "y": 990,
                                "w": 39,
                                "h": 39
                            },
                            "195": {
                                "x": 497,
                                "y": 990,
                                "w": 40,
                                "h": 40
                            },
                            "210": {
                                "x": 537,
                                "y": 990,
                                "w": 37,
                                "h": 37
                            },
                            "225": {
                                "x": 574,
                                "y": 990,
                                "w": 35,
                                "h": 35
                            },
                            "240": {
                                "x": 609,
                                "y": 990,
                                "w": 38,
                                "h": 38
                            },
                            "255": {
                                "x": 647,
                                "y": 990,
                                "w": 40,
                                "h": 40
                            },
                            "270": {
                                "x": 687,
                                "y": 990,
                                "w": 39,
                                "h": 39
                            },
                            "285": {
                                "x": 726,
                                "y": 990,
                                "w": 40,
                                "h": 40
                            },
                            "300": {
                                "x": 766,
                                "y": 990,
                                "w": 38,
                                "h": 38
                            },
                            "315": {
                                "x": 804,
                                "y": 990,
                                "w": 35,
                                "h": 35
                            },
                            "330": {
                                "x": 839,
                                "y": 990,
                                "w": 37,
                                "h": 37
                            },
                            "345": {
                                "x": 876,
                                "y": 990,
                                "w": 40,
                                "h": 40
                            }
                        }
                    ]
                },
                "A225": {
                    "rotates": true,
                    "aliases": [],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 1045,
                                "w": 39,
                                "h": 39
                            },
                            "15": {
                                "x": 39,
                                "y": 1045,
                                "w": 40,
                                "h": 40
                            },
                            "30": {
                                "x": 79,
                                "y": 1045,
                                "w": 37,
                                "h": 37
                            },
                            "45": {
                                "x": 116,
                                "y": 1045,
                                "w": 37,
                                "h": 37
                            },
                            "60": {
                                "x": 153,
                                "y": 1045,
                                "w": 39,
                                "h": 39
                            },
                            "75": {
                                "x": 192,
                                "y": 1045,
                                "w": 40,
                                "h": 40
                            },
                            "90": {
                                "x": 232,
                                "y": 1045,
                                "w": 39,
                                "h": 39
                            },
                            "105": {
                                "x": 271,
                                "y": 1045,
                                "w": 40,
                                "h": 40
                            },
                            "120": {
                                "x": 311,
                                "y": 1045,
                                "w": 39,
                                "h": 39
                            },
                            "135": {
                                "x": 350,
                                "y": 1045,
                                "w": 37,
                                "h": 37
                            },
                            "150": {
                                "x": 387,
                                "y": 1045,
                                "w": 37,
                                "h": 37
                            },
                            "165": {
                                "x": 424,
                                "y": 1045,
                                "w": 40,
                                "h": 40
                            },
                            "180": {
                                "x": 464,
                                "y": 1045,
                                "w": 39,
                                "h": 39
                            },
                            "195": {
                                "x": 503,
                                "y": 1045,
                                "w": 40,
                                "h": 40
                            },
                            "210": {
                                "x": 543,
                                "y": 1045,
                                "w": 37,
                                "h": 37
                            },
                            "225": {
                                "x": 580,
                                "y": 1045,
                                "w": 37,
                                "h": 37
                            },
                            "240": {
                                "x": 617,
                                "y": 1045,
                                "w": 39,
                                "h": 39
                            },
                            "255": {
                                "x": 656,
                                "y": 1045,
                                "w": 40,
                                "h": 40
                            },
                            "270": {
                                "x": 696,
                                "y": 1045,
                                "w": 39,
                                "h": 39
                            },
                            "285": {
                                "x": 735,
                                "y": 1045,
                                "w": 40,
                                "h": 40
                            },
                            "300": {
                                "x": 775,
                                "y": 1045,
                                "w": 39,
                                "h": 39
                            },
                            "315": {
                                "x": 814,
                                "y": 1045,
                                "w": 37,
                                "h": 37
                            },
                            "330": {
                                "x": 851,
                                "y": 1045,
                                "w": 37,
                                "h": 37
                            },
                            "345": {
                                "x": 888,
                                "y": 1045,
                                "w": 40,
                                "h": 40
                            }
                        }
                    ]
                },
                "SI2": {
                    "rotates": true,
                    "aliases": [
                        "SOL2"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 1100,
                                "w": 39,
                                "h": 39
                            },
                            "15": {
                                "x": 39,
                                "y": 1100,
                                "w": 40,
                                "h": 40
                            },
                            "30": {
                                "x": 79,
                                "y": 1100,
                                "w": 38,
                                "h": 38
                            },
                            "45": {
                                "x": 117,
                                "y": 1100,
                                "w": 33,
                                "h": 33
                            },
                            "60": {
                                "x": 150,
                                "y": 1100,
                                "w": 28,
                                "h": 28
                            },
                            "75": {
                                "x": 178,
                                "y": 1100,
                                "w": 23,
                                "h": 23
                            },
                            "90": {
                                "x": 201,
                                "y": 1100,
                                "w": 21,
                                "h": 21
                            },
                            "105": {
                                "x": 222,
                                "y": 1100,
                                "w": 23,
                                "h": 23
                            },
                            "120": {
                                "x": 245,
                                "y": 1100,
                                "w": 27,
                                "h": 27
                            },
                            "135": {
                                "x": 272,
                                "y": 1100,
                                "w": 33,
                                "h": 33
                            },
                            "150": {
                                "x": 305,
                                "y": 1100,
                                "w": 38,
                                "h": 38
                            },
                            "165": {
                                "x": 343,
                                "y": 1100,
                                "w": 40,
                                "h": 40
                            },
                            "180": {
                                "x": 383,
                                "y": 1100,
                                "w": 39,
                                "h": 39
                            },
                            "195": {
                                "x": 422,
                                "y": 1100,
                                "w": 40,
                                "h": 40
                            },
                            "210": {
                                "x": 462,
                                "y": 1100,
                                "w": 38,
                                "h": 38
                            },
                            "225": {
                                "x": 500,
                                "y": 1100,
                                "w": 33,
                                "h": 33
                            },
                            "240": {
                                "x": 533,
                                "y": 1100,
                                "w": 28,
                                "h": 28
                            },
                            "255": {
                                "x": 561,
                                "y": 1100,
                                "w": 23,
                                "h": 23
                            },
                            "270": {
                                "x": 584,
                                "y": 1100,
                                "w": 21,
                                "h": 21
                            },
                            "285": {
                                "x": 605,
                                "y": 1100,
                                "w": 23,
                                "h": 23
                            },
                            "300": {
                                "x": 628,
                                "y": 1100,
                                "w": 27,
                                "h": 27
                            },
                            "315": {
                                "x": 655,
                                "y": 1100,
                                "w": 33,
                                "h": 33
                            },
                            "330": {
                                "x": 688,
                                "y": 1100,
                                "w": 38,
                                "h": 38
                            },
                            "345": {
                                "x": 726,
                                "y": 1100,
                                "w": 40,
                                "h": 40
                            }
                        }
                    ]
                },
                "EC": {
                    "rotates": true,
                    "aliases": [
                        "A002",
                        "A109",
                        "A119",
                        "A129",
                        "A139",
                        "A149",
                        "A169",
                        "A189",
                        "ALH",
                        "ALO2",
                        "ALO3",
                        "AS32",
                        "AS3B",
                        "AS50",
                        "AS55",
                        "AS65",
                        "B06",
                        "B06T",
                        "B105",
                        "B212",
                        "B222",
                        "B230",
                        "B407",
                        "B412",
                        "B427",
                        "B429",
                        "B430",
                        "B47G",
                        "B47J",
                        "BK17",
                        "BSTP",
                        "EC20",
                        "EC25",
                        "EC30",
                        "EC35",
                        "EC45",
                        "EC55",
                        "EC75",
                        "EH10",
                        "EXPL",
                        "FREL",
                        "GAZL",
                        "H2",
                        "H269",
                        "H47",
                        "H500",
                        "H53",
                        "H53S",
                        "H60",
                        "H64",
                        "HUCO",
                        "KA32",
                        "KA50",
                        "KA52",
                        "KMAX",
                        "LAMA",
                        "LYNX",
                        "MI26",
                        "MI38",
                        "MI8",
                        "NH90",
                        "OH1",
                        "PUMA",
                        "R22",
                        "R44",
                        "R66",
                        "RVAL",
                        "S61",
                        "S61R",
                        "S76",
                        "S92",
                        "SUCO",
                        "TIGR",
                        "UH1",
                        "UH1Y",
                        "V22",
                        "B505",
                        "G2CA",
                        "GYRO",
                        "H160",
                        "CDUS",
                        "MM24",
                        "EXEC",
                        "CLON",
                        "S64",
                        "EN48",
                        "W3",
                        "S330",
                        "H46",
                        "MD60",
                        "B214",
                        "EN28",
                        "MD52"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 1155,
                                "w": 39,
                                "h": 39
                            },
                            "15": {
                                "x": 39,
                                "y": 1155,
                                "w": 49,
                                "h": 49
                            },
                            "30": {
                                "x": 88,
                                "y": 1155,
                                "w": 53,
                                "h": 53
                            },
                            "45": {
                                "x": 141,
                                "y": 1155,
                                "w": 55,
                                "h": 55
                            },
                            "60": {
                                "x": 196,
                                "y": 1155,
                                "w": 53,
                                "h": 53
                            },
                            "75": {
                                "x": 249,
                                "y": 1155,
                                "w": 49,
                                "h": 49
                            },
                            "90": {
                                "x": 298,
                                "y": 1155,
                                "w": 39,
                                "h": 39
                            },
                            "105": {
                                "x": 337,
                                "y": 1155,
                                "w": 49,
                                "h": 49
                            },
                            "120": {
                                "x": 386,
                                "y": 1155,
                                "w": 53,
                                "h": 53
                            },
                            "135": {
                                "x": 439,
                                "y": 1155,
                                "w": 55,
                                "h": 55
                            },
                            "150": {
                                "x": 494,
                                "y": 1155,
                                "w": 53,
                                "h": 53
                            },
                            "165": {
                                "x": 547,
                                "y": 1155,
                                "w": 49,
                                "h": 49
                            },
                            "180": {
                                "x": 596,
                                "y": 1155,
                                "w": 39,
                                "h": 39
                            },
                            "195": {
                                "x": 635,
                                "y": 1155,
                                "w": 49,
                                "h": 49
                            },
                            "210": {
                                "x": 684,
                                "y": 1155,
                                "w": 53,
                                "h": 53
                            },
                            "225": {
                                "x": 737,
                                "y": 1155,
                                "w": 55,
                                "h": 55
                            },
                            "240": {
                                "x": 792,
                                "y": 1155,
                                "w": 53,
                                "h": 53
                            },
                            "255": {
                                "x": 845,
                                "y": 1155,
                                "w": 49,
                                "h": 49
                            },
                            "270": {
                                "x": 894,
                                "y": 1155,
                                "w": 39,
                                "h": 39
                            },
                            "285": {
                                "x": 933,
                                "y": 1155,
                                "w": 49,
                                "h": 49
                            },
                            "300": {
                                "x": 982,
                                "y": 1155,
                                "w": 53,
                                "h": 53
                            },
                            "315": {
                                "x": 1035,
                                "y": 1155,
                                "w": 55,
                                "h": 55
                            },
                            "330": {
                                "x": 1090,
                                "y": 1155,
                                "w": 53,
                                "h": 53
                            },
                            "345": {
                                "x": 1143,
                                "y": 1155,
                                "w": 49,
                                "h": 49
                            }
                        },
                        {
                            "0": {
                                "x": 0,
                                "y": 1210,
                                "w": 39,
                                "h": 39
                            },
                            "15": {
                                "x": 39,
                                "y": 1210,
                                "w": 49,
                                "h": 49
                            },
                            "30": {
                                "x": 88,
                                "y": 1210,
                                "w": 53,
                                "h": 53
                            },
                            "45": {
                                "x": 141,
                                "y": 1210,
                                "w": 55,
                                "h": 55
                            },
                            "60": {
                                "x": 196,
                                "y": 1210,
                                "w": 53,
                                "h": 53
                            },
                            "75": {
                                "x": 249,
                                "y": 1210,
                                "w": 49,
                                "h": 49
                            },
                            "90": {
                                "x": 298,
                                "y": 1210,
                                "w": 39,
                                "h": 39
                            },
                            "105": {
                                "x": 337,
                                "y": 1210,
                                "w": 49,
                                "h": 49
                            },
                            "120": {
                                "x": 386,
                                "y": 1210,
                                "w": 53,
                                "h": 53
                            },
                            "135": {
                                "x": 439,
                                "y": 1210,
                                "w": 55,
                                "h": 55
                            },
                            "150": {
                                "x": 494,
                                "y": 1210,
                                "w": 53,
                                "h": 53
                            },
                            "165": {
                                "x": 547,
                                "y": 1210,
                                "w": 49,
                                "h": 49
                            },
                            "180": {
                                "x": 596,
                                "y": 1210,
                                "w": 39,
                                "h": 39
                            },
                            "195": {
                                "x": 635,
                                "y": 1210,
                                "w": 49,
                                "h": 49
                            },
                            "210": {
                                "x": 684,
                                "y": 1210,
                                "w": 53,
                                "h": 53
                            },
                            "225": {
                                "x": 737,
                                "y": 1210,
                                "w": 55,
                                "h": 55
                            },
                            "240": {
                                "x": 792,
                                "y": 1210,
                                "w": 53,
                                "h": 53
                            },
                            "255": {
                                "x": 845,
                                "y": 1210,
                                "w": 49,
                                "h": 49
                            },
                            "270": {
                                "x": 894,
                                "y": 1210,
                                "w": 39,
                                "h": 39
                            },
                            "285": {
                                "x": 933,
                                "y": 1210,
                                "w": 49,
                                "h": 49
                            },
                            "300": {
                                "x": 982,
                                "y": 1210,
                                "w": 53,
                                "h": 53
                            },
                            "315": {
                                "x": 1035,
                                "y": 1210,
                                "w": 55,
                                "h": 55
                            },
                            "330": {
                                "x": 1090,
                                "y": 1210,
                                "w": 53,
                                "h": 53
                            },
                            "345": {
                                "x": 1143,
                                "y": 1210,
                                "w": 49,
                                "h": 49
                            }
                        }
                    ]
                },
                "BALL": {
                    "rotates": false,
                    "aliases": [
                        "LOON",
                        "SHIP"
                    ],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 1265,
                                "w": 28,
                                "h": 28
                            }
                        }
                    ]
                },
                "GRND": {
                    "rotates": true,
                    "aliases": [],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 1320,
                                "w": 17,
                                "h": 17
                            },
                            "15": {
                                "x": 17,
                                "y": 1320,
                                "w": 20,
                                "h": 20
                            },
                            "30": {
                                "x": 37,
                                "y": 1320,
                                "w": 22,
                                "h": 22
                            },
                            "45": {
                                "x": 59,
                                "y": 1320,
                                "w": 23,
                                "h": 23
                            },
                            "60": {
                                "x": 82,
                                "y": 1320,
                                "w": 24,
                                "h": 24
                            },
                            "75": {
                                "x": 106,
                                "y": 1320,
                                "w": 23,
                                "h": 23
                            },
                            "90": {
                                "x": 129,
                                "y": 1320,
                                "w": 21,
                                "h": 21
                            },
                            "105": {
                                "x": 150,
                                "y": 1320,
                                "w": 23,
                                "h": 23
                            },
                            "120": {
                                "x": 173,
                                "y": 1320,
                                "w": 24,
                                "h": 24
                            },
                            "135": {
                                "x": 197,
                                "y": 1320,
                                "w": 24,
                                "h": 24
                            },
                            "150": {
                                "x": 221,
                                "y": 1320,
                                "w": 22,
                                "h": 22
                            },
                            "165": {
                                "x": 243,
                                "y": 1320,
                                "w": 20,
                                "h": 20
                            },
                            "180": {
                                "x": 263,
                                "y": 1320,
                                "w": 17,
                                "h": 17
                            },
                            "195": {
                                "x": 280,
                                "y": 1320,
                                "w": 20,
                                "h": 20
                            },
                            "210": {
                                "x": 300,
                                "y": 1320,
                                "w": 22,
                                "h": 22
                            },
                            "225": {
                                "x": 322,
                                "y": 1320,
                                "w": 23,
                                "h": 23
                            },
                            "240": {
                                "x": 345,
                                "y": 1320,
                                "w": 24,
                                "h": 24
                            },
                            "255": {
                                "x": 369,
                                "y": 1320,
                                "w": 23,
                                "h": 23
                            },
                            "270": {
                                "x": 392,
                                "y": 1320,
                                "w": 21,
                                "h": 21
                            },
                            "285": {
                                "x": 413,
                                "y": 1320,
                                "w": 23,
                                "h": 23
                            },
                            "300": {
                                "x": 436,
                                "y": 1320,
                                "w": 24,
                                "h": 24
                            },
                            "315": {
                                "x": 460,
                                "y": 1320,
                                "w": 24,
                                "h": 24
                            },
                            "330": {
                                "x": 484,
                                "y": 1320,
                                "w": 22,
                                "h": 22
                            },
                            "345": {
                                "x": 506,
                                "y": 1320,
                                "w": 20,
                                "h": 20
                            }
                        }
                    ]
                },
                "SLEI": {
                    "rotates": true,
                    "aliases": [],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 1375,
                                "w": 39,
                                "h": 39
                            },
                            "15": {
                                "x": 39,
                                "y": 1375,
                                "w": 49,
                                "h": 49
                            },
                            "30": {
                                "x": 88,
                                "y": 1375,
                                "w": 53,
                                "h": 53
                            },
                            "45": {
                                "x": 141,
                                "y": 1375,
                                "w": 55,
                                "h": 55
                            },
                            "60": {
                                "x": 196,
                                "y": 1375,
                                "w": 53,
                                "h": 53
                            },
                            "75": {
                                "x": 249,
                                "y": 1375,
                                "w": 49,
                                "h": 49
                            },
                            "90": {
                                "x": 298,
                                "y": 1375,
                                "w": 39,
                                "h": 39
                            },
                            "105": {
                                "x": 337,
                                "y": 1375,
                                "w": 49,
                                "h": 49
                            },
                            "120": {
                                "x": 386,
                                "y": 1375,
                                "w": 53,
                                "h": 53
                            },
                            "135": {
                                "x": 439,
                                "y": 1375,
                                "w": 55,
                                "h": 55
                            },
                            "150": {
                                "x": 494,
                                "y": 1375,
                                "w": 53,
                                "h": 53
                            },
                            "165": {
                                "x": 547,
                                "y": 1375,
                                "w": 49,
                                "h": 49
                            },
                            "180": {
                                "x": 596,
                                "y": 1375,
                                "w": 39,
                                "h": 39
                            },
                            "195": {
                                "x": 635,
                                "y": 1375,
                                "w": 49,
                                "h": 49
                            },
                            "210": {
                                "x": 684,
                                "y": 1375,
                                "w": 53,
                                "h": 53
                            },
                            "225": {
                                "x": 737,
                                "y": 1375,
                                "w": 55,
                                "h": 55
                            },
                            "240": {
                                "x": 792,
                                "y": 1375,
                                "w": 53,
                                "h": 53
                            },
                            "255": {
                                "x": 845,
                                "y": 1375,
                                "w": 49,
                                "h": 49
                            },
                            "270": {
                                "x": 894,
                                "y": 1375,
                                "w": 39,
                                "h": 39
                            },
                            "285": {
                                "x": 933,
                                "y": 1375,
                                "w": 49,
                                "h": 49
                            },
                            "300": {
                                "x": 982,
                                "y": 1375,
                                "w": 53,
                                "h": 53
                            },
                            "315": {
                                "x": 1035,
                                "y": 1375,
                                "w": 55,
                                "h": 55
                            },
                            "330": {
                                "x": 1090,
                                "y": 1375,
                                "w": 53,
                                "h": 53
                            },
                            "345": {
                                "x": 1143,
                                "y": 1375,
                                "w": 49,
                                "h": 49
                            }
                        },
                        {
                            "0": {
                                "x": 0,
                                "y": 1430,
                                "w": 39,
                                "h": 39
                            },
                            "15": {
                                "x": 39,
                                "y": 1430,
                                "w": 49,
                                "h": 49
                            },
                            "30": {
                                "x": 88,
                                "y": 1430,
                                "w": 53,
                                "h": 53
                            },
                            "45": {
                                "x": 141,
                                "y": 1430,
                                "w": 55,
                                "h": 55
                            },
                            "60": {
                                "x": 196,
                                "y": 1430,
                                "w": 53,
                                "h": 53
                            },
                            "75": {
                                "x": 249,
                                "y": 1430,
                                "w": 49,
                                "h": 49
                            },
                            "90": {
                                "x": 298,
                                "y": 1430,
                                "w": 39,
                                "h": 39
                            },
                            "105": {
                                "x": 337,
                                "y": 1430,
                                "w": 49,
                                "h": 49
                            },
                            "120": {
                                "x": 386,
                                "y": 1430,
                                "w": 53,
                                "h": 53
                            },
                            "135": {
                                "x": 439,
                                "y": 1430,
                                "w": 55,
                                "h": 55
                            },
                            "150": {
                                "x": 494,
                                "y": 1430,
                                "w": 53,
                                "h": 53
                            },
                            "165": {
                                "x": 547,
                                "y": 1430,
                                "w": 49,
                                "h": 49
                            },
                            "180": {
                                "x": 596,
                                "y": 1430,
                                "w": 39,
                                "h": 39
                            },
                            "195": {
                                "x": 635,
                                "y": 1430,
                                "w": 49,
                                "h": 49
                            },
                            "210": {
                                "x": 684,
                                "y": 1430,
                                "w": 53,
                                "h": 53
                            },
                            "225": {
                                "x": 737,
                                "y": 1430,
                                "w": 55,
                                "h": 55
                            },
                            "240": {
                                "x": 792,
                                "y": 1430,
                                "w": 53,
                                "h": 53
                            },
                            "255": {
                                "x": 845,
                                "y": 1430,
                                "w": 49,
                                "h": 49
                            },
                            "270": {
                                "x": 894,
                                "y": 1430,
                                "w": 39,
                                "h": 39
                            },
                            "285": {
                                "x": 933,
                                "y": 1430,
                                "w": 49,
                                "h": 49
                            },
                            "300": {
                                "x": 982,
                                "y": 1430,
                                "w": 53,
                                "h": 53
                            },
                            "315": {
                                "x": 1035,
                                "y": 1430,
                                "w": 55,
                                "h": 55
                            },
                            "330": {
                                "x": 1090,
                                "y": 1430,
                                "w": 53,
                                "h": 53
                            },
                            "345": {
                                "x": 1143,
                                "y": 1430,
                                "w": 49,
                                "h": 49
                            }
                        }
                    ]
                },
                "DRON": {
                    "rotates": true,
                    "aliases": [],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 1485,
                                "w": 25,
                                "h": 25
                            },
                            "15": {
                                "x": 25,
                                "y": 1485,
                                "w": 27,
                                "h": 27
                            },
                            "30": {
                                "x": 52,
                                "y": 1485,
                                "w": 25,
                                "h": 25
                            },
                            "45": {
                                "x": 77,
                                "y": 1485,
                                "w": 25,
                                "h": 25
                            },
                            "60": {
                                "x": 102,
                                "y": 1485,
                                "w": 25,
                                "h": 25
                            },
                            "75": {
                                "x": 127,
                                "y": 1485,
                                "w": 27,
                                "h": 27
                            },
                            "90": {
                                "x": 154,
                                "y": 1485,
                                "w": 25,
                                "h": 25
                            },
                            "105": {
                                "x": 179,
                                "y": 1485,
                                "w": 27,
                                "h": 27
                            },
                            "120": {
                                "x": 206,
                                "y": 1485,
                                "w": 25,
                                "h": 25
                            },
                            "135": {
                                "x": 231,
                                "y": 1485,
                                "w": 25,
                                "h": 25
                            },
                            "150": {
                                "x": 256,
                                "y": 1485,
                                "w": 25,
                                "h": 25
                            },
                            "165": {
                                "x": 281,
                                "y": 1485,
                                "w": 27,
                                "h": 27
                            },
                            "180": {
                                "x": 308,
                                "y": 1485,
                                "w": 25,
                                "h": 25
                            },
                            "195": {
                                "x": 333,
                                "y": 1485,
                                "w": 27,
                                "h": 27
                            },
                            "210": {
                                "x": 360,
                                "y": 1485,
                                "w": 25,
                                "h": 25
                            },
                            "225": {
                                "x": 385,
                                "y": 1485,
                                "w": 25,
                                "h": 25
                            },
                            "240": {
                                "x": 410,
                                "y": 1485,
                                "w": 25,
                                "h": 25
                            },
                            "255": {
                                "x": 435,
                                "y": 1485,
                                "w": 27,
                                "h": 27
                            },
                            "270": {
                                "x": 462,
                                "y": 1485,
                                "w": 25,
                                "h": 25
                            },
                            "285": {
                                "x": 487,
                                "y": 1485,
                                "w": 27,
                                "h": 27
                            },
                            "300": {
                                "x": 514,
                                "y": 1485,
                                "w": 25,
                                "h": 25
                            },
                            "315": {
                                "x": 539,
                                "y": 1485,
                                "w": 25,
                                "h": 25
                            },
                            "330": {
                                "x": 564,
                                "y": 1485,
                                "w": 25,
                                "h": 25
                            },
                            "345": {
                                "x": 589,
                                "y": 1485,
                                "w": 27,
                                "h": 27
                            }
                        }
                    ]
                },
                "SAT": {
                    "rotates": true,
                    "aliases": [],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 1540,
                                "w": 32,
                                "h": 32
                            },
                            "15": {
                                "x": 32,
                                "y": 1540,
                                "w": 33,
                                "h": 33
                            },
                            "30": {
                                "x": 65,
                                "y": 1540,
                                "w": 31,
                                "h": 31
                            },
                            "45": {
                                "x": 96,
                                "y": 1540,
                                "w": 28,
                                "h": 28
                            },
                            "60": {
                                "x": 124,
                                "y": 1540,
                                "w": 23,
                                "h": 23
                            },
                            "75": {
                                "x": 147,
                                "y": 1540,
                                "w": 18,
                                "h": 18
                            },
                            "90": {
                                "x": 165,
                                "y": 1540,
                                "w": 13,
                                "h": 13
                            },
                            "105": {
                                "x": 178,
                                "y": 1540,
                                "w": 18,
                                "h": 18
                            },
                            "120": {
                                "x": 196,
                                "y": 1540,
                                "w": 24,
                                "h": 24
                            },
                            "135": {
                                "x": 220,
                                "y": 1540,
                                "w": 28,
                                "h": 28
                            },
                            "150": {
                                "x": 248,
                                "y": 1540,
                                "w": 32,
                                "h": 32
                            },
                            "165": {
                                "x": 280,
                                "y": 1540,
                                "w": 33,
                                "h": 33
                            },
                            "180": {
                                "x": 313,
                                "y": 1540,
                                "w": 32,
                                "h": 32
                            },
                            "195": {
                                "x": 345,
                                "y": 1540,
                                "w": 33,
                                "h": 33
                            },
                            "210": {
                                "x": 378,
                                "y": 1540,
                                "w": 31,
                                "h": 31
                            },
                            "225": {
                                "x": 409,
                                "y": 1540,
                                "w": 28,
                                "h": 28
                            },
                            "240": {
                                "x": 437,
                                "y": 1540,
                                "w": 23,
                                "h": 23
                            },
                            "255": {
                                "x": 460,
                                "y": 1540,
                                "w": 18,
                                "h": 18
                            },
                            "270": {
                                "x": 478,
                                "y": 1540,
                                "w": 13,
                                "h": 13
                            },
                            "285": {
                                "x": 491,
                                "y": 1540,
                                "w": 18,
                                "h": 18
                            },
                            "300": {
                                "x": 509,
                                "y": 1540,
                                "w": 24,
                                "h": 24
                            },
                            "315": {
                                "x": 533,
                                "y": 1540,
                                "w": 28,
                                "h": 28
                            },
                            "330": {
                                "x": 561,
                                "y": 1540,
                                "w": 32,
                                "h": 32
                            },
                            "345": {
                                "x": 593,
                                "y": 1540,
                                "w": 33,
                                "h": 33
                            }
                        }
                    ]
                },
                "ISS": {
                    "rotates": true,
                    "aliases": [],
                    "frames": [
                        {
                            "0": {
                                "x": 0,
                                "y": 1595,
                                "w": 32,
                                "h": 32
                            },
                            "15": {
                                "x": 32,
                                "y": 1595,
                                "w": 35,
                                "h": 35
                            },
                            "30": {
                                "x": 67,
                                "y": 1595,
                                "w": 37,
                                "h": 37
                            },
                            "45": {
                                "x": 104,
                                "y": 1595,
                                "w": 37,
                                "h": 37
                            },
                            "60": {
                                "x": 141,
                                "y": 1595,
                                "w": 36,
                                "h": 36
                            },
                            "75": {
                                "x": 177,
                                "y": 1595,
                                "w": 33,
                                "h": 33
                            },
                            "90": {
                                "x": 210,
                                "y": 1595,
                                "w": 28,
                                "h": 28
                            },
                            "105": {
                                "x": 238,
                                "y": 1595,
                                "w": 33,
                                "h": 33
                            },
                            "120": {
                                "x": 271,
                                "y": 1595,
                                "w": 36,
                                "h": 36
                            },
                            "135": {
                                "x": 307,
                                "y": 1595,
                                "w": 37,
                                "h": 37
                            },
                            "150": {
                                "x": 344,
                                "y": 1595,
                                "w": 36,
                                "h": 36
                            },
                            "165": {
                                "x": 380,
                                "y": 1595,
                                "w": 35,
                                "h": 35
                            },
                            "180": {
                                "x": 415,
                                "y": 1595,
                                "w": 32,
                                "h": 32
                            },
                            "195": {
                                "x": 447,
                                "y": 1595,
                                "w": 35,
                                "h": 35
                            },
                            "210": {
                                "x": 482,
                                "y": 1595,
                                "w": 37,
                                "h": 37
                            },
                            "225": {
                                "x": 519,
                                "y": 1595,
                                "w": 37,
                                "h": 37
                            },
                            "240": {
                                "x": 556,
                                "y": 1595,
                                "w": 36,
                                "h": 36
                            },
                            "255": {
                                "x": 592,
                                "y": 1595,
                                "w": 33,
                                "h": 33
                            },
                            "270": {
                                "x": 625,
                                "y": 1595,
                                "w": 28,
                                "h": 28
                            },
                            "285": {
                                "x": 653,
                                "y": 1595,
                                "w": 33,
                                "h": 33
                            },
                            "300": {
                                "x": 686,
                                "y": 1595,
                                "w": 36,
                                "h": 36
                            },
                            "315": {
                                "x": 722,
                                "y": 1595,
                                "w": 37,
                                "h": 37
                            },
                            "330": {
                                "x": 759,
                                "y": 1595,
                                "w": 36,
                                "h": 36
                            },
                            "345": {
                                "x": 795,
                                "y": 1595,
                                "w": 35,
                                "h": 35
                            }
                        }
                    ]
                }
            }
        },
        //plane: plane35,
        sizing: 35,
        scale: window.devicePixelRatio ? window.devicePixelRatio : 1,
        getIcon: function (aircraftType: any, track: any, acColor: any) {
            //console.log(track);
            this.scale = Math.round(this.scale);
            this.scale = Math.max(1, this.scale);
            this.scale = Math.min(3, this.scale);
            this.scale = 1;
            if (this.sizing == 80)
                this.plane35 = this.plane80;
            else if (this.sizing == 35)
                this.plane35 = this.plane35;
            else
                this.plane35 = this.plane80;

            var p: any = this.findIcon(aircraftType);
            
            if (p == null)
                p = this.plane35.icons["C206"];
            var rotatedFrame = p.frames[0][(Math.round(track / 15) * 15).toString()];
            //console.log("Aircraft Type: " + aircraftType, track, p, rotatedFrame);
            if (rotatedFrame == null) {
                if (this.sizing == 80) {
                    rotatedFrame = {
                        "x": 0,
                        "y": 246,
                        "w": 48,
                        "h": 48
                    }
                } else if (this.sizing == 35) {
                    rotatedFrame = {
                        "x": 0,
                        "y": 110,
                        "w": 21,
                        "h": 21
                    }
                }
            }
            var scaled = {
                x: rotatedFrame.x / this.scale,
                y: rotatedFrame.y / this.scale,
                w: rotatedFrame.w / this.scale,
                h: rotatedFrame.h / this.scale,
            };
            //console.log(scaled);
            return {
                scaled: {
                    x: rotatedFrame.x / this.scale,
                    y: rotatedFrame.y / this.scale,
                    w: rotatedFrame.w / this.scale,
                    h: rotatedFrame.h / this.scale,
                },
                url: this.baseUrl + acColor + this.sprite + this.sizing.toString() + this.sprite2,
                scaledSize: { w: this.plane35.w, h: this.plane35.h },//(scaled.w / scale, scaled.h / scale),
                size: { w: scaled.w, h: scaled.h },
                origin: { x: scaled.x, y: scaled.y },
                anchor: { w: scaled.w / 2, h: scaled.h / 2 },
                labelOrigin: { x: -20, y: -20 },
            }


        },

        findIcon: function (ac:any) {
            let retval;// = this.plane35.icons["C206"];
            Object.entries(this.plane35.icons).forEach((x) => {
                //var retval;
                if (x[0] == ac) {
                    retval = x[1];
                    return retval;
                } else {
                    x[1].aliases.forEach((y) => {
                        if (y == ac) {
                            retval = x[1];
                            return retval;
                        }
                    });
                }
            });
            //console.log(ac);
            return retval;
        }
    }
}

export { gaftAPI }