import React, { Component } from 'react';
import { Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { APIFunctions } from '../../modules/APIFunctions';

const params:any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop:string) => searchParams.get(prop),
});

class ResetPassword extends Component<any, any> {

    userID = "";
    resetID = "";

    constructor(props: any) {
        super(props);
        this.state = {
            isValid: false,
            passwordChanged: false,
            isReset: false,
        }
        this.userID = params.a;
        this.resetID = params.b;
    }

    async componentDidMount() {
        var a = new APIFunctions();
        var result = await a.validatePasswordReset({ userID: this.userID, resetID: this.resetID });
        this.setState({ isValid: result });
        console.log(result);
       
    }

    validateForm = async () => {
        var hasErrors = false;
        if (this.state.val_password.length < 8) {
            this.setState({ valid_password: false });
            hasErrors = true;
        } else {
            this.setState({ valid_password: null });
        }
        if (this.state.val_password !== this.state.val_confirmpassword) {
            this.setState({ valid_confirmpassword: false });
            hasErrors = true;
        } else {
            this.setState({ valid_confirmpassword: null });
        }
        if (!hasErrors) {
            var a = new APIFunctions();
            var result = await a.resetPassword({ password: this.state.val_password, userID: this.userID });
            console.log("PASSWORD RESET", result);
            this.setState({ isReset: true, isValid: false });
        }
    }

    render() {

        return (
            this.state.isValid ?
                <div className="col-12 registerDiv container">
                    <div className="row divCenter col-12">
                        <div className="col-md-6 col-sm-12 divCenter" style={{ marginBottom: '20px' }}><h2>Recover your password</h2></div>
                    </div>
                    <div className="row divCenter" style={{ marginBottom: '20px' }}>
                        <div className="col-md-6 col-sm-12">
                            You have requested to reset your password.  No problem! This happens to everyone.  In order to keep your account secure, we cannot recover your old password and you will need to set up a new one.  Please provide your new password below.
                        </div>
                    </div>
                    <div className="row divCenter" style={{ marginBottom: '20px' }}>
                        <div className="col-md-6 col-sm-12">
                            <FormGroup floating>
                                <Input onChange={(e) => { this.setState({ val_password: e.target.value }); }} id="password" placeholder="Password" type="password" valid={this.state.valid_password === true} invalid={this.state.valid_password === false} />
                                <Label for="password">Password <span style={{ color: '#FF0000' }}>*</span></Label>
                                <FormFeedback invalid>
                                    Password length should be minimum 8 characters.
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup floating>
                                <Input onChange={(e) => { this.setState({ val_confirmpassword: e.target.value }); }} id="confirmpassword" placeholder="Confirm Password" type="password" valid={this.state.valid_confirmpassword === true} invalid={this.state.valid_confirmpassword === false} />
                                <Label for="confirmpassword">Confirm Password <span style={{ color: '#FF0000' }}>*</span></Label>
                                <FormFeedback invalid>
                                    Passwords do not match.
                                </FormFeedback>
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row divCenter">
                        <div className="col-md-6 col-sm-12">
                            <Button color="primary" className="col-12" onClick={this.validateForm}>Reset password</Button>
                            <br /><br />
                           
                        </div>

                    </div>
                </div>
                : this.state.isReset ?
                    <div className="col-12 registerDiv container">
                        <div className="row divCenter col-12">
                            <div className="col-md-6 col-sm-12 divCenter" style={{ marginBottom: '20px' }}><h2>Congratulations! Password Reset!</h2></div>
                        </div>
                        <div className="row divCenter" style={{ marginBottom: '20px' }}>
                            <div className="col-md-6 col-sm-12">
                                You have successfully reset your password.  Please try to log in using your new password.
                            </div>
                        </div>
                       
                    </div>
                    :
                    <div className="col-12 registerDiv">
                        <div className="row divCenter col-12">
                            <div className="col-md-6 col-sm-12 divCenter" style={{ marginBottom: '20px' }}><h2>Recover your password</h2></div>
                        </div>
                        <div className="row divCenter" style={{ marginBottom: '20px' }}>
                            <div className="col-md-6 col-sm-12">
                               We are sorry, however, the password reset link is either expired (it has been over 24 hours), already used, or the link is invalid.  You can request another password reset to obtain a new link.
                            </div>
                        </div>

                    </div>
        );
    }
}

export { ResetPassword }