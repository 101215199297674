import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const withRouter = (Component:any) => {
    const Wrapper = (props:any) => {
        const navigate = useNavigate();
        let location = useLocation();
        let params = useParams();
        return (
            <Component
                navigate={navigate}
                {...props}
                router={{ location, navigate, params }}
            />
        );
    };

    return Wrapper;
};