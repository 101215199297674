import React, { Component } from 'react';

class PrivacyPolicy extends Component<any, any> {

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="col-12 registerDiv container">
                    <div className="row divCenter col-12">
                        <div className="col-md-6 col-sm-12 divCenter" style={{ marginBottom: '20px' }}><h2>Privacy Policy</h2></div>
                    </div>
                    <div className="row" style={{ marginBottom: '20px' }}>
                        <div className="col-12">
                            <p>
                                Thank you for visiting GA Flight Tracker. General Aviation Flight Tracker is committed to honoring the privacy of our members and visitors.
                                <br /><br />
                                When you register for a GA Flight Tracker account and other services, we ask you for your name, contact information, preferences, and personal profile information. This information lets us provide personalized services and communicate separately with you. We also use aggregated information about the use of our services to evaluate our users' preferences, improve our programming, and facilitate third-party reporting of Internet usage.
                                <br /><br />
                                Like most web sites, we use small bits of data called cookies stored on users' computers to simulate a continuous connection. Cookies let us remember information about your preferences and passwords and allow you to move within our service without reintroducing yourself. Web browsers let you easily see and control cookies. GA Flight Tracker and its advertisers may also use cookies to determine on an anonymous basis which advertisements users have seen and how users responded to them, but don't use such cookies to collect personally identifiable information unless you give us permission to do so. Similarly, GA Flight Tracker and its advertisers may use small pieces of code called "web beacons" or "clear gifs" to collect anonymous advertising metrics and to deliver cookies related to such advertisements.
                                <br /><br />
                                While we need certain information to register you as a unique user and let you access personalized services, some contact and optional information is not required and may be given at your discretion. Registration is not mandatory and you may use most GA Flight Tracker services without disclosing any information.
                                <br /><br />
                                We encourage GA Flight Tracker members to update and edit their personal information and keep it current. You can easily correct or update your personal information at any time via our Profile page, or through the iOS and Android apps.
                                <br /><br />
                                We store all user information in secure databases protected via a variety of access controls. This data is accessed only for the purposes specified in this privacy statement.
                                <br /><br />
                                When contributing to the GA Flight Tracker service, photo system, Squawks, or other community features, it is inherent in the form of communication that your unique user name, aircraft information, and GPS position is revealed to identify yourself and your location. All other information in your profile is optional, but will be displayed if you have entered such information and not specifically asked that it is not displayed.
                                <br /><br />
                                When interacting with other members or sending third parties a message, flight alert, notification, flight plan, or other details via GA Flight Tracker, your username, full name and email address may be distributed as part of the interaction. Similarly, if you accept (directly or implicitly) or reply to an interaction, the same information may be similarly distributed.
                                We do not share personally identifiable data with other companies (apart from those acting as our agents in providing our service to you, who agree to use it only for that purpose), except as necessary to fulfill a transaction you have requested, or unless you otherwise give us permission to do so. GA Flight Tracker may also share such information in response to legal process, such as a court order or subpoena.
                                <br /><br />
                                We will not reveal any information about you to any company featured on GA Flight Tracker. However, a featured company may define user criteria that they are interested in and by choosing to purchase an API connection for use on their website or application, the featured company will must abide by the same privacy policies set forth by GA Flight Tracker.  GA Flight Tracker will review and authorize use of our API only after the featured company meets all required criteria for use.
                                <br /><br />
                                Children under the age of thirteen years old may not register for GA Flight Tracker or use any GA Flight Tracker services that require personally identifiable information - This includes the GO ONLINE feature which will disclose your location in realtime.
                                <br /><br />
                                Please contact us contact us with any questions or comments.
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export {PrivacyPolicy}