
class User {
     UserID?: String;
     Username?: String;
     Password?: String;
     FirstName?: String;
     LastName?: String;
     EmailAddress?: String;
     MobileNumber?: String;
     LastOnline?: String;
     ProfilePicture?: String;
     isPilot?: Boolean;
     isVFR?: Boolean;
     isIFR?: Boolean;
     isDualEngine?: Boolean;
     isNightRated?: Boolean;
     isPublic?: Boolean;
     Platform?: String;
     SSO?: String;
     AccountID?: String;


}

export { User }